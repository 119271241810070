
import {Component, Vue} from 'vue-property-decorator'

import { VChip } from 'vuetify/lib'

@Component({
  name: 'Chip',
  extends: VChip as any,
})
export default class Chip extends Vue {

}
