












































import {Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import {VIcon, VRating} from 'vuetify/lib'
import {
  Pos,
  Position,
  Rating,
  RatingRequest,
  ReportRequest,
  ReportResponse,
  ReservationResponse,
  Dialog,
} from '@/lib/kepler/interfaces'
import {Action, State} from 'vuex-class'
import {AxiosPromise, AxiosResponse} from 'axios'
import VehicleAddReportDialog from '@/views/Vehicle/VehicleAddReportDialog.vue'
import {EventBus} from '@/main'
import {BookingState} from '@/store/modules/booking'

interface InternalRating extends Rating {
  rating: number
  reportRequest: ReportRequest | null
}

@Component({
  components: {
    Button: Utils.loadComponent('Button'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    VRating,
    VIcon,
  },
  name: 'RateDialog',
})
export default class RateDialog extends ConfirmDialogCallback {
  @State((state) => state.configuration.appConfig) public appConfigState!: any
  @State((state) => state.booking) public bookingState!: BookingState
  @State((state) => state.configuration.appConfig.reservation_survey) public ratings!: Rating[]
  @State((state) => state.profile.userPosition) public userPosition!: Pos | null
  @State((state) => state.profile.client.client_type.type) public clientType!: string

  @Action('getReservationByNumber') public getReservationByNumber!: (num: number) => Promise<ReservationResponse>
  @Action('sendReport') public sendReport!: (payload: ReportRequest) => AxiosPromise<ReportResponse[]>
  @Action('bookingRating') public bookingRating!: (payload: RatingRequest) => AxiosPromise
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  declare public closeDialog: (index?: number) => void
  declare public confirmCallback: () => void
  declare public cancelCallback: () => void
  @Prop() public reservationNumber!: number

  @Prop() public before?: boolean

  public loading: boolean = false

  protected reservation: ReservationResponse | null = null
  protected ratingInternal: InternalRating[] = []

  public get required() {
    return true
  }

  protected get hasReportings() {
    const p = (id: string) => {
      return this.checkReservation().then((r) => {
        return r.reportings.filter((rep) => {
          return rep.type.id === id
        }).length
      })
    }

    const result: { [key: string]: boolean } = {}
    this.ratingInternal.forEach((r) => {
      const id = r.reportRequest?.reporting_type_id
      if (id) {
        p(id).then((val) => {
          this.$set(result, id, val)
          this.$forceUpdate()
        })
      }
    })
    return result
  }

  protected get allRated() {

    return this.ratingInternal.every((r) => {
      // skips the validation if the rating is optional or is a damage report
      if (r.mandatory && !this.isDamageReport(r)) {
        if (r.rating > 0) {
          if (r.rating <= r.rating_threshold) {
            return !!this.reservation?.reportings.filter((rep) => {
              return rep.type.id === r.reporting_type_id
            }).length
          }
          return true
        }
        return false
      }
      return true
    })
  }

  protected created() {
    this.loading = true

    this.getRes().then(() => {
      const reservation = this.reservation!
      // console.log(reservation)

      if (this.ratings.length === 0) {
        this.confirmCallback()
      }

      let position: Position | null = null
      if (this.userPosition) {
        position = {latitude: this.userPosition.lat, longitude: this.userPosition.lng}
      }

      const reportRequestTemplate: ReportRequest = {
        vehicle_id: reservation.vehicle_slot.vehicle.id,
        reservation_id: reservation?.id || null,
        reporting_type_id: null,
        location: '',
        position,
        description: '',
        attachments: [],
        damage_position_raw: [],
      }

      const flags = reservation.flags

      this.ratings.forEach(((r) => {
        let reportRequest = null
        let rating = 0

        const flagged = flags.find((f) => {
          return (f.flag === 'RATING_' + r.survey)
        })

        if (flagged?.value) {
          rating = parseInt(flagged.value, 10)
        }

        if (r.reporting_type_id) {
          reportRequest = {} as ReportRequest
          Object.assign(reportRequest, reportRequestTemplate)

          if (!reportRequest.reporting_type_id) {
            reportRequest.reporting_type_id = r.reporting_type_id
          }
        }

        const internal = {reportRequest, rating, ...r}

        if (this.isDamageReport(internal) && !!internal.rating) {
          internal.rating = 5
        }

        this.ratingInternal.push(internal)

        if (this.before) {
          const filterList = ['DAMAGE', 'CLEANLINESS']
          this.$set(this, 'ratingInternal', this.ratingInternal.filter((ri) => {
            return filterList.includes(ri.survey)
          }))
        }

        this.loading = false
      }))
    }).catch(() => {
      //
    })
  }

  protected getRes() {
    if (navigator.onLine) {
      return this.getReservationByNumber(this.reservationNumber).then((r) => {
        this.reservation = r
        return r
      })
    } else {
      return new Promise<void | ReservationResponse>((resolve, reject) => {
        const f = this.bookingState.activeReservations.find((r) => {
          return r.number === this.reservationNumber
        })
        // console.log('f', f)
        if (f) {
          this.reservation = f
          resolve()
        } else {
          reject()
        }
      }) as Promise<ReservationResponse>
    }
  }

  protected sendSurvey() {
    return new Promise<void | AxiosResponse>((resolve, reject) => {
      const ratingsObj: { [key: string]: number } = {}
      this.ratingInternal.forEach((r) => {
        if (r.rating) {
          ratingsObj[r.survey] = r.rating
        }
        // if damage report fake a number
        if (this.isDamageReport(r)) {
          const id = r.reportRequest!.reporting_type_id!
          if (this.hasReportings[id]) {
            ratingsObj[r.survey] = 1
          } else {
            ratingsObj[r.survey] = 5
          }
        }
      })

      if (this.reservationNumber) {
        this.bookingRating({reservation: this.reservationNumber, ratings: ratingsObj})
          .then((r) => {
            resolve(r)
          }).catch(() => {
          if (!navigator.onLine) {
            resolve()
          } else {
            reject()
          }
        })
      } else {
        throw new Error('reservation number unavailable')
      }
    })
  }

  protected checkReservation(): Promise<ReservationResponse> {
    return new Promise((resolve, reject) => {
      if (this.reservation) {
        resolve(this.reservation)
      } else {
        this.getRes().then((r) => {
          resolve(r)
        }).catch(reject)
      }
    })
  }

  protected isDamageReport(r: InternalRating) {
    if (r.reportRequest) {
      return r.reportRequest.reporting_type_id === this.appConfigState.damage_report_id
    }
  }

  protected sendEverythingAndFuckOff() {
    this.loading = true
    Promise.all([
      this.sendSurvey(),
    ]).then(() => {
      this.confirmCallback()
    }).catch(() => {
      this.loading = false
    })
  }

  protected openReportDialog(r: ReportRequest) {
    this.checkReservation().then((reservation) => {
      EventBus.$on('sentReport', () => {
        this.getRes()
        EventBus.$off('sentReport')
      })
      this.openDialog(new Dialog(VehicleAddReportDialog, {
        vehicleSlot: this.reservation?.vehicle_slot,
        vehicle: reservation.vehicle_slot.vehicle,
        preFilledReport: r,
        reportType: r.reporting_type_id,
      }))
    })
  }

  protected showAsterisk(r: InternalRating) {
    if (r.reportRequest?.reporting_type_id) {
      return r.mandatory && !this.hasReportings[r.reportRequest.reporting_type_id] && !this.isDamageReport(r)
    }
  }
}
