

































import {Vue, Component, Prop} from 'vue-property-decorator'
import {
  BookRequest,
  OpenVehicleRequest,
  ReservationResponse,
  TrackRequest,
  VehicleSlot,
  Popup,
  Dialog,
} from '@/lib/kepler/interfaces'
import Locate, {Position} from '@/lib/location'
import ServiceMesh from '@/lib/serviceMesh'
import {Action} from 'vuex-class'
import {EventBus} from '@/main'
import Utils from '@/utils'

import VehicleBookingAddMemoDialogView from '@/views/Vehicle/VehicleBookingAddMemoDialog.vue'
import BookingOpenCloseProgressView from '@/views/Booking/BookingOpenCloseProgress.vue'
import ConfirmDialog from '@/views/ConfirmDialog.vue'
import {AxiosResponse} from 'axios'
import ProgressCircular from '@/components/proxy/ProgressCircular.vue'

interface OpenCloseResponse {
  [key: string]: any

  channel: 'live' | 'ble' | 'bt' | 'sms'
}

@Component({
  components: {
    ProgressCircular,
    VehicleConfirmImage: Utils.loadComponent('entities/vehicle/VehicleConfirmImage'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    Container: Utils.loadComponent('proxy/Container'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Layout: Utils.loadComponent('proxy/Layout'),
    IsLogged: Utils.loadComponent('IsLogged'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Digits: Utils.loadComponent('Digits'),
    Button: Utils.loadComponent('Button'),
    Btn: Utils.loadComponent('proxy/Btn'),
    //                A E S T E T H I C S
  },
  name: 'VehiclePinRequestDialog',
})
export default class VehiclePinRequestDialog extends Vue {
  @Action('selectReservation') public selectReservation!: (r: ReservationResponse) => Promise<ReservationResponse>
  @Action('closeOverlays') public closeOverlays!: () => void
  @Action('closeDialog') public closeDialog!: (index?: number) => void
  @Action('flushPopups') public flushPopups!: () => void
  @Action('openVehicle') public openVehicle!: (payload: OpenVehicleRequest) => Promise<AxiosResponse<OpenCloseResponse>>
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Action('track') public track!: (req: TrackRequest) => Promise<void>
  @Action('book') public book!: (req: BookRequest) => Promise<ReservationResponse>

  @Prop({default: true}) public showCloseButton!: boolean
  @Prop({default: false}) public bookAndUnlock!: boolean
  @Prop({default: true}) public askForMemo!: boolean
  @Prop({
    type: Number,
    default: 4,
  }) public maxLength!: number
  @Prop() public reservation!: ReservationResponse
  @Prop() public vehicleSlot!: VehicleSlot

  public loading: boolean = false
  public disabled: boolean = false
  public visibility: boolean = false
  public confirmed: boolean = false
  public digits: string = ''
  public error: string = ''

  public reservationRequest: BookRequest = {start: null, end: null, plate: null, memo: ''}
  public digitsCompleted: boolean = false

  public userPosition: Position = {lat: 0, lng: 0, acc: null}

  protected get asterisks(): string {
    return '*'.repeat(this.digits.length)
  }

  protected get userDistanceFromVehicle() {
    return Locate.calculateDistance(this.userPosition, {
      lat: this.vehicleSlot.position.latitude,
      lng: this.vehicleSlot.position.longitude,
      acc: null,
    })
  }

  protected get confirmImage() {
    return ServiceMesh.getConfirmationImage(this.vehicleType)
  }

  protected get vehicleType() {
    return ServiceMesh.getVehicleType(this.getVehicle)
  }

  protected get getVehicle() {
    return this.reservation ? this.reservation.vehicle_slot.vehicle : this.vehicleSlot.vehicle
  }

  protected get completed() {
    return this.digits.length === this.maxLength
  }

  protected mounted() {
    EventBus.$on('userWantToProceedWithBook', this.sendBookAndUnlock)
    EventBus.$on('forceBooking', () => {
      this.closeDialog()
      this.bookVehicle()
    })
    Locate.locate(
      (pos: any) => {
        this.userPosition = pos
      },
      this.userPosition,
      () => {
        // TODO: investigate further use for error callback
      },
    )
  }

  protected destroyed() {
    EventBus.$off('userWantToProceedWithBook')
    EventBus.$off('forceBooking')
  }

  protected addDigit(val: string) {
    if (this.digits.length < this.maxLength) {
      this.digits += val
    }
  }

  protected removeDigit() {
    this.digits = this.digits.slice(0, -1)
  }

  protected sendBookAndUnlock() {
    if (this.userDistanceFromVehicle > 0 && (this.userDistanceFromVehicle > this.$env.MINIMUM_DISTANCE_FROM_VEHICLE_FOR_UNLOCK)) {
      this.openDialog(new Dialog(ConfirmDialog, {
        imageState: 'error.svg',
        code: '',
        title: this.$t('booking.action.you_seem_a_bit_far_away'),
        subtitle: this.$t('booking.action.to_proceed_with_book_and_unlock'),
        confirmText: this.$t('action.proceed'),
      }, null, false, 'forceBooking'))
      return
    }

    this.bookVehicle()
  }

  protected bookVehicle() {
    this.closeDialog()
    this.reservationRequest.plate = this.vehicleSlot.vehicle.plate
    this.book(this.reservationRequest).then((reservation: ReservationResponse) => {
      this.selectReservation(reservation)
      this.vehicleOpen(reservation)
    })
  }

  protected unlock() {
    this.loading = true
    if (this.bookAndUnlock) {
      if (this.askForMemo) {
        this.openDialog(new Dialog(VehicleBookingAddMemoDialogView, {
          vehicle: this.vehicleSlot.vehicle,
          bookRequest: this.reservationRequest,
          confirmCallback: () => {
            this.sendBookAndUnlock()
          },
          cancelCallback: () => {
            return
          },
        }))
      } else {
        this.sendBookAndUnlock()
      }
    } else {
      this.vehicleOpen(this.reservation)
    }
  }

  protected success() {
    this.loading = false
    this.confirmed = true
    this.$nextTick(() => {
      const check = (this.$refs.pinRequestSuccess as Vue)
      if (check && check.$el) {
        check.$el.classList.remove('v-ripple__animation')
      }
      setTimeout(() => {
        // console.log('close on timeout')
        this.closeDialog()
      }, 1000)
    })
  }

  protected vehicleOpen(reservation: ReservationResponse) {
    const pin = this.digits
    const action = 'start'

    this.openVehicle({
      pin: this.digits,
      reservationNumber: reservation.number,
    }).then((r: AxiosResponse<OpenCloseResponse>) => {
      let response = r.data
      if (response.response && typeof response.response !== 'boolean') {
        response = response.response
      }
      this.locate(reservation)
      this.openProgressView(reservation, response)
    }).catch(() => {
      this.flushPopups()
    })
  }

  protected locate(reservation: ReservationResponse) {
    Locate.locate((position: Position) => {
        const trackRequest: TrackRequest = {
          reservation_number: reservation.number,
          latitude: position.lat,
          longitude: position.lng,
          accuracy: 0,
        }
        this.track(trackRequest)
      }, {
        lat: reservation.vehicle_slot.position.latitude,
        lng: reservation.vehicle_slot.position.longitude,
        acc: null,
      },
      () => {
        // TODO: investigate further use for error callback
      },
    )
  }

  protected openProgressView(reservation: ReservationResponse, r: OpenCloseResponse) {
    this.openPopup(new Popup(BookingOpenCloseProgressView, {
      reservation,
      response: r,
      action: 'unlock',
    }, '', true))
    this.success()
  }
}
