import 'typeface-open-sans'
import '@mdi/font/css/materialdesignicons.css'

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import './lib/kepler/sdk'

import Vue from 'vue'

import App from './App.vue'
import router from './router'

import store from './store/index'

import NMaps from './lib/n-maps/src/VueNMaps'
import {ObserveVisibility} from 'vue-observe-visibility'

import * as mapConfig from './lib/map/map_config.json'

Vue.directive('observe-visibility', ObserveVisibility)

Vue.use(NMaps, mapConfig)

// import './registerServiceWorker'
import cordovaLoader from './cordovaLoader'

import {ValidationProvider} from 'vee-validate'

Vue.component('ValidationProvider', ValidationProvider)

import Vuetify from 'vuetify'
import {
  VApp,
  // TODO: This have to be removed!
  VDialog,
  VSpacer,
  VContent,
  VStepper,
  VStepperHeader,
  VStepperStep,
  VStepperContent,
} from 'vuetify/lib'
import theme from '@/lib/vuetify/theme'
import './lib/vuetify/vuetify.styl'

const iconfont = 'mdi' // 'md' || 'mdi' || 'fa' || 'fa4
Vue.prototype.$vuetify_iconfont = iconfont
Vue.use(Vuetify, {
  components: {
    VApp,
    VSpacer,
    VContent,
    VStepper,
    VStepperHeader,
    VStepperStep,
    VStepperContent,
    VDialog,
  },
  theme,
  iconfont,
})

import VueEnv, {envInstance} from './lib/plugins/env'
import VueStyle from './lib/plugins/style'
import VueLang from './lib/plugins/lang'
import VueLog from './lib/plugins/logger'
import Theme from './lib/plugins/theme'

Vue.use(VueEnv)
Vue.use(VueStyle)
Vue.use(VueLang)
Vue.use(VueLog)
Vue.use(Theme)

Vue.config.productionTip = (process.env.NODE_ENV === 'development')

store.dispatch('initRoot', envInstance.APP_VERSION_NUMBER).then((r) => {
  if (r) {
    router.replace({name: 'home'})
  }
})

export const EventBus = new Vue()

function cron(fun: () => void): void {
  fun()
  setTimeout(() => {
    cron(fun)
  }, 1000 * 60)
}

cron(() => {
  const d = new Date()
  if (d.getMinutes() % 5 === 0) {
    EventBus.$emit('cron5', 5)
    // console.log('> cron5')
  }
  EventBus.$emit('cron1', 1)
  // console.log('> cron1')
})

EventBus.$on('cron1', () => {
  // console.log('>> cron1')
  store.dispatch('timestamps')
  // we are nuking this every time there's an $on/$off like in Map.vue:295
  // TODO: find a more reliable method than this cron
})

EventBus.$on('cron5', () => {
  // console.log('>> cron5')
})

router.beforeEach(((to, from, next) => {
  const config = store.state.configuration

  if (config.requiredVersionNumber < config.versionNumber) {
    if (to.path !== '/update') {
      router.replace({name: 'update'})
      next(false)
      return false
    }
    next()
  }

  if (store.getters.popupIsOpen && (to.path !== from.path)) {
    store.dispatch('closePopup')
    next(false)
    return false
  }
  if (store.state.topbar.topbarActions && store.state.topbar.topbarActions.length) {
    store.dispatch('flushActionButtons').then(() => {
      next()
    })
  } else {
    next()
  }
}))

cordovaLoader(() => {
  // noinspection JSUnusedLocalSymbols
  const vue = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app')
  document.addEventListener('resume', () => {
    store.dispatch('flushConnectionErrors')
    store.dispatch('timestamps')
    // if (cordova.platformId !== 'browser') {
    //   window.codePush.sync()
    // }
  }, false)
})
