






























import {Vue, Component, Prop, VModel} from 'vue-property-decorator'

import {VDialog, VTextField, VDatePicker} from 'vuetify/lib'
import moment from 'moment'

@Component({
  components: {VDialog, VDatePicker, VTextField},
  name: 'DateInput',
})
export default class DateInput extends Vue {
  @VModel({type: String, required: false}) public externalValue?: string

  @Prop({type: Boolean, default: false}) protected readonly disabled!: boolean
  @Prop({type: Boolean, default: false}) protected readonly readonly!: boolean
  @Prop({type: Boolean, default: null}) protected readonly valid!: boolean | null

  @Prop({type: Boolean, default: false}) private readonly birthdate!: boolean
  @Prop({type: String, default: () => 'date'}) private readonly type?: 'date' | 'month'
  @Prop({type: String, default: () => 'L'}) private readonly format?: string
  @Prop({type: String, required: false}) private readonly min?: string
  @Prop({type: String, required: false}) private readonly max?: string

  public get dateFormatted() {
    return this.internalValue ? moment(this.internalValue).format(this.format) : null
  }

  public get internalValue() {
    const m = moment(this.externalValue)
    return m.isValid() ? m.format('YYYY-MM-DD') : ''
  }

  public set internalValue(v: string) {
    const m = moment(v, 'YYYY-MM-DD')
    if (m.isValid() && this.externalValue !== v) {
      this.externalValue = v
    }
  }

  public save(date: string) {
    (this.$refs.dialog as any)?.save(date)
  }

  public validationIcon(v: boolean | null) {
    return typeof v === 'boolean' ? v ? 'mdi-check' : 'mdi-close' : ''
  }
}
