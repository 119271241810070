import sdk from '@/lib/kepler/sdk'
import AbstractHardware from '@/lib/hardware/classes/AbstractHardware'

export default class RestHardware extends AbstractHardware {
  public init() {
    this.addAction('open', () => new Promise((resolve, reject) => {
      const reservationNumber = this.config.reservationNumber
      this.config.pinRequester().then((pin: string) => {
        sdk.booking.open({reservationNumber, pin}).then(() => {
          resolve('SUCCESS')
        }).catch((x) => {
          if (x.response.status == 422 && x.response.data.messages.includes('exception.messages.invalid-pin')) {
            reject('INVALID_PIN')
          } else if (x.response.data.messages) {
            const m = x.response.data.messages.join('-')
            if (m.toUpperCase().includes('TIMEOUT')) {
              reject('TIMEOUT')
            } else {
              reject(x.response.status + ': ' + m)
            }
          } else {
            reject(x.response.status + ': ' + JSON.stringify(x.response.data))
          }
        })
      }).catch(() => {
        reject('ABORTED')
      })
    }))
    this.addAction('close', () => new Promise((resolve, reject) => {
        const reservationNumber = this.config.reservationNumber
        sdk.booking.close(reservationNumber).then(() => {
          resolve('SUCCESS')
        }).catch((x)=>{
          if (x.response.data.messages) {
            reject([x.response.data.result, x.response.data.messages.join('-')])
          } else {
            reject(x.response.status + ': ' + JSON.stringify(x.response.data))
          }
        })
      }),
    )
    return Promise.resolve()
  }
}
