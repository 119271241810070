






















































import {Vue, Component} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import {ProfileState} from '@/store/modules/profile'
import {Dialog, Driver, Subscription} from '@/lib/kepler/interfaces'
import sdk from '@/lib/kepler/sdk'
import collect from 'collect.js'

import ConfirmDialog from '@/views/ConfirmDialog.vue'
import Utils from '@/utils'

@Component({
  components: {
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Flex: Utils.loadComponent('proxy/Flex'),
    GradientCard: Utils.loadComponent('GradientCard'),
    Button: Utils.loadComponent('Button'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
    CardDriver: Utils.loadComponent('CardDriver'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    CardSubscription: Utils.loadComponent('subscriptions/CardSubscription'),
  },
  name: 'SubscriptionDrivers',
})
export default class SubscriptionDrivers extends Vue {
  @State('profile') public profileState!: ProfileState
  @Action('getSubscriptions') public getSubscriptions!: () => Promise<Subscription[]>
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void

  private subscriptionId: string = ''
  private selectedDrivers: string[] = []
  private loading: boolean = false

  protected get subscriptions() {
    return this.profileState.subscriptions
  }

  protected get subscription(): Subscription {
    const c = collect(this.subscriptions)
    return c.firstWhere('id', this.subscriptionId)
  }

  protected get drivers(): Driver[] | undefined {
    if (this.profileState.drivers) {
      return this.profileState.drivers
    }
  }

  protected get changedDrivers() {
    const arr: string[] = []
    this.selectedDrivers.forEach((driverId) => {
      if (!this.isActiveDriverId(driverId)) {
        arr.push(driverId)
      }
    })
    return arr
  }

  protected mounted() {
    this.subscriptionId = (this.$route.params as any).id
    this.init()
  }

  protected init() {
    this.selectedDrivers = []
    this.getSubscriptions().then(() => {
        this.subscription.drivers.forEach((driver: Driver) => {
          this.selectedDrivers.push(driver.id)
        })
      },
    )
  }

  private isActiveDriver(driver: Driver) {
    const c = collect(this.subscription.drivers)
    return !!c.firstWhere('id', driver.id)
  }

  private isActiveDriverId(driverId: string) {
    const c = collect(this.subscription.drivers)
    return !!c.firstWhere('id', driverId)
  }

  private contactSupport(driver: Driver) {
    if (this.isActiveDriver(driver)) {
      this.openDialog(new Dialog(ConfirmDialog, {
        singleAction: true,
        imageState: 'support.svg',
        code: '',
        title: '',
        subtitle: this.$t('subscription.cannot_remove_driver'),
        confirmText: this.$t('action.close'),
      }, null, false, null))
    } else if (this.checkDriverForSubscription(driver)) {
      this.openDialog(new Dialog(ConfirmDialog, {
        singleAction: true,
        imageState: 'support.svg',
        code: '',
        title: '',
        subtitle: this.$t('subscription.driver_already_have_a_subscription'),
        confirmText: this.$t('action.close'),
      }, null, false, null))
    }
  }

  private checkDriverForSubscription(d: Driver) {
    const id = d.id
    const subscriptionMode = this.subscription.plan.booking_mode
    const subscriptions = this.subscriptions
    let result: boolean = false
    if (subscriptions) {
      subscriptions.forEach((subscription) => {
        if (subscription.plan.booking_mode === subscriptionMode) {
          return false
        }
        const driverInSubscription = subscription.drivers.filter((driver) => {
          return driver.id === id
        }).length > 0
        if (driverInSubscription) {
          result = true
        }
      })
    }
    return result
  }

  private submitChanges() {
    this.loading = true
    this.changedDrivers.forEach((driverId, i) => {
      sdk.subscription.addDriver(this.subscriptionId, driverId).then(
        () => {
          if (i === this.changedDrivers.length - 1) {
            const str = this.changedDrivers.length > 1 ? 'subscription.drivers_added' : 'subscription.driver_added'
            this.openDialog(new Dialog(ConfirmDialog, {
              singleAction: true,
              imageState: 'success.svg',
              code: '',
              title: '',
              subtitle: this.$t(str),
              confirmText: this.$t('action.close'),
            }, null, false, null))
            this.init()
            this.loading = false
          }
        },
      ).catch(() => (
        this.loading = false
      ))
    })
  }
}
