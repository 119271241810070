


































import {Vue, Component, Prop} from 'vue-property-decorator'
import {ReservationResponse} from '@/lib/kepler/interfaces'
import {State} from 'vuex-class'
import Utils from '@/utils'
import DateHelper from '@/lib/DateHelper'

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Card: Utils.loadComponent('proxy/Card/Card'),
  },
  name: 'TripList',
})
export default class TripList extends Vue {
  @State((state) => state.configuration.appConfig.fields_configuration) public fieldConfigState!: any
  @Prop({type: Object, required: true}) protected reservation!: ReservationResponse

  protected get fuelLevelHidden() {
    return !!Utils.getProp(this.fieldConfigState, ['trip', 'fuelHidden'])
  }

  protected formattedTime(date: string) {
    return DateHelper.formatDate(date, 'L LT')
  }
}
