














import {Component, Prop} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import Utils from '../../utils'
import {BookRequest, ReservationResponse, VehicleSlot} from '@/lib/kepler/interfaces'
import ConfirmDialog from '../ConfirmDialog.vue'
import {EventBus} from '@/main'

@Component({
  components: {
    Card: Utils.loadComponent('proxy/Card/Card'),
    CloseButton: Utils.loadComponent('CloseButton'),
    IsLogged: Utils.loadComponent('IsLogged'),
    VehicleQuickSummary: Utils.loadComponent('entities/vehicle/VehicleQuickSummary'),
    Divider: Utils.loadComponent('proxy/Divider'),
    CheckInOutPicker: Utils.loadComponent('CheckInOutPicker'),
    RangeEstimate: Utils.loadComponent('rangeSelector/rangeEstimate'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'VehicleBookingDateTimeSelect',
})
export default class VehicleBookingDateTimeSelect extends ConfirmDialog {
  @Action('book') public bookAction!: (req: BookRequest) => Promise<ReservationResponse>
  @Prop() public vehicleSlot!: VehicleSlot
  @Prop() public bookRequest!: BookRequest

  protected confirm() {
    this.loading = true
    EventBus.$emit('quickBookingDateSelected')
  }

  protected get confirmDisabled() {
    return this.bookRequest.start === null || this.bookRequest.end === null
  }

  protected pupulateFields(val: any) {
    this.bookRequest.start = val.start
    this.bookRequest.end = val.end
  }

  protected mounted() {
    this.bookRequest.plate = this.vehicleSlot.vehicle.plate
  }
}
