import _Vue from 'vue'
import {Theme} from '@/lib/kepler/interfaces'
import JSONtheme from '@/lib/vuetify/theme.json'

export interface Branding {
  dark?: boolean
  theme: Theme
  customCss: { [key: string]: { [cssRule: string]: string } }
  gradientAngle: { [key: string]: number }
  customClasses: { [key: string]: string[] }
}

export const branding = JSONtheme as Branding

export default {
  install(Vue: typeof _Vue): void {
    Vue.prototype.$branding = branding
  },
}
