























import {Vue, Component} from 'vue-property-decorator'
import sdk from '@/lib/kepler/sdk'
import {Action, Getter, State} from 'vuex-class'
import Utils from '@/utils'
import Button from '@/components/Button.vue'
import {Popup, Dialog} from '@/lib/kepler/interfaces'
import AddClient from '@/components/AddClient.vue'

interface Activity {
  name: string
  title: string
  description?: string
  color?: string
  icon?: string
  route?: string
  action?: string
}

@Component({
  components: {
    Button: Utils.loadComponent('Button'),
    Layout: Utils.loadComponent('proxy/Layout'),
    ProgressLinear: Utils.loadComponent('proxy/ProgressLinear'),
    BookingActivities: Utils.loadView('BookingActivities'),
    Container: Utils.loadComponent('proxy/Container'),
    CardButton: Utils.loadComponent('CardButton'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    Img: Utils.loadComponent('proxy/Image'),
    Accordion: Utils.loadComponent('proxy/Accordion/Accordion'),
    AccordionContent: Utils.loadComponent('proxy/Accordion/AccordionContent'),
    Icon: Utils.loadComponent('proxy/Icon'),
  },
})

export default class Activities extends Vue {
  @State((state) => state.profile?.driver?.profile_picture) public profilePicture!: boolean
  @State((state) => state.configuration.appConfig?.fields_configuration?.activities_hidden_sections) public h?: string
  @State((state) => !!state.configuration.appConfig?.fields_configuration?.invite_friend_enabled) public clientInvite!: boolean

  @State((state) => state.subscriptions.plans) public plans!: any[]
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Getter('mainDriver') public isMain!: boolean

  protected get hiddenList(): string[] {
    if (this.h) {
      return this.h.toLowerCase().split(',')
    }
    return []
  }

  protected get activitiesList(): Activity[] {
    const logged: Activity[] = [
      {
        name: 'profile',
        title: 'activities.profile.title',
        description: 'activities.profile.description',
        color: 'accent',
        icon: 'mdi-account',
        route: 'profile',
      },
      {
        name: 'history',
        title: 'activities.history.title',
        description: 'activities.history.description',
        color: 'accent',
        icon: 'mdi-calendar-clock',
        route: 'history',
      },
    ]
    if (this.clientInvite) {
      logged.push({
        name: 'invite',
        title: 'activities.invite.title',
        description: 'activities.invite.description',
        color: 'accent',
        icon: 'mdi-account-plus',
        action: 'invite',
      })
    }
    if (this.isMain) {
      logged.push({
        name: 'plans',
        title: 'activities.plans.title',
        description: 'activities.plans.description',
        color: 'accent',
        icon: 'mdi-text-box-multiple-outline',
        route: 'plans',
      })
    }
    const notLogged: Activity[] = [
      {
        name: 'plans',
        title: 'activities.plans_not_logged.title',
        description: 'activities.plans_not_logged.description',
        color: 'accent',
        icon: 'mdi-text-box-multiple-outline',
        route: 'plans',
      },
      {
        name: 'register',
        title: 'activities.register.title',
        description: 'activities.register.description',
        color: 'accent',
        icon: 'mdi-account-plus',
        route: 'registration',
      },
      {
        name: 'login',
        title: 'activities.login.title',
        description: 'activities.login.description',
        color: 'primary',
        icon: 'mdi-account',
        route: 'login',
      },
    ]

    const result: Activity[] = this.isLogged ? logged : notLogged

    return result.filter((a) => !this.hiddenList.includes(a.name))
  }

  protected get isLogged() {
    return sdk.people.isLogged()
  }

  protected get otherActivitiesHeader() {
    if (!this.activitiesList.length) {
      return null
    }
    const t = this.isLogged ? 'activities.other_activities.title' : 'activities.not_logged.title'
    const d = this.isLogged ? 'activities.other_activities.description' : 'activities.not_logged.description'
    const obj = {
      title: this.$isAvailable(t) || '',
      subtitle: this.$isAvailable(d) || '',
    }
    return obj.title || obj.subtitle ? obj : null
  }

  protected activityAction(a: Activity) {
    if (a.route) {
      this.$router.push({name: a.route})
    }
    if (a.action) {
      switch (a.action) {
        case 'invite':
          this.openDialog(
            new Dialog(AddClient, {}),
          )
          break
        default:
          return
      }
    }
  }
}
