
















import {Vue, Component, Prop} from 'vue-property-decorator'
import {Dialog, OpenVehicleRequest, ReservationResponse} from '@/lib/kepler/interfaces'
import bikeBtSdk from '@/lib/bikeBtSdk/bikeBtSdk'
import {Action} from 'vuex-class'
import Utils from '@/utils'
import {EventBus} from '@/main'
import ConfirmDialog from '../ConfirmDialog.vue'

export interface OpenCloseResponse {
  [key: string]: any

  channel: 'live' | 'ble' | 'bt' | 'sms'
}

declare let device: any

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Btn: Utils.loadComponent('proxy/Btn'),
    TopBar: Utils.loadComponent('TopBar'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    HelpButton: Utils.loadComponent('topbar/HelpButton'),
    CloseButton: Utils.loadComponent('CloseButton'),
    ConfirmDialog,
  },
  name: 'BookingConfirmationAndUnlock',
})
export default class BookingConfirmationAndUnlock extends Vue {
  @Prop() public reservation!: ReservationResponse
  @Prop() public action!: string
  @Prop() public response!: OpenCloseResponse
  @Prop() public openRequest!: OpenVehicleRequest
  @Action('closeOverlays') public closeOverlays!: () => void
  @Action('closePopup') public closePopup!: (index?: number) => void
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('getReservation') public getReservation: any
  @Action('selectReservation') public selectReservation!: (r: ReservationResponse) => Promise<ReservationResponse>

  protected pool!: any

  protected get vehicleType() {
    return this.reservation.vehicle_slot.vehicle.category.type.toLowerCase()
  }

  // instance methods

  protected created() {
    if (!this.response.channel) {
      this.response.channel = 'live'
    }
    switch (this.response.channel) {
      case 'live':
        this.longPoolLive()
        break
      case 'ble':
        this.longPoolBle()
        break
      case 'bt':
        break // TODO
      case 'sms':
        break // TODO
    }
  }

  protected destroyed() {
    switch (this.response.channel) {
      case 'live':
        clearTimeout(this.pool)
        break
      case 'ble':
        this.bleEvents(false)
        break
      case 'bt':
        break // TODO
      case 'sms':
        break // TODO
    }
  }

  // long pools

  protected longPoolLive() {
    this.pool = setTimeout(() => {
      this.getReservation(this.reservation).then((e: ReservationResponse) => {
        if (this.checkDoors(e.vehicle_slot.vehicle.door)) {
          this.longPoolLiveSuccess(e)
        }
        this.longPoolLive()
      })
    }, 2500)
  }

  protected longPoolLiveSuccess(e?: ReservationResponse) {
    clearTimeout(this.pool)
    if (e) {
      this.selectReservation(e)
    }
    this.closePopup()
    this.$router.replace({name: 'reservation'})
  }

  protected longPoolBle() {
    this.bleEvents(true)
    try {
      bikeBtSdk.searchByPlate(this.response.device.plate, (address: string) => {
        this.sendOpenViaBluetooth(address, this.response.device.pin)
      }, () => {
        this.openDialog(new Dialog(ConfirmDialog, {
          imageState: 'error.svg',
          code: '',
          title: this.$t('common.bluetooth_error'),
          subtitle: this.$t('common.bluetooth_request_in_timeout'),
          singleAction: true,
          showCloseButton: false,
          confirmText: 'Ok',
        }, null, false, 'bleTimeOut'))
      })
    } catch (e) {
      this.openDialog(new Dialog(ConfirmDialog, {
        imageState: 'error.svg',
        code: '',
        title: this.$t('common.bluetooth_error'),
        subtitle: '',
        singleAction: true,
        showCloseButton: false,
        confirmText: 'Ok',
      }, null, false, 'bleTimeOut'))
    }
  }

  // live crap

  protected checkDoors(status: string) {
    switch (this.action) {
      case 'unlock':
        return status === 'UNLOCKED'
      case 'lock':
        return status === 'LOCKED'
    }
  }

  // ble stuff

  protected sendOpenViaBluetooth(address: string, pin: string) {
    bikeBtSdk.open(address, pin, (status: any) => {
        if (status === 'OPEN') {
          bikeBtSdk.disconnect(address)
          this.closeOverlays()
          this.$router.replace({name: 'reservation'})
        }
      }, (status: any) => {
        switch (status) {
          case 'TIMEOUT':
            this.openDialog(new Dialog(ConfirmDialog, {
              imageState: 'error.svg',
              code: '',
              title: this.$t('common.bluetooth_error'),
              subtitle: this.$t('common.bluetooth_request_in_timeout'),
              singleAction: true,
              showCloseButton: false,
              confirmText: 'Ok',
            }, null, false, 'bleTimeOut'))
            break

          case 'BLUETOOTH_DISABLED':
            this.openDialog(new Dialog(ConfirmDialog, {
              imageState: 'error.svg',
              code: '',
              title: this.$t('common.bluetooth_disabled'),
              subtitle: this.$t('common.bluetooth_please_enable_for_open_the_bike'),
              singleAction: true,
              showCloseButton: false,
              confirmText: 'Ok',
            }, null, false, 'bleNotActive'))
            break
        }
      },
      (msg) => {
        this.$log(msg, 1)
      },
    )
  }

  protected bleEvents(on: boolean) {
    if (on) {
      EventBus.$on('bleNotActive', () => {
        this.closeOverlays()
      })
      EventBus.$on('bleTimeOut', () => {
        this.closeOverlays()
      })
    } else {
      EventBus.$off(['bleNotActive', 'bleTimeOut'])
    }
  }

  protected get typeImg() {
    const type = this.vehicleType
    switch (type.toLowerCase()) {
      case 'car':
        return 'img/booking/unlock.svg'
      case 'bike':
        return 'img/booking/bike_lock_illustr.svg'
      case 'scooter':
        return 'img/icons/scooterqr.svg'
      case 'kickscooter':
        return 'img/icons/kickscooterqr.svg'
    }
  }
}
