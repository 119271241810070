import {BleDevice} from "@/lib/BtSDKs/BleDevice";

export default class OmniScooter extends BleDevice {
  private SERV_DATA_UUID = '6e400001-b5a3-f393-e0a9-e50e24dcca9e'
  private CHAR_DATA_WRITE_UUID = '6e400002-b5a3-f393-e0a9-e50e24dcca9e'
  private CHAR_DATA_NOTIFY_UUID = '6e400003-b5a3-f393-e0a9-e50e24dcca9e'

  private key: string = ""

  private mac: string = ""

  private encKey: number = 0x00

  constructor(logger: any, mac: string, key: string) {
    super(logger)
    this.key = key
    this.mac = mac
    this.log("Initialized class for " + mac)
  }

  // https://github.com/mode80/crc8js/blob/master/crc8.js
  crc8(byte_array: number[]) {
    let table = [
      0, 94, 188, 226, 97, 63, 221, 131, 194, 156, 126, 32, 163, 253, 31, 65,
      157, 195, 33, 127, 252, 162, 64, 30, 95, 1, 227, 189, 62, 96, 130, 220,
      35, 125, 159, 193, 66, 28, 254, 160, 225, 191, 93, 3, 128, 222, 60, 98,
      190, 224, 2, 92, 223, 129, 99, 61, 124, 34, 192, 158, 29, 67, 161, 255,
      70, 24, 250, 164, 39, 121, 155, 197, 132, 218, 56, 102, 229, 187, 89, 7,
      219, 133, 103, 57, 186, 228, 6, 88, 25, 71, 165, 251, 120, 38, 196, 154,
      101, 59, 217, 135, 4, 90, 184, 230, 167, 249, 27, 69, 198, 152, 122, 36,
      248, 166, 68, 26, 153, 199, 37, 123, 58, 100, 134, 216, 91, 5, 231, 185,
      140, 210, 48, 110, 237, 179, 81, 15, 78, 16, 242, 172, 47, 113, 147, 205,
      17, 79, 173, 243, 112, 46, 204, 146, 211, 141, 111, 49, 178, 236, 14, 80,
      175, 241, 19, 77, 206, 144, 114, 44, 109, 51, 209, 143, 12, 82, 176, 238,
      50, 108, 142, 208, 83, 13, 239, 177, 240, 174, 76, 18, 145, 207, 45, 115,
      202, 148, 118, 40, 171, 245, 23, 73, 8, 86, 180, 234, 105, 55, 213, 139,
      87, 9, 235, 181, 54, 104, 138, 212, 149, 203, 41, 119, 244, 170, 72, 22,
      233, 183, 85, 11, 136, 214, 52, 106, 43, 117, 151, 201, 74, 20, 246, 168,
      116, 42, 200, 150, 21, 75, 169, 247, 182, 232, 10, 84, 215, 137, 107, 53

    ]
    var c = null;

    for (var i = 0; i < byte_array.length; i++)
      // @ts-ignore
      c = table[(c ^ byte_array[i]) % 256]

    return c;
  }


  private initStatus: string = "UNKNOWN"

  init() {
    return new Promise<void>((resolve, reject) => {
      this.scan().then(() => {
        if (this.initStatus == "INITIALIZED") {
          resolve()
        } else if (this.initStatus == "INITIALIZING") {
          reject("INIT ALREADY IN PROGRESS")
        } else {
          this.initStatus = "INITIALIZING"
          this.connect().then(() => {
            this.authCallback = () => {
              this.initStatus = "INITIALIZED"
              resolve()
              this.authCallback = null
            }

            this.subscribe(this.SERV_DATA_UUID, this.CHAR_DATA_NOTIFY_UUID, (d) => {
              this.receive(d)
            }).then(
              () => {
                this.send(this.forgePayload("AUTH", this.stringToByte(this.key)))
              }
            )
          }).catch((x) => {
            this.initStatus = "UNKNOWN"
            reject(x)
          })
        }
      })
    })
  }

  send(data: any) {
    this.log("!>" + data.length + "> " + JSON.stringify(this.intToHex(data)))
    // Encrypt and checksum
    var rand = data[3]
    data[3] = data[3] + 0x32 // Rand becomes RAND1
    for (var i = 4; i < data.length; i++) {
      data[i] = data[i] ^ rand
    }
    data.push(this.crc8(data))
    // Sending
    this.log(">" + data.length + "> " + JSON.stringify(this.intToHex(data)))
    return this.write(this.SERV_DATA_UUID, this.CHAR_DATA_WRITE_UUID, data)
  }

  private authCallback: any = null

  receive(data: any) {
    this.log("!<" + data.length + "< " + JSON.stringify(this.intToHex(data)))
    data.pop() // Pop out crc
    // @ts-ignore
    this.log("<C< " + this.crc8(data).toString(16))
    // Decrypt
    var rand = data[3] - 0x32
    for (var i = 4; i < data.length - 1; i++) {
      data[i] = data[i] ^ rand
    }
    this.log("<" + data.length + "< " + JSON.stringify(this.intToHex(data)))

    let parsed: any = {}

    // Command switch
    switch (data[5]) {
      // Auth
      case 0x01:
        parsed.command = "AUTH"
        this.encKey = data[4]
        if (this.authCallback) {
          this.authCallback()
        }
        if (data[6] == 0x01) {
          parsed.esit = "SUCCESS"
        } else {
          parsed.esit = "FAILURE " + data[6]
        }
        break;
      case 0x05:
        parsed.command = "UNLOCK"
        if (data[6] == 0x01) {
          parsed.esit = "SUCCESS"
        } else {
          parsed.esit = "FAILURE " + data[6]
        }
        parsed.timestamp = this.intToHex(data.slice(7, 10))
        break;
      case 0x15:
        parsed.command = "LOCK"
        if (data[6] == 0x01) {
          parsed.esit = "SUCCESS"
        } else {
          parsed.esit = "FAILURE " + data[6]
        }
        parsed.timestamp = this.intToHex(data.slice(7, 11))
        parsed.time = this.intToHex(data.slice(11, 15))
        break;
      case 0x60:
        parsed.command = "DATA"
        parsed.power_level = data[6]
        parsed.mode = data[7]
        parsed.speed = parseInt(this.intToHex(data.slice(8, 10)).join(""), 16) / 10
        parsed.single_mileage_km = parseInt(this.intToHex(data.slice(10, 12)).join(""), 16) * 10 / 1000
        parsed.range_km = parseInt(this.intToHex(data.slice(12, 14)).join(""), 16) * 10 / 1000
        break;
      default:
        parsed.command = "NEW:" + this.intToHex([data[5]])
        break;
    }
    if (this.operationCallback != null && (parsed.command == "LOCK" || parsed.command == "UNLOCK")) {
      this.operationCallback(parsed.esit == "SUCCESS")
      this.operationCallback = null
    }
    this.logJson(parsed)
  }

  getCommandCode(command: string): number {
    switch (command) {
      case "AUTH":
        return 0x01
      case "LOCK":
        return 0x15
      case "UNLOCK":
        return 0x05
      case "DATA":
        return 0x60
    }
    return 0x00
  }

  data() {
    this.init().then(() => {
      this.send(this.forgePayload("DATA", [
        0x01,         // Control instruction
      ]))
    })
  }

  private operationCallback: any = null

  lock() {
    return new Promise<void>((resolve, reject) => {
      if (this.operationCallback != null) {
        reject("OPERATION ALREADY IN PROGRESS")
      } else {
        this.init().then(() => {
          this.operationCallback = (esit: boolean) => {
            if (esit) {
              resolve()
            } else {
              reject()
            }
          }
          this.send(this.forgePayload("LOCK", [
            0x01,         // Control instruction
          ]))
        }).catch(reject)
      }
    })
  }

  unlock() {
    return new Promise<void>((resolve, reject) => {
      if (this.operationCallback != null) {
        reject("OPERATION ALREADY IN PROGRESS")
      } else {
        this.init().then(() => {
          this.operationCallback = (esit: boolean) => {
            if (esit) {
              resolve()
            } else {
              reject()
            }
          }
          var unix = (Math.round(+new Date() / 1000)).toString(16);
          this.send(this.forgePayload("UNLOCK", [
            0x01,         // Control instruction
            0x00,         // UserID
            0x00,         // UserID
            0x00,         // UserID
            0x00,         // UserID
            parseInt(unix.substring(0, 2), 16), // Timestamp
            parseInt(unix.substring(2, 4), 16), // Timestamp
            parseInt(unix.substring(4, 6), 16), // Timestamp
            parseInt(unix.substring(6, 8), 16), // Timestamp
            0x00
          ]))
        }).catch(reject)
      }
    })
  }

  forgePayload(command: string, data: number[]) {
    let result: number[] = []
    result = result.concat([0xA3, 0xA4])  // STX
    result = result.concat([data.length]) // LEN
    result = result.concat([Math.floor(Math.random() * (0xFF + 1))]) // RANDOM
    result = result.concat([this.encKey]) // KEY
    result = result.concat([this.getCommandCode(command)]) // COMMAND
    result = result.concat(data) // DATA
    return result
  }

  isCorrectDevice(name: string, address: string, mode: string, data: any) {
    if (name != "Scooter") {
      return false
    }

    switch (mode) {
      case "manifacturer":
        return data.substring(4, 16).toUpperCase() == this.mac.replace(/:/g, "").toUpperCase()
      case "advertisement":
        return address.replace(/:/g, "").toUpperCase() == this.mac.replace(/:/g, "").toUpperCase()
    }

    return false
  }
}
