import Vue from 'vue'

export default class Utils {
  public static loadView(name: string) {
    return () => import(/* webpackChunkName: "views-[request]" */ `@/views/${name}.vue`)
  }

  public static loadComponent(name: string) {
    return () => import(/* webpackChunkName: "components-[request]" */ `@/components/${name}.vue`)
  }

  // to get the property foo.bar.baz from a Vuex state object you would call getProp(state, ['foo', 'bar', 'baz']).
  // If any of the properties in the chain don't exist the function returns undefined
  public static getProp(obj: Record<string, any>, props: string[]): any {
    const prop = props.shift()
    if (prop === undefined) {
      return undefined
    }
    if (!obj[prop] || !props.length) {
      return obj[prop]
    }
    return Utils.getProp(obj[prop], props)
  }

  // to set a new property foo.bar.baz = true on a Vuex state object you would call setProp(state, ['foo', 'bar', 'baz'], true).
  // The function creates any nested properties that don't already exist.
  public static setProp(obj: { [i: string]: any }, props: any[], value: any) {
    const prop = props.shift()
    if (!obj[prop]) {
      Vue.set(obj, prop, {})
    }
    if (!props.length) {
      if (value && typeof value === 'object' && !Array.isArray(value)) {
        Vue.set(obj, prop, {...obj[prop], ...value})
      } else {
        Vue.set(obj, prop, value)
      }
      return
    }
    Utils.setProp(obj[prop], props, value)
  }

  public static deleteProp(obj: { [i: string]: { [v: string]: any } }, props: any[] | string) {
    const properProp = (): string => {
      if (Array.isArray(props)) {
        return props.shift()
      } else {
        return props
      }
    }

    const prop = properProp()

    if (!obj[prop]) {
      return
    }
    if (!props.length) {
      Vue.delete(obj, prop)
      return
    }
    Utils.deleteProp(obj[prop], props)
  }

  public static flushObject(obj: { [i: string]: { [k: string]: any } }) {
    for (const prop of Object.keys(obj)) {
      Utils.deleteProp(obj, prop)
    }
  }

  public static flushArray(arr: any[]) {
    return arr.length ? arr.splice(0) : arr
  }
}
