


















import {Component, Prop} from 'vue-property-decorator'
import {State} from 'vuex-class'
import {SubscriptionPlan, TosResponse} from '@/lib/kepler/interfaces'
import Utils from '@/utils'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'

@Component({
  components: {
    ConfirmDialogCallback,
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
    Img: Utils.loadComponent('proxy/Image'),
    Button: Utils.loadComponent('Button'),
  },
})
export default class PlanTermsOfServiceDialog extends ConfirmDialogCallback {
  @Prop() public tocs!: TosResponse
  @Prop() public plan!: SubscriptionPlan
  @State((state) => state.configuration.appConfig.fields_configuration) public fieldConfigState!: any

  protected tocApproved: string = ''

  protected openLinkIfPresent() {
    if (this.logo && this.logo.url) {
      cordova.InAppBrowser.open(this.logo.url, '_system')
    }
  }

  protected get cycleTranslation() {
    if (this.plan.billing_cycle === 30) {
      return this.$t('common.time_units.monthly')
    } else {
      return ''
    }
  }

  protected get logo() {
    const image = Utils.getProp(this.fieldConfigState, ['plans', 'termsOfServiceLogo'])
    if (image) {
      const [src, url] = image.split('|')
      return {src, url}
    }
  }
}
