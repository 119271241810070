
























import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action} from 'vuex-class'

import {ReservationResponse} from '@/lib/kepler/interfaces'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'
import ServiceMesh from '@/lib/serviceMesh'
import DateHelper from '@/lib/DateHelper'
import {EventBus} from '@/main'
import moment from 'moment'
import Utils from '@/utils'

@Component({
  components: {
    Plate: Utils.loadComponent('Plate'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    Layout: Utils.loadComponent('proxy/Layout'),
  },
  name: 'ActiveReservationCard',
})
export default class ActiveReservationCard extends Vue {
  // @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Action('selectReservation') public selectReservation!: (r: ReservationResponse) => Promise<ReservationResponse>
  @Action('current') public current!: any

  @Prop() protected reservation!: any

  protected progress: number = 0
  protected timeLeft: string = ''

  get vehicleName() {
    return `${this.reservation.vehicle_slot.vehicle.brand} ${this.reservation.vehicle_slot.vehicle.model}`
  }

  protected get typeColor() {
    const key = `${this.reservation.vehicle_slot.reservation_type}${this.reservation.vehicle_slot.vehicle.category.type}`.toUpperCase()
    return ServiceMesh.colors[key]
  }

  protected get isLate() {
    return this.progress > 100
  }

  protected get standardColor() {
    return this.isLate ? VuetifyColorHelper.color('error') : 'inherit'
  }

  protected get remainingTime() {
    const diff = () => {
      return moment.duration(moment.unix(this.reservation.end_timestamp).diff(moment()))
    }

    const obj = {
      days: diff().days(),
      hours: diff().hours(),
      minutes: diff().minutes(),
    }

    let str = ''

    for (const [unit, n] of Object.entries(obj)) {
      // console.log(unit, n)
      if (n !== 0) {
        str += `${Math.abs(n)}${unit.charAt(0)} `
      }
    }

    return str.trim()
  }

  protected mounted() {
    this.updateProgress(this.reservation)
    EventBus.$on('cron1', () => {
      this.current().then(() => {
        this.updateProgress(this.reservation)
      })
    })
  }

  protected destroyed() {
    EventBus.$off('cron1')
  }

  protected updateProgress(reservation: ReservationResponse) {
    const timeStart: any = reservation.start_timestamp
    const timeEnd: any = reservation.end_timestamp
    const now = new Date().getTime() / 1000
    const totalHours = (timeEnd - timeStart) / 3600
    const hoursSpent = (now - timeStart) / 3600
    this.progress = 100 / totalHours * hoursSpent

    if (reservation.vehicle_slot.reservation_type === 'RT') {
      this.timeLeft = DateHelper.getTimeOffset(now, timeEnd).formatTimeOffset('dhm')
      return
    }

    this.timeLeft = DateHelper.getTimeOffset(timeStart, new Date()).formatTimeOffset('dhm')
  }

  protected openReservationDetail() {
    this.selectReservation(this.reservation)
    this.$router.push({name: 'reservation'})
    // this.openPopup(new Popup(BookingDetail, {reservation: this.reservation}, this.$t('booking.reservation', {number: this.reservation.id})))
  }
}
