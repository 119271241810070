


















































































import {Vue, Component, Prop} from 'vue-property-decorator'
import {State} from 'vuex-class'

import {ReservationResponse, VehicleSlot} from '@/lib/kepler/interfaces'
import Utils from '@/utils'
import {VDialog} from 'vuetify/lib'
import VehicleSlotHelper from '@/lib/vehicleSlot'
import DateHelper from '@/lib/DateHelper'
import CloseButton from '@/components/CloseButton.vue'
import Locate from '@/lib/location'

interface InfoButton {
  icon: string
  id: string
  text: string
  fullwidth?: boolean
}

@Component({
  components: {
    CloseButton: Utils.loadComponent('CloseButton'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Button: Utils.loadComponent('Button'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Img: Utils.loadComponent('proxy/Image'),
    Divider: Utils.loadComponent('proxy/Divider'),
    GradientCard: Utils.loadComponent('GradientCard'),
    TripList: Utils.loadComponent('entities/reservation/TripList'),
    VehicleLocation: Utils.loadComponent('entities/vehicle/VehicleLocation'),
    VehicleInstructions: Utils.loadComponent('VehicleInstructions'),
    VDialog,
  },
  name: 'VehicleDetail',
})
export default class VehicleDetail extends Vue {
  @State((state) => state.configuration.appConfig?.fields_configuration?.parking_distance_threshold ?? '75') public distanceThreshold!: string

  @Prop() public vehicleSlot!: VehicleSlot
  @Prop() public reservation?: ReservationResponse

  @Prop({type: Boolean, default: true}) public showLocation!: boolean
  @Prop({type: Boolean, default: true}) public showInstructions!: boolean
  @Prop({type: Boolean, default: true}) public showRates!: boolean
  @Prop({type: Boolean, default: false}) public showTrips!: boolean

  protected selectedButton: string | null = null

  protected get slotHelper() {
    return new VehicleSlotHelper(this.vehicleSlot)
  }

  protected get dialogOpen() {
    return !!this.selectedButton
  }

  protected set dialogOpen(v: boolean) {
    if (!v) {
      this.selectedButton = null
    }
  }

  protected get buttons() {
    /* tslint:disable: cyclomatic-complexity */ // TODO: refactor, maybe?
    const xs = this.$vuetify.breakpoint.xsOnly
    const conditions: Record<string, boolean> = {
      rate: !!this.vehicleSlot.base_rate && this.showRates,
      info: !!this.vehicleSlot.vehicle.instructions?.length && this.showInstructions,
      trips: !!this.reservation?.trips?.length && this.showTrips,
      location: this.vehicleSlot.position && this.showLocation,
    }
    const shownNum = Object.values(conditions).filter(Boolean).length
    const buttons: InfoButton[] = [{
      id: 'rate',
      icon: 'mdi-cash-multiple',
      text: this.vehicleSlot.base_rate.name,
      fullwidth: xs && shownNum > 2 && !conditions.location,
    }, {
      id: 'info',
      icon: 'mdi-information',
      text: this.$t('booking.instructions'),
    }, {
      id: 'trips',
      icon: 'mdi-map-marker-path',
      text: this.$t('booking.trips.trips'),
    }, {
      id: 'location',
      icon: this.vehicleSlot.reservation_type === 'FF' ? 'mdi-map-marker' : 'mdi-parking',
      text: this.vehicleSlot.lot.name || '',
      fullwidth: xs,
    }]

    const arr: InfoButton[] = []
    buttons.forEach((button) => {
      if (conditions[button.id]) {
        arr.push(button)
      }
    })
    return arr
  }

  protected get image() {
    return this.vehicleSlot.lot.picture.includes('404') ? false : this.vehicleSlot.lot.picture
  }

  protected get distanceString() {
    const d: number | null = this.vehicleSlot.distance
    if (d !== null) {
      const str = this.$t('common.circa')
      if (this.$distance().toLowerCase() === 'km') {
        return str + ' ' + (d >= 1000 ? this.$distance(Math.round((d / 1000) * 10) / 10) : Math.floor(d) + 'm')
      } else {
        return str + ' ' + this.$distance(Math.round(d))
      }
    } else {
      // TODO: translate
      return false
    }
  }

  protected get destination() {
    const position = this.reservation?.current_vehicle_position || this.vehicleSlot.position
    if (position) {
      return {lat: position.latitude, lng: position.longitude, acc: null}
    }
  }

  protected get distanceFromParking() {
    const res = this.reservation
    const pos = res?.current_vehicle_position
    if (pos) {
      const distance = Locate.calculateDistance(Locate.PositionToPos(pos), Locate.PositionToPos(this.vehicleSlot.position))
      return Math.floor(distance)
    }
    return null
  }

  protected get notYetStarted() {
    return this.reservation?.status === 'CONFIRMED'
  }

  public get parsedRate() {
    const r = this.vehicleSlot.base_rate
    const parsedistance = (val: string) => this.$distance(val && Number(val) !== 1 ? val : undefined)
    if (r) {
      const [timeCost, timeCycle] = r.info?.time_rate?.split('|') ?? [null, null]
      const [distanceCost, distanceCycle] = r.info?.distance_rate?.split('|') ?? [null, null]
      const time = timeCost !== null && timeCycle !== null ? {
          cost: this.$currency(timeCost),
          cycle: this.$parseMinutes(timeCycle),
        } : null
      const distance = distanceCost !== null && distanceCycle !== null ? {
          cost: this.$currency(distanceCost),
          cycle: parsedistance(distanceCycle),
        } : null
      const usageCost = r.info?.usage_cost || null
      return {time, distance, usageCost}
    }
  }

  public get baseRateTitle() {
    return this.vehicleSlot.base_rate?.info?.title || null
  }

  protected select(idx?: number) {
    this.$set(this, 'selectedButton', typeof idx === 'number' ? this.buttons[idx] : null)
  }

  protected openInMaps() {
    if (this.destination) {
      const destination = `&destination=${this.destination.lat},${this.destination.lng}`
      const url = `https://www.google.com/maps/dir/?api=1${destination}&=travelmode=walking`
      cordova.InAppBrowser.open(url, '_system')
    }
  }

  protected parseMinutes(val: number) {
    return DateHelper.parseMinutes(this, val)
  }
}
