import {Vue, Component, Prop} from 'vue-property-decorator'
import {
  AvailabilityRequest,
  AvailabilityResponse,
  BookTimeSelectRequest,
  ExtraActionRequest, ReservationEditRequest, ReservationExtendRequest,
  ReservationResponse,
  Popup,
  Dialog,
} from '@/lib/kepler/interfaces'
import DateHelper from '@/lib/DateHelper'
import {Action, State} from 'vuex-class'
import BookingEdit from '@/views/Booking/BookingEdit.vue'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import ConfirmDialog from '@/views/ConfirmDialog.vue'
import {BookingState} from '@/store/modules/booking'
import moment from 'moment'
import QuickExtendDialog from '@/components/QuickExtendDialog.vue'
import {ProfileState} from '@/store/modules/profile'
import ServiceMesh from '@/lib/serviceMesh'
import ReservationHelper from '@/lib/reservation'

interface Highlight {
  title: string;
  content: string
  subcontent?: string;
}

@Component
export default class BookingDetailMixin extends Vue {
  @State('booking') public booking!: BookingState
  @State('profile') public profileState!: ProfileState
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('closeDialog') public closeDialog!: (index?: number) => void
  @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Action('closePopup') public closePopup!: (index?: number) => void
  @Action('editReservation') public editReservation!: (p: ReservationEditRequest) => Promise<ReservationResponse>
  @Action('deleteReservation') public deleteReservation!: (p: ReservationResponse) => Promise<any>
  @Action('vehicleAvailability') public vehicleAvailability!: (p: AvailabilityRequest) => Promise<AvailabilityResponse[]>
  @Action('extendReservation') public extendReservation!: (p: ReservationExtendRequest) => Promise<any>
  @Action('sendExtraAction') public sendExtraAction!: (p: ExtraActionRequest) => Promise<void>
  @Prop() public reservation!: ReservationResponse
  public elapsed: string = ''
  public availability: AvailabilityResponse[] = []
  public loading: boolean = false

  public get highlights() {
    const res = this.selectedReservation
    const h: Highlight[] = []

    if (res) {
      const charged = typeof res.cost === 'number' && res.status === 'CHARGED'
      const cost = charged ? `${this.$currency(res.cost as number)}` : null
      const roundTrip = res.type === 'RT' || res.type === 'CRT' || res.type === 'STR'
      const freeFloating = res.type === 'FF'

      if (roundTrip) {
        h.push({
          title: this.$t('booking.check_in'),
          content: this.format(res.start, 'L'),
          subcontent: this.format(res.start, 'LT'),
        })
        h.push({
          title: this.$t('booking.check_out'),
          content: this.format(res.end, 'L'),
          subcontent: this.format(res.end, 'LT'),
        })
      } else {
        h.push({
          title: `${this.$t('booking.start_time')}`,
          content: this.startTime[0],
          subcontent: this.startTime[1],
        })
        if (freeFloating) {
          // this adds the received trip distances, could be confusing for users as they may expect realtime distance
          // TODO: is it better to show only if freeFloating && charged?
          h.push({
            title: this.$t('booking.trips_distance'),
            content: this.$distance(this.totalTripsDistance),
            subcontent: '',
          })

        }
        h.push({
          title: res.status !== 'STARTED' ? this.$t('booking.duration') : this.$t('booking.elapsed'),
          content: DateHelper.getTimeOffset(res.start_timestamp, res.end_timestamp || new Date()).formatTimeOffset('dhm'),
          subcontent: '',
        })
      }

      if (cost) {
        h.push({
            title: this.$t('booking.total_cost'),
            content: `${cost}`,
            subcontent: '',
          },
        )
      }

      if (!this.sameDriver) {
        h.push({
          title: this.$t('common.driver'),
          content: this.reservation.driver.name,
          subcontent: this.reservation.driver.surname,
        })
      }
    }
    return h
  }

  public get totalTripsDistance(): string {
    const trips = this.selectedReservation.trips
    let distance: number = 0

    if (trips && trips.length > 0) {
      trips.forEach((trip) => {
        distance += trip.distance
      })
    }
    return distance.toFixed(2)
  }

  public get startTime() {
    const r = this.selectedReservation
    if (r.start) {
      if (moment(r.start).isSame(moment(), 'day')) {
        return [this.format(this.selectedReservation.start, 'LT'), '']
      } else {
        return [
          this.format(this.selectedReservation.start, 'L'),
          this.format(this.selectedReservation.start, 'LT'),
        ]
      }
    }
    return ['', '']
  }

  public get selectedReservation(): ReservationResponse {
    return this.reservation ? this.reservation : this.booking.selectedReservation as ReservationResponse
  }

  public get vehicleType() {
    return this.reservation.vehicle_slot.vehicle.category.type.toLowerCase()
  }

  public get isCurrent() {
    return ReservationHelper.isCurrent(this.reservation)
  }

  public get sameDriver() {
    return this.profileState.driver?.id === this.reservation.driver.id
  }

  protected get vehicleTypeColor() {
    const slot = this.reservation.vehicle_slot
    const key = `${slot.reservation_type}${slot.vehicle.category.type}`.toUpperCase()
    return ServiceMesh.colors[key]
  }

  protected get extraActions() {
    const obj: { [key: string]: string } = {}
    const hwAct = Object.entries(this.reservation.extra?.hardware_actions || {})
    if (hwAct.length) {
      hwAct.forEach(([key, val]) => {
        obj[key] = this.$isAvailable('booking.action.extra.' + key) || val
      })
    }
    return obj
  }

  public format(date: any, format: any) {
    return DateHelper.formatDate(date, format)
  }

  public setElapsed() {
    this.elapsed = DateHelper.getTimeOffset(this.selectedReservation.start_timestamp, new Date()).formatTimeOffset('dhm')
  }

  public sendExtra(operation: string) {
    const reservationNumber = this.reservation.number
    this.loading = true
    this.sendExtraAction({reservationNumber, operation}).then((r: any) => {
      if (r.result) {
        this.openDialog(new Dialog(ConfirmDialog, {
          showCloseButton: true,
          imageState: '',
          code: '',
          title: r.operation,
          subtitle: r.result,
          singleAction: true,
        }))
      }
    }).finally(()=>{this.loading = false})
  }

  protected getAvailability(): Promise<void> {
    if (this.selectedReservation.start && this.selectedReservation.end) {
      const start = DateHelper.parse(this.selectedReservation.end).unix()
      const end = DateHelper.parse(this.selectedReservation.end).add(2, 'days').unix()
      const ar: AvailabilityRequest = {
        vehicle_id: this.selectedReservation.vehicle_slot.vehicle.id,
        start,
        end,
      }

      return this.vehicleAvailability(ar).then((r: AvailabilityResponse[]) => {
        r[0].selected = true
        this.availability = r
      })
    }
    return Promise.reject('no start or end dates in selected reservation')
  }

  protected created() {
    if (!this.selectedReservation) {
      return
    }
  }

  protected edit() {
    this.openDialog(new Dialog(BookingEdit, {
      reservation: this.selectedReservation,
      confirmCallback: () => {
        this.loading = true
        const start = DateHelper.parse(this.selectedReservation.start!).unix()
        const end = DateHelper.parse(this.selectedReservation.end!).unix()

        const startUx = this.selectedReservation.start_timestamp
        const endUx = this.selectedReservation.end_timestamp

        if ((startUx && endUx) && ((start !== startUx) || (end !== endUx))) {
          this.editReservation({
            reservation_number: this.selectedReservation.number,
            start: startUx,
            end: endUx,
          }).then((r: ReservationResponse) => {
            this.selectedReservation.start = r.start
            this.selectedReservation.end = r.end
            this.confirmEdit()
          }).finally(() => {
            this.loading = false
          })
        }
      },
      cancelCallback: () => {
        this.selectedReservation.start_timestamp = DateHelper.parse((this.selectedReservation as any).start).unix()
        this.selectedReservation.end_timestamp = DateHelper.parse((this.selectedReservation as any).end).unix()
      },
    }))
  }

  protected confirmEdit() {
    this.openDialog(new Dialog(ConfirmDialog, {
      imageState: 'success.svg',
      confirmText: 'ok',
      code: '',
      subtitle: '',
      title: this.$t('booking.action.edit_confirm'),
      singleAction: true,
      emitConfirm: false,
    }))
  }

  protected openExtend() {
    this.openDialog(new Dialog(QuickExtendDialog, {
      reservation: this.reservation,
      availability: this.availability,
      onConfirm: this.extend,
    }, null, false, 'quickBookingDateSelected'))
  }

  protected extend(obj: BookTimeSelectRequest) {
    if (obj.end === null) {
      return
    }
    this.loading = true
    this.extendReservation({
      reservation_number: this.selectedReservation.number,
      end: obj.end,
    }).then((r: ReservationResponse) => {
      this.closeDialog()
      this.selectedReservation.end = r.end
      this.getAvailability()
      this.confirmEdit()
    }).finally(() => {
      this.loading = false
    })
  }

  protected cancelReservation() {
    this.openDialog(new Dialog(ConfirmDialogCallback, {
      code: '',
      title: this.$t('booking.action.cancel_confirm'),
      subtitle: '',
      confirmText: this.$t('booking.action.cancel'),
      cancelText: this.$t('booking.action.keep'),
      confirmColor: 'error',
      imageState: 'warn.svg',
      confirmCallback: () => {
        this.loading = true
        this.deleteReservation(this.selectedReservation).then(() => {
          this.closePopup()
        }).finally(() => {
          this.loading = false
        })
      },
      cancelCallback: () => {
        return 1
      },
    }))
  }
}
