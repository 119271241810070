
































































import {Component, Prop} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import Login from '@/views/Login.vue'
import {LoginByPhoneCodeRequest, LoginResponse, Popup, Dialog} from '@/lib/kepler/interfaces'
import Utils from '@/utils'
import PhoneVerificationView from '@/views/PhoneVerification.vue'
import Validations from '@/lib/Validations'
import ResetPasswordView from '@/views/ResetPassword.vue'
import sdk from '@/lib/kepler/sdk'

@Component({
  components: {
    Card: Utils.loadComponent('proxy/Card/Card'),
    Layout: Utils.loadComponent('proxy/Layout'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    Button: Utils.loadComponent('Button'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    CloseButton: Utils.loadComponent('CloseButton'),
    PhoneWithPrefix: Utils.loadComponent('PhoneWithPrefix'),
  },
  name: 'LoginDialog',
})
export default class LoginDialog extends Login {
  @Action('requestLoginByPhone') public requestLoginByPhone!: (p: LoginByPhoneCodeRequest) => Promise<any>
  @Action('closeDialog') public closeDialog!: (index?: number) => void

  protected rules: any = Validations.rules
  protected smsLogin: boolean = false
  protected phone: string = ''
  @Prop({type: Function}) private callback!: (p: LoginResponse) => void
  @Prop({type: Boolean, default: false}) private leaveOpen!: boolean

  protected loginRequest() {
    if ((this.$refs as any).loginForm.validate()) {
      this.loading = true
      sdk.people.login(this.credentials).then((r) => {
        this.callback(r.data)
        if (!this.leaveOpen) {
          this.closeDialog()
        }
      }).catch(() => {
        // this.valid = false
      }).finally(() => {
        this.loading = false
      })
    }
  }

  protected toggleSmsLogin() {
    this.smsLogin = !this.smsLogin
  }

  protected verifyPhone() {
    this.loading = true
    this.requestLoginByPhone({mobile_number: this.phone}).then(() => {
      this.openDialog(new Dialog(PhoneVerificationView, {
        mobileNumber: this.phone, isDialog: true, quick: true, callback: (r: LoginResponse) => {
          this.callback(r)
          if (!this.leaveOpen) {
            this.closeDialog()
          }
        },
      }))
      this.loading = false
    }, () => {
      this.loading = false
    })
  }

  protected openResetPasswordView() {
    this.closeDialog()
    this.openPopup(new Popup(ResetPasswordView, null, this.$t('login.reset_your_password'), false))
  }
}
