

































































import {Component} from 'vue-property-decorator'
import Utils from '@/utils'
import VehicleAddReport from '@/views/Vehicle/VehicleAddReport.vue'
import {EventBus} from '@/main'
import {ReservationResponse, Dialog} from '@/lib/kepler/interfaces'

@Component({
  components: {
    VehicleDamage: Utils.loadComponent('entities/vehicle/VehicleDamage'),
    FullListHistory: Utils.loadView('Vehicle/VehicleAddReport'),
    SelectTag: Utils.loadComponent('proxy/Inputs/SelectTag'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    TextArea: Utils.loadComponent('proxy/Inputs/TextArea'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    Container: Utils.loadComponent('proxy/Container'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Button: Utils.loadComponent('Button'),
    Btn: Utils.loadComponent('proxy/Btn'),
  },
  name: 'VehicleAddReportDialog',
  extends: VehicleAddReport,
})
export default class VehicleAddReportDialog extends VehicleAddReport {
  [x: string]: any

  protected selectReservation(r: ReservationResponse) {
    this.openDialog(new Dialog(VehicleAddReportDialog, {
      vehicle: r.vehicle_slot.vehicle,
    }, this.$t('vehicle.report.diary_report')))
  }

  protected send() {
    if (this.vehicle) {
      this.reportRequest.vehicle_id = this.vehicle.id
      this.loading = true
      if (this.isUnavailableParkReport) {
        this.reportRequest.description = this.$t('vehicle.report.park_occupied_full', {
          streetName: this.streetName,
          streetNumber: this.streetNumber,
        })
      }
      if (!navigator.onLine) {
        this.sendReport(this.reportRequest).finally(() => {
          EventBus.$emit('sentReport')
          this.closeDialog()
        })
      }
      this.sendReport(this.reportRequest).then(() => {
        EventBus.$emit('sentReport')
        this.closeDialog()
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
