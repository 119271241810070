























import {Component} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import BookingDetailMixin from '@/lib/BookingDetailMixin'
import Utils from '@/utils'

@Component({
  components: {
    BookingActions: Utils.loadComponent('BookingActions'),
    ReservationVehicleAndDates: Utils.loadComponent('entities/reservation/ReservationVehicleAndDates'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Container: Utils.loadComponent('proxy/Container'),
    CardTitle: Utils.loadComponent('proxy/Card/CardTitle'),
    VehicleDetail: Utils.loadComponent('entities/vehicle/VehicleDetail'),
    Divider: Utils.loadComponent('proxy/Divider'),
  },
  name: 'HistoryBookingDetail',
  mixins: [BookingDetailMixin],
})
export default class HistoryBookingDetail extends mixins<BookingDetailMixin>(BookingDetailMixin) {
  protected get isFuture() {
    const id = this.reservation.id
    return !!this.booking.futureReservations.find((res) => res.id === id)
  }

  protected get showLocation() {
    const bm = this.reservation.type
    return (this.isFuture || this.isCurrent) && (bm === 'STR' || bm?.endsWith('RT'))
  }

  protected get showRates() {
    return this.reservation.type !== 'STR'
  }
}
