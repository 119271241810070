

































import {Vue, Component} from 'vue-property-decorator'
import {State} from 'vuex-class'
import Utils from '@/utils'
import {AppConfig, CheckpointStatus} from '@/lib/kepler/interfaces'
import Icon from '@/components/proxy/Icon.vue'
import {AppConfigState} from '@/store/modules/configuration'

@Component({
  components: {
    Icon,
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Button: Utils.loadComponent('Button'),
    Divider: Utils.loadComponent('proxy/Divider'),
    LoopingBG: Utils.loadComponent('LoopingBG'),
  },
})
export default class Update extends Vue {
  @State((state) => state.configuration) public config!: AppConfigState

  protected platform = cordova.platformId
  protected release = this.$env.RELEASE

  protected storeNames: { [key: string]: string } = {
    android: this.$t('update.stores.android'),
    ios: this.$t('update.stores.ios'),
  }

  protected get appConfig() {
    return this.config.appConfig
  }

  protected get supported() {
    return ['browser', 'android', 'ios'].includes(this.platform)
  }

  protected get title() {
    let str = 'update.title'
    if (this.status === 'needs-update') {
      str = 'update.title_required'
    }
    if (this.platform === 'browser') {
      str = 'update.title_browser'
    }
    return this.$t(str)
  }

  protected get status() {
    return this.$route.params.status as CheckpointStatus || 'needs-update'
  }

  protected get stores() {
    const arr: Array<{ platform: string, name: string; url: string }> = []
    const urls = Object.keys(this.config.appConfig).filter((cfg) => {
      return cfg.endsWith('_store_url')
    })
    urls.forEach((url) => {
      arr.push({
        platform: url.split('_')[0],
        name: this.storeNames[url.split('_')[0]],
        url: this.config.appConfig[url],
      })
    })
    return arr
  }

  protected strings(release?: boolean) {
    if (release) {
      return {
        version: this.config.release,
        required: this.config.releaseRequired,
      }
    } else {
      const v = this.config.versionNumber
      const r = this.config.versionNumberRequired
      return {
        version: this.$isAvailable('update.current_version') ? this.$t('update.current_version', {v}) : v,
        required: this.$isAvailable('update.required_version') ? this.$t('update.required_version', {r}) : r,
      }
    }
  }

  protected open(url: string) {
    cordova.InAppBrowser.open(url, '_system')
  }
}
