





















import {Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'
import CardButton from './CardButton.vue'

@Component({
  components: {
    Button: Utils.loadComponent('Button'),
    Img: Utils.loadComponent('proxy/Image'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    CardButton,
  },
})
export default class CardDriver extends CardButton {
  @Prop({}) protected image?: string
  @Prop({}) protected id?: string
  @Prop({}) protected name?: string
  @Prop({}) protected status!: string
  @Prop({}) protected hasPlan!: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) protected checkSubscription!: boolean

  protected get statusFormatted() {
    const str = this.status.replace(' ', '_').toLowerCase()
    return {
      status: this.$t(`profile.status.${str}`),
      color: (() => {switch (this.status) {
        case 'ACTIVE':
          return 'success'
        case 'SUSPENDED':
          return 'error'
        case 'PENDING REVIEW':
          return 'warning'
        default:
          return 'accent'
      }})(),
    }
  }

  protected goToSubs() {
    this.$router.push({name: 'my subscriptions'})
  }
}
