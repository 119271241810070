import {ActionTree, MutationTree} from 'vuex'
import sdk from '@/lib/kepler/sdk'
import {
  SubscriptionPlan,
  Rate,
  RatesForCategoryRequest,
} from '@/lib/kepler/interfaces'
import rootState, {RootState} from '@/store'
import {AxiosResponse} from 'axios'

export class SubscriptionsState {
  public plans: SubscriptionPlan[] | null = null
}

const mutations: MutationTree<SubscriptionsState> = {
  PLANS(stateS, payload) {
    stateS.plans = payload
  },
  RATES(stateS, payload) {
    // please kill me
    if (stateS.plans !== null) {
      const planKey = stateS.plans.findIndex((obj: SubscriptionPlan) =>  obj.id === payload.id)
      const plan: SubscriptionPlan = stateS.plans[planKey]
      const rates0: Rate[] = plan.rates
      const rates1: Rate[] = payload.data
      const rates2: any[] = []
      rates0.forEach((itm: Rate, i: number) => {
        rates2.push(Object.assign({}, itm, rates1[i]))
      })
      stateS.plans[planKey].rates = rates2
    }
  },
  PURGE_PLANS(stateS) {
    stateS.plans = []
  },
}

const actions: ActionTree<SubscriptionsState, RootState> = {
  purge({commit}) {
    commit('PURGE_PLANS')
  },
  getRates({commit, dispatch}, planId) {
    return sdk.subscription.rates(planId)
      .then((r: AxiosResponse<Rate[]>) => {
        commit('RATES', {data: r.data, id: planId})
        dispatch('sleep', 'rates')
        return r.data
      })
  },
  getPlans({commit, dispatch}): Promise<SubscriptionPlan[]> {
    return new Promise((resolve) => {
      const action = sdk.people.isLogged() ? sdk.subscription.availablePlans : sdk.subscription.publicPlans
      action()
        .then((r: AxiosResponse<SubscriptionPlan[]>) => {
          commit('PLANS', r.data)
          dispatch('sleep', 'plans')
          resolve(r.data)
        })
    })
  },
  getRatesForCategory({dispatch}, payload: RatesForCategoryRequest) {
    return dispatch('getRates', payload.planId).then((data: Rate[]) => {
      return data.filter((item) => item.vehicle_category.id === payload.categoryId)[0]
    })
  },
}

const getters = {
  defaultPlan: (s: SubscriptionsState) => s.plans?.find((p) => {
    return p.id === rootState.getters.defaultPlanId
  }) || null,
}

export default {
  state: new SubscriptionsState(),
  mutations,
  actions,
  getters,
}
