





























import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import {
  BookRequest,
  BookTimeSelectRequest,
  Dialog,
  Estimate,
  EstimateRequest,
  Popup,
  ReservationResponse,
  VehicleSlot,
} from '@/lib/kepler/interfaces'

import moment from 'moment'
import Utils from '@/utils'

import VehicleBookingAddMemoDialog from '@/views/Vehicle/VehicleBookingAddMemoDialog.vue'
import VehicleConfirmBooking from '@/views/Vehicle/VehicleConfirmBooking.vue'

@Component({
  components: {
    RangeEstimate: Utils.loadComponent('rangeSelector/rangeEstimate'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Accordion: Utils.loadComponent('proxy/Accordion/Accordion'),
    AccordionContent: Utils.loadComponent('proxy/Accordion/AccordionContent'),
    Btn: Utils.loadComponent('proxy/Btn'),
    Button: Utils.loadComponent('Button'),
    Chip: Utils.loadComponent('proxy/Chip'),
    CircleStatus: Utils.loadComponent('CircleStatus'),
    Container: Utils.loadComponent('proxy/Container'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Divider: Utils.loadComponent('proxy/Divider'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Layout: Utils.loadComponent('proxy/Layout'),
    VehicleInstructions: Utils.loadComponent('VehicleInstructions'),
    VehicleDetail: Utils.loadComponent('entities/vehicle/VehicleDetail'),
    VehicleQuickInfo: Utils.loadComponent('entities/vehicle/VehicleQuickInfo'),
    VehicleTypeChip: Utils.loadComponent('entities/vehicle/VehicleTypeChip'),
  },
  name: 'VehicleBookingSummary',
})
export default class VehicleBookingSummary extends Vue {
  @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('bookEstimate') public bookEstimate!: (r: EstimateRequest) => Promise<Estimate>
  @Action('book') public bookAction!: (req: BookRequest) => Promise<ReservationResponse>

  @Prop() protected vehicleSlot!: VehicleSlot
  @Prop() protected reservationRequest!: BookRequest

  protected estimateRequest: BookTimeSelectRequest | null = null

  protected formatTime(timestamp: number) {
    const t = moment.unix(timestamp)
    return t.format('L') + '\n' + t.format('LT')
  }

  protected book() {
    this.openDialog(new Dialog(VehicleBookingAddMemoDialog, {
      vehicle: this.vehicleSlot.vehicle,
      bookRequest: this.reservationRequest,
      confirmCallback: () => {
        this.bookAction(this.reservationRequest)
          .then(() => {
            this.openPopup(new Popup(VehicleConfirmBooking, {vehicleSlot: this.vehicleSlot}, null, true))
          }).catch()
      },
    }))
  }

  protected created() {
    this.estimateRequest = {start: this.reservationRequest.start, end: this.reservationRequest.end}
  }
}
