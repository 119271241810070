import {ReservationResponse} from '@/lib/kepler/interfaces'
import DateHelper from '@/lib/DateHelper'

export default class ReservationHelper {
  private readonly r: ReservationResponse

  constructor(obj: ReservationResponse) {
    this.r = obj
  }

  public static isCurrent(r: ReservationResponse) {
    let current: boolean = false
    const now = DateHelper.getTimestamp()
    const start = r.start_timestamp || 0
    const end = r.end_timestamp || 0
    const started = r.status === 'STARTED'
    const confirmed = r.status === 'CONFIRMED'
    if (start && end && (started || confirmed)) {
      current = now > start && end >= now
    }
    return current
  }

  public static comparePositions(r1: ReservationResponse, r2?: ReservationResponse) {
    const sameLat = r1.current_vehicle_position?.latitude === r2?.current_vehicle_position?.latitude
    const sameLng = r1.current_vehicle_position?.longitude === r2?.current_vehicle_position?.longitude
    return sameLat && sameLng
  }

  public static same(r1: ReservationResponse, r2?: ReservationResponse) {
    return r2 !== undefined &&
      (r1.updated_timestamp === r2.updated_timestamp) && // sameUpdated
      (r1.start_timestamp === r2.start_timestamp) && // sameStart
      (r1.end_timestamp === r2.end_timestamp) && // sameEnd
      (r1.status === r2.status) && // sameStatus
      this.comparePositions(r1, r2) && // samePosition
      (JSON.stringify(r1.extra) === JSON.stringify(r2.extra))// sameExtra
  }
}
