import {ResourceLoader} from '../utils/ResourceLoader'
import {Base} from './Base'
import {LatLng} from '../models/LatLng'

declare namespace google {
  let maps: any
}

export class GoogleMap extends Base {

  public meta: any = {}

  public loadResources() {
    const url = 'https://maps.googleapis.com/maps/api/js?libraries=visualization&key=' + this.config.apiKey // TODO: Proxy url
    return [
      ResourceLoader('script', 'google-map', url),
      ResourceLoader('css-inline', 'google-map', '.google-maps-cluster-icon{ z-index: 99999; }'),
    ]
  }

  public initMap(element: HTMLElement, initConfig: any, s: any): void {
    this.map = new google.maps.Map(element, {
      styles: this.config.style,
      center: this.toGoogleLatLng(initConfig.center),
      zoom: initConfig.zoom,
      // Disable buttons
      fullscreenControl: false,
      disableDefaultUI: true,
      zoomControl: false,
      scaleControl: false,
      clickableIcons: false,
      streetViewControl: false,

    })
    // Allow single infowindow and auto close (to match leaflet default behaviour)
    // @ts-ignore
    this.meta.infoWindow = new google.maps.InfoWindow

    this.map.addListener('click', () => {
      this.meta.infoWindow.close()
    })
    s()
  }

  public setClickListener(l: (p: LatLng) => void): void {
    this.map.addListener('click', (ev: any) => {
      l(new LatLng(ev.latLng.lat(), ev.latLng.lng()))
    })
  }

  public pan(p: LatLng): void {
    // @ts-ignore
    this.map.panTo(this.toGoogleLatLng(p))
  }

  public zoom(level: number): void {
    this.map.setZoom(level)
  }

  public cleanChild(c: any): void {
    c.setMap(null)
  }

  public addPolyline(points: LatLng[], lineWidth: number, color: string, tooltip: string | null = null): void {
    // @ts-ignore
    return new google.maps.Polyline({
      path: points,
      map: this.map,
      strokeWeight: lineWidth,
      strokeColor: color,
    })
  }

  public addPolygon(points: LatLng[], lineWidth: number, color: string, popupElement: any, click: () => void): void {
    // @ts-ignore
    let i = new google.maps.Polygon({
      path: points,
      map: this.map,
      strokeWeight: lineWidth,
      strokeColor: color,
      strokeOpacity: 1.0,
      fillColor: color,
      fillOpacity: 0.35,
    })

    if (click) {
      i.addListener('click', () => {
        click()
      })
    }

    if (popupElement) {
      i.addListener('click', (circle: any) => {
        this.meta.infoWindow.setPosition(circle.latLng)
        this.meta.infoWindow.setContent(popupElement)
        this.meta.infoWindow.open(this.map, i)
      })
    }

    return i
  }

  public addCircle(point: LatLng, radius: number, popupElement: any, click: () => void) {
    // @ts-ignore
    let i = new google.maps.Circle({
      // @ts-ignore
      center: new google.maps.LatLng(point.lat, point.longitude),
      map: this.map,
      radius: radius,
    })

    if (click) {
      i.addListener('click', () => {
        click()
      })
    }

    if (popupElement) {
      i.addListener('click', (circle: any) => {
        this.meta.infoWindow.setPosition(circle.latLng)
        this.meta.infoWindow.setContent(popupElement)
        this.meta.infoWindow.open(this.map, i)
      })
    }

    return i
  }

  public addMarker(point: LatLng, icon: string, popupElement: any, click: () => void): void {
    let iconConfig: any = null

    if (icon) {
      iconConfig = {
        url: icon,
        // @ts-ignore
        anchor: new google.maps.Point(15, 15), // Half size to center
        // @ts-ignore
        scaledSize: new google.maps.Size(30, 30),
      }
    }

    // @ts-ignore
    const i = new google.maps.Marker({
      // @ts-ignore
      position: this.toGoogleLatLng(point),
      map: this.map,
      icon: iconConfig,
    })

    // Infobox detection
    if (popupElement) {
      i.addListener('click', () => {
        this.meta.infoWindow!.setContent(popupElement)
        this.meta.infoWindow!.open(this.map, i)
      })
    }
    // Click detection
    if (click) {
      i.addListener('click', () => {
        click()
      })
    }

    return i
  }

  public markerClusterLib(): any[] {
    const u = 'https://unpkg.com/@google/markerclustererplus@4.0.1/dist/markerclustererplus.min.js'
    return [
      ResourceLoader('script', 'google-map-marker-clusterer', u),
    ]
  }

  public markerCluster(item: any, child: any): void {
    // Bypass reactivity edge-case
    // @ts-ignore
    if (typeof google === 'undefined') {
      return
    }

    if (item) {
      item.clearMarkers()
    }
    // @ts-ignore
    return new MarkerClusterer(this.map, child.map((c: any) => c.item), {
      // m1.png - m2.png - m3.png - m4.png - m5.png
      imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
      maxZoom: 17,
    })
  }

  public addHeatmap(points: LatLng[]): void {
    // @ts-ignore
    return new google.maps.visualization.HeatmapLayer({
      // @ts-ignore
      data: points.map((p) => new google.maps.LatLng(p.lat, p.longitude)),
      map: this.map,
      radius: 20,
      opacity: 0.8,
    })

  }

  public getBoundsAndPan(points: LatLng[]) {
    var bounds = new google.maps.LatLngBounds()
    points.forEach((p: LatLng) => {
      bounds.extend(new google.maps.LatLng(p.lat, p.lng))
    })
    this.map.fitBounds(bounds)
  }

  private toGoogleLatLng(pos: LatLng) {
    return new google.maps.LatLng(pos.lat, pos.lng)
  }
}
