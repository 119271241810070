







import {Vue, Component, Prop} from 'vue-property-decorator'
import Layout from '../components/proxy/Layout.vue'
import Btn from '../components/proxy/Btn.vue'
import Icon from '../components/proxy/Icon.vue'
import BrowserCamera from '@/components/BrowserCamera.vue'

class CameraDefaultOptions implements CameraOptions {
  public allowEdit = false
  public cameraDirection = 0
  public correctOrientation = true
  public destinationType = 0
  public encodingType = 0
  public mediaType = 0
  public quality = 80
  public saveToPhotoAlbum = false
  public sourceType = 1
  public targetHeight = 1024
  public targetWidth = 1024
}

@Component({
  components: {BrowserCamera, Icon, Btn, Layout},
})
export default class DevCamera extends Vue {
  @Prop({
    type: Object,
    default: () => new CameraDefaultOptions(),
  }) public cameraOptions!: CameraOptions
  @Prop({
    type: Function,
    required: true,
  }) public successCallback!: (picture: string) => Promise<void>

  public get platform() {
    return device.platform.toLowerCase()
  }

  protected getPicture() {
    this.getPictureByCordova().then((r) => {
      if (typeof r === 'string') {
        this.successCallback(r)
      }
    })
  }

  private getPictureByBrowser(r: any) {
    // console.log(r)
    this.successCallback(r)
  }

  private getPictureByCordova() {
    return new Promise(((resolve, reject) => {
      navigator.camera.getPicture(resolve, reject, this.cameraOptions)
    }))
  }

}
