



























































import {Component} from 'vue-property-decorator'
import Vue from 'vue'
import {Action, State} from 'vuex-class'
import {TermOfServicesState} from '@/store/modules/termOfServices'
import Container from '@/components/proxy/Container.vue'
import Layout from '@/components/proxy/Layout.vue'
import Accordion from '@/components/proxy/Accordion/Accordion.vue'
import AccordionContent from '@/components/proxy/Accordion/AccordionContent.vue'
import Card from '@/components/proxy/Card/Card.vue'
import CardTitle from '@/components/proxy/Card/CardTitle.vue'
import CardText from '@/components/proxy/Card/CardText.vue'
import moment from 'moment'
import {TocElement, TocSignResponse} from '@/lib/kepler/interfaces'
import Button from '@/components/Button.vue'
import CustomIcon from '@/components/CustomIcon.vue'
import ProgressCircular from '@/components/proxy/ProgressCircular.vue'
import Utils from '@/utils'

@Component({
  components: {
    IsLogged: Utils.loadComponent('IsLogged'),
    ProgressCircular,
    CustomIcon,
    Button,
    CardText,
    CardTitle,
    Card,
    AccordionContent,
    Accordion,
    Layout,
    Container,
  },
  name: 'Contracts',
})
export default class Contracts extends Vue {
  @State('termOfServices') public termOfServices!: TermOfServicesState
  @Action('getTocs') public getTocs!: () => void
  @Action('getToc') public getToc!: (id: string) => Promise<TocElement>
  @Action('signToc') public signToc!: (id: string) => Promise<TocSignResponse>
  @Action('unsignToc') public unsignToc!: (id: string) => Promise<any>

  public loading: boolean = false
  public downloadedTocs: string[] = []
  public singleTocData: TocElement | null = null

  // TODO: make this configurable through backoffice
  public readBeforeYouSignYouDummy: boolean = false

  public get singleTocId() {
    if (this.$route.hash) {
      const toc = this.$route.hash.replace('#', '')
      this.getSingleToc(toc)
      return toc
    }
    return null
  }

  public getSingleToc(id: string) {
    this.getToc(id).then((r: TocElement) => {
      this.singleTocData = r
    })
  }

  public formatDate(date: string) {
    return moment(date).format('DD/MM/YYYY')
  }

  public parseStatusClass(status: string, optional: boolean) {
    switch (status) {
      case 'EXPIRED':
        return 'grey--text'
      case 'SIGNED':
        return 'success--text'
      case 'UPCOMING':
        return 'warning--text'
      case 'MISSING':
        return optional ? 'warning--text' : 'error--text'
    }
  }

  public approvable(status: string) {
    // 'SIGNED' | 'MISSING' | 'EXPIRED' | 'UPCOMING'
    return status === 'MISSING' || status === 'UPCOMING'
  }

  public fileName(path: string) {
    return path.split(/[\s/]+/).pop()
  }

  public downloadToc(el: TocElement) {
    if (el.toc.attachment) {
      cordova.InAppBrowser.open(el.toc.attachment, '_system')
      if (!this.wasDownloaded(el)) {
        this.downloadedTocs.push(el.toc.id)
      }
    }
  }

  public wasDownloaded(el: TocElement) {
    if (this.readBeforeYouSignYouDummy && el.toc.attachment) {
      return this.downloadedTocs.includes(el.toc.id)
    }
    return true
  }

  public sign(id: string): Promise<any> {
    this.loading = true
    return this.signToc(id).finally(() => {
      this.loading = false
    })
  }

  public unsign(id: string): Promise<any> {
    this.loading = true
    return this.unsignToc(id).finally(() => {
      this.loading = false
    })
  }

  protected created() {
    this.getTocs()
  }
}
