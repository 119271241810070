import {log} from '@/lib/plugins/logger'
import rootState from '@/store'
import {Position as LongPos, Pos} from '@/lib/kepler/interfaces'

export interface Position {
  lat: number
  lng: number
  acc?: number | null
  alt?: number | null
  hdg?: number | null
  spd?: number | null
}

export default class Locate {
  public static locate(
    cb: (pos: Position) => void,
    defaultPosition: Position,
    errorCb: (defaultPosition: any, error: any) => void,
  ) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        cb({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          acc: position.coords.accuracy,
          alt: position.coords.altitude,
          hdg: position.coords.heading,
          spd: position.coords.speed,
        })
      },
      (error) => {
        errorCb(defaultPosition, error)
      },
      {
        maximumAge: 3000,
        timeout: 15000,
        enableHighAccuracy: true,
      },
    )
  }

  public static please_locate(cb: (pos: Position | null) => void) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        cb({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          acc: position.coords.accuracy,
          alt: position.coords.altitude,
          hdg: position.coords.heading,
          spd: position.coords.speed,
        })
      },
      (error) => {
        log(error.message, 3)
        cb(null)
      },
      {
        maximumAge: 8000,
        timeout: 15000,
        enableHighAccuracy: true,
      },
    )
  }

  public static please_keep_locate(cb: (pos: Position | null) => void, timeout: number = 300000) {
    if (rootState.getters.debugMode) {
      log('starting watcher')
    }
    let latest: any
    return navigator.geolocation.watchPosition((position) => {
      const pos = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
        acc: position.coords.accuracy,
        alt: position.coords.altitude,
        hdg: position.coords.heading,
        spd: position.coords.speed,
      }
      if (JSON.stringify(pos) !== JSON.stringify(latest)) { // filter duplicated positions
        latest = pos
        cb(pos)
      }
    }, (error) => {
      log(error.message, 3)
      cb(null)
    }, {
      enableHighAccuracy: true,
      maximumAge: 1500,
      timeout: timeout,
    })
  }

  public static please_stop_locate(watchId: number) {
    log('stopping watcher ' + watchId)
    navigator.geolocation.clearWatch(watchId)
  }

  public static getCompassHeading(cb: (pos: Partial<Position> | null) => void) {
    return navigator.compass?.watchHeading(
      (hdg) => {
        cb({
          hdg: hdg.trueHeading,
          acc: hdg.headingAccuracy,
        })
      }, (error => {
        log(error, 3)
        cb(null)
      }),
      {},
    )
  }

  public static stopCompassWatcher(watchID: number) {
    navigator.compass.clearWatch(watchID)
  }

  public static preciseLocation(cb: (pos: Position) => void, defaultPosition: Position) {
    const getAccurateCurrentPosition = (successCallback: PositionCallback, errorCallback?: PositionErrorCallback, geoprogress?: any, options?: any) => {
      let lastCheckedPosition: any
      let locationEventCount: number = 0
      let watchID: any
      let timerID: any

      options = options || {}

      const checkLocation = (position: any) => {
        lastCheckedPosition = position
        locationEventCount = locationEventCount + 1
        // We ignore the first event unless it's the only one received because some devices seem to send a cached
        // location even when maxaimumAge is set to zero
        if ((position.coords.accuracy <= options.desiredAccuracy) && (locationEventCount > 1)) {
          clearTimeout(timerID)
          navigator.geolocation.clearWatch(watchID)
          foundPosition(position)
        } else {
          geoprogress(position)
        }
      }

      const stopTrying = () => {
        navigator.geolocation.clearWatch(watchID)
        foundPosition(lastCheckedPosition)
      }

      const onError = (error: any) => {
        clearTimeout(timerID)
        navigator.geolocation.clearWatch(watchID)
        if (errorCallback) {
          errorCallback(error)
        }
      }

      const foundPosition = (position: any) => {
        successCallback(position)
      }

      if (!options.maxWait) {
        options.maxWait = 10000
      } // Default 10 seconds
      if (!options.desiredAccuracy) {
        options.desiredAccuracy = 20
      } // Default 20 meters
      if (!options.timeout) {
        options.timeout = options.maxWait
      } // Default to maxWait

      options.maximumAge = 0 // Force current locations only
      options.enableHighAccuracy = true // Force high accuracy (otherwise, why are you using this function?)

      watchID = navigator.geolocation.watchPosition(checkLocation, onError, options)
      timerID = setTimeout(stopTrying, options.maxWait) // Set a timeout that will abandon the location loop
    }

    getAccurateCurrentPosition(
      (position) => {
        cb({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          acc: position.coords.accuracy,
          alt: position.coords.accuracy,
          hdg: position.coords.heading,
          spd: position.coords.speed,
        })
      },
      (error) => {
        log(error.message, 3)
        cb(defaultPosition)
      },
      () => {
        //
      },
      {
        desiredAccuracy: 20,
        maxWait: 5000,
      },
    )
  }

  public static calculateDistance(position1: Position, position2: Position) {
    const R = 6371 // Radius of the earth in km
    const dLat = this.deg2rad(position2.lat - position1.lat)  // deg2rad below
    const dLon = this.deg2rad(position2.lng - position1.lng)
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(position1.lat)) * Math.cos(this.deg2rad(position2.lat)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    return R * c * 1000// Distance in metres
  }

  public static deg2rad(deg: number) {
    return deg * (Math.PI / 180)
  }

  public static PositionToPos(p: LongPos): Pos {
    const lat = p.latitude
    const lng = p.longitude
    const acc = p.accuracy
    return {lat, lng, acc}
  }

  public static PosToPosition(p: Pos): LongPos {
    const latitude = p.lat
    const longitude = p.lng
    return {latitude, longitude}
  }
}
