











import {Vue, Component, Prop} from 'vue-property-decorator'
import {VLayout, VFlex, VSwitch, VIcon} from 'vuetify/lib'

@Component({
  components: {
    VLayout,
    VFlex,
    VSwitch,
    VIcon,
  },
})
export default class ChecklistItem extends Vue {
  @Prop({required: true, type: String, default: () => ''}) private title!: string
  @Prop({required: false, type: String, default: () => ''}) private description!: string
  @Prop({required: false, type: String}) private icon?: string
  @Prop({required: true, type: Boolean, default: false}) private value!: boolean

  private get internalValue() {
    return this.value
  }

  private set internalValue(v: boolean) {
    this.$emit('input', v)
  }
}
