



















import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '../utils'
import ServiceMesh from '../lib/serviceMesh'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'

@Component({
  components: {
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Layout: Utils.loadComponent('proxy/Layout'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
  },
  name: 'FuelLevel',
})
export default class FuelLevel extends Vue {
  @Prop({type: String}) protected type!: string
  @Prop({type: String}) protected vehicleType!: string
  @Prop({type: Number}) protected level!: number
  @Prop({type: Number || null, default: null}) protected range!: number | null
  @Prop({
    type: Boolean,
    default: false,
  }) protected text!: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) protected oldBat!: boolean

  // TODO: gmaps mounts the component while still hidden, find some hook that works.
  protected barOverflow: boolean = false
  protected color: string = ''
  protected colorIsLight: boolean | null = null
  protected colorIsDark: boolean | null = null
  protected barText: string = ''
  protected barStyle = {backgroundColor: '', width: ''}
  protected symbolType: string = ''

  protected get fuelLevel(): string {
    const l = this.level
    this.$set(this.barStyle, 'width', `${this.level}%`)
    if (l > 65) {
      return 'high'
    } else if (l > 25) {
      return 'medium'
    } else {
      return 'low'
    }
  }

  protected get simpleText() {
    const type = this.type ? this.$isAvailable(`vehicle.fuel_types.${this.type}`) || this.type : ''
    const range = !!this.range ? ' ~' + this.$distance(this.range) : ''
    return `${this.level}% ${type}${range}`
  }

  protected get iconName(): string {
    if (this.oldBat) {
      return `img/icons/fuel/_battery_${this.fuelLevel}.svg`
    }
    return `img/icons/fuel/${this.symbolType}_${this.fuelLevel}.svg`
  }

  protected mounted() {
    if (this.vehicleType) {
      const sm = new ServiceMesh()
      const color = ServiceMesh.colors[this.vehicleType]

      this.color = color
      this.colorIsLight = VuetifyColorHelper.lightContrast(color)
      this.colorIsDark = !this.colorIsLight // fastest way to avoid !null as a :dark value
      this.barText = this.$t(sm.vehicleType[this.vehicleType])
      this.barStyle = {
        backgroundColor: color,
        width: `${this.level}%`,
      }
      this.symbolType = this.type === 'ELECTRIC' ? 'battery' : 'fuel'
    }
  }
}
