








































import {Component} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import {ReservationFlag} from '@/lib/kepler/interfaces'
import {Action, State} from 'vuex-class'
import DateHelper from '@/lib/DateHelper'
import {AppConfigState} from '@/store/modules/configuration'
import {EventBus} from '@/main'
import Utils from '@/utils'
import BookingDetailMixin from '@/lib/BookingDetailMixin'

@Component({
  components: {
    VehicleActions: Utils.loadComponent('VehicleActions'),
    BookingActions: Utils.loadComponent('BookingActions'),
    Chip: Utils.loadComponent('proxy/Chip'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    Divider: Utils.loadComponent('proxy/Divider'),
    VehicleDetail: Utils.loadComponent('entities/vehicle/VehicleDetail'),
    Accordion: Utils.loadComponent('proxy/Accordion/Accordion'),
    AccordionContent: Utils.loadComponent('proxy/Accordion/AccordionContent'),
    Container: Utils.loadComponent('proxy/Container'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Layout: Utils.loadComponent('proxy/Layout'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    ReservationVehicleAndDates: Utils.loadComponent('entities/reservation/ReservationVehicleAndDates'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'BookingSummary',
  mixins: [BookingDetailMixin],
})
export default class BookingSummary extends mixins<BookingDetailMixin>(BookingDetailMixin) {
  @State('configuration') public configState!: AppConfigState
  @State((state) => state.configuration.appConfig?.fields_configuration) public fieldConfigState!: any

  @Action('setActionButtons') public setActionButtons: any
  @Action('unsetSelectReservation') public unsetSelectReservation: any
  @Action('getReservation') public getReservation: any
  @Action('globalNotes') public globalNotes: any
  @Action('bookingFlushFlags') public bookingFlushFlags: any

  public topUp: boolean | null = null

  public notes: any[] = []

  public get totalTripsDistance(): string {
    const trips = this.reservation.trips
    let distance: number = 0

    if (trips && trips.length > 0) {
      trips.forEach((trip) => {
        distance += trip.distance
      })
    }
    return distance.toFixed(2)
  }

  public get bookingFlags() {
    let p: boolean = false
    this.booking.bookingFlags.forEach((flag: ReservationFlag) => {
      if (flag.reservation_number === this.reservation.number) {
        const now = DateHelper.parse(DateHelper.now('YYYY-MM-DD HH:mm'))
        const value = DateHelper.parse(flag.value)
        p = value.diff(now, 'minutes') > 0
      }
    })
    return p
  }

  public mounted() {
    if (!this.reservation) {
      this.$router.push('/')
    } else {
      this.$route.meta.topbar.title = this.$t('booking.reservation', {number: this.reservation.number})
      this.globalNotes().then((r: any) => {
        this.notes = r.data
      })

      this.setElapsed()
      EventBus.$on('cron1', () => {
        this.setElapsed()
      })
      if (!this.bookingFlags) {
        this.bookingFlushFlags()
      }
    }
  }

  public setElapsed() {
    this.elapsed = DateHelper.getTimeOffset(this.reservation.start_timestamp, new Date()).formatTimeOffset('dhm')
  }

  public destroyed() {
    EventBus.$off('cron1')
  }

  public format(date: any, format: any) {
    return DateHelper.formatDate(date, format)
  }
}
