import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import History from './views/History.vue'
import Dev from './views/Dev.vue'
import Profile from './views/Profile.vue'
import FlowQuickRegistration from './views/Flows/FlowQuickRegistration.vue'
import BookingDetail from './views/Booking/BookingDetail.vue'
import VehicleView from './views/Vehicle/Vehicle.vue'
import Wallet from './views/Wallet.vue'
import Drivers from './views/Drivers.vue'
import ProfileManager from './views/ProfileManager.vue'
import ViewRouter from './components/ViewRouter.vue'
import MySubscriptions from './views/MySubscriptions.vue'
import SubscriptionDrivers from './views/SubscriptionDrivers.vue'
import Activities from './views/Activities.vue'
import PlanDetail from './views/PlanDetail.vue'
import Reportings from './views/Reportings.vue'
import Contracts from '@/views/Contracts.vue'
import AddDocumentView from '@/components/registration/RegistrationDrivingLicenseStep.vue'
import Update from '@/views/Update.vue'
import Documents from '@/components/profile/Documents.vue'
import PaymentMethods from '@/components/wallet/PaymentMethods.vue'
import Settings from '@/views/Settings.vue'
import Logout from '@/views/Logout.vue'
import Plans from '@/components/subscriptions/Plans.vue'
import BookingActivities from '@/views/BookingActivities.vue'
import Login from '@/views/Login.vue'
import HomeMode from '@/views/HomeMode.vue'
import Maintenance from '@/views/Maintenance.vue'
import FlowBooking from '@/views/Flows/FlowBooking.vue'
import Invoices from '@/components/wallet/Invoices.vue'
import Deposits from '@/components/wallet/Deposits.vue'

Vue.use(Router)

export default new Router({
  // https://stackoverflow.com/questions/40899826/vue-v2-vue-router-and-cordova
  // mode: 'history',
  routes: [
    {
      path: '/',
      component: Home,
      children: [
        {
          path: '/',
          redirect: {name: 'home'},
        },
        {
          path: '/home',
          name: 'home',
          component: HomeMode,
          meta: {
            topbar: {
              title: 'appName',
            },
          },
        },
      ],
    },
    {
      path: '/update',
      name: 'update',
      component: Update,
    },
    {
      path: '/dev',
      name: 'dev',
      component: Dev,
      meta: {
        topbar: {
          title: 'kitchen sink',
        },
      },
    },
    {
      path: '/activities',
      component: Home,
      children: [
        {
          name: 'activities',
          path: '/',
          component: Activities,
          meta: {
            topbar: {
              title: 'activities.title',
            },
          },
          children: [
            {
              name: 'book',
              path: 'booking',
              component: BookingActivities,
              children: [
                {
                  path: '/activities/booking/flow',
                  redirect: {name: 'booking-flow'},
                },
                {
                  name: 'bookingType',
                  path: ':type',
                  children: [
                    {
                      name: 'bookingMode',
                      path: ':mode',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/history',
      component: Home,
      children: [
        {
          name: 'history',
          path: '/',
          component: History,
          meta: {
            topbar: {
              title: 'history.title',
            },
          },
        },
      ],
    },
    {
      path: '/profile',
      component: Home,
      children: [
        {
          name: 'profile',
          path: '/',
          component: Profile,
          meta: {
            topbar: {
              title: 'profile.title',
            },
          },
        },
        {
          path: 'switch',
          name: 'profile-switch',
          component: Profile,
          meta: {
            topbar: {
              title: 'profile.switch.title',
            },
          },
        },
        {
          path: 'add-document',
          name: 'add new document',
          component: AddDocumentView,
        },
        {
          path: 'my-documents',
          name: 'My documents',
          component: Documents,
        },
        {
          path: 'wallet',
          component: Home,
          children: [
            {
              path: '/',
              component: Wallet,
              name: 'manage-wallet',
              meta: {
                topbar: {
                  title: 'profile.wallet.my_wallet',
                },
              },
            },
            {
              path: 'payment-methods',
              name: 'payment methods',
              component: PaymentMethods,
              meta: {
                topbar: {
                  title: 'menu.account.payment_methods',
                },
              },
            },
            {
              path: 'invoices',
              name: 'invoices',
              component: Invoices,
              meta: {
                topbar: {
                  title: 'profile.wallet.tabs.invoices',
                },
              },
            }, {
              path: 'deposits',
              name: 'deposits',
              component: Deposits,
              meta: {
                topbar: {
                  title: 'profile.wallet.tabs.deposits',
                },
              },
            },
          ],
        },
        {
          path: 'manage',
          name: 'manage profile',
          component: ProfileManager,
          meta: {
            topbar: {
              title: 'profile.manage',
            },
          },
        },
        {
          name: 'my subscriptions',
          path: 'subscriptions',
          component: MySubscriptions,
          meta: {
            topbar: {
              title: 'profile.subscriptions.title',
            },
          },
          children: [
            {
              path: ':id',
              name: 'subscription detail',
              component: SubscriptionDrivers,
              meta: {
                topbar: {
                  title: 'subscription.add_drivers',
                },
              },
            },
          ],
        },
        {
          name: 'drivers',
          path: 'drivers',
          component: Drivers,
          meta: {
            topbar: {
              title: 'My Drivers',
            },
          },
        },
        {
          name: 'reportings',
          path: 'reportings',
          component: Reportings,
          meta: {
            topbar: {
              title: 'profile.reportings.title',
            },
          },
        },
        {
          name: 'settings',
          path: 'settings',
          component: Settings,
          meta: {
            topbar: {
              title: 'profile.settings.title',
            },
          },
        },
        {
          name: 'logout',
          path: 'logout',
          component: Logout,
          meta: {
            topbar: {
              title: 'profile.logout.title',
            },
          },
        },
      ],
    },
    {
      path: '/plans/',
      name: 'plans',
      component: Plans,
      children: [
        {
          path: ':id',
          name: 'planDetail',
          component: PlanDetail,
          meta: {
            topbar: {
              title: '',
            },
          },
        },
      ],
      meta: {
        topbar: {
          title: 'activities.plans.title',
        },
      },
    },
    {
      path: '/reservation',
      component: ViewRouter,
      children: [
        {
          path: '/',
          name: 'reservation',
          component: BookingDetail,
          meta: {
            topbar: {
              title: 'booking.reservation',
            },
          },
        },
      ],
    },
    {
      path: '/vehicle',
      component: ViewRouter,
      children: [
        {
          path: '/',
          name: 'vehicle',
          component: VehicleView,
          meta: {
            topbar: {
              title: 'vehicle.vehicle_detail',
            },
          },
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        topbar: {
          title: 'login.login',
        },
      },
    },
    {
      path: '/registration',
      name: 'registration',
      component: FlowQuickRegistration,
      meta: {
        topbar: {
          hidden: true,
        },
      },
    },
    {
      path: '/rent',
      name: 'rent',
      alias: '/activities/booking/flow',
      component: FlowBooking,
      meta: {
        topbar: {
          hidden: true,
        },
      },
    },
    {
      path: '/terms',
      name: 'terms',
      component: Contracts,
      meta: {
        topbar: {
          title: 'profile.terms.title',
        },
      },
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: Maintenance,
      meta: {
        topbar: {
          title: 'maintenance.title',
        },
      },
    },
  ],
  scrollBehavior() {
    document.querySelector('div.view-home')?.parentElement?.scroll(0, 0)
    return {x: 0, y: 0}
  },
})
