













import {Component} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'

import {Action, State} from 'vuex-class'
import TabHistoryMixin from '@/lib/TabHistory'
import Utils from '@/utils'
import {ReservationResponse} from '@/lib/kepler/interfaces'

@Component({
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    Tabs: Utils.loadComponent('proxy/Tabs/Tabs'),
    Tab: Utils.loadComponent('proxy/Tabs/Tab'),
    TabItems: Utils.loadComponent('proxy/Tabs/TabItems'),
    TabItem: Utils.loadComponent('proxy/Tabs/TabItem'),
    BookingSummary: Utils.loadView('Booking/BookingSummary'),
    VehicleStatus: Utils.loadComponent('entities/vehicle/VehicleStatus'),
  },
  name: 'BookingDetail',
  mixins: [TabHistoryMixin],
})
export default class BookingDetail extends mixins<TabHistoryMixin>(TabHistoryMixin) {
  @State((state) => state.booking.activeReservations) public activeResState!: ReservationResponse[]
  @State((state) => state.booking.selectedReservation?.id) public selectedReservationId?: string
  @Action('selectReservation') public selectReservation!: (r: ReservationResponse) => Promise<ReservationResponse>

  public currentTab: number = 0
  public vehicleCategory: string | null = null
  public reservationColor: string | null = null
  public tabMap: { [key: string]: number } = {
    '#summary': 0,
    '#reports': 1,
  }

  public get activeReservation() {
    const selectedId = this.selectedReservationId
    const selectedRes = selectedId ? this.activeResState.find((r) => r.id === selectedId) : null

    if (selectedRes) {
      return selectedRes
    }

    const onlyRes = this.activeResState.length === 1 ? this.activeResState[0] : null

    if (onlyRes) {
      this.selectReservation(onlyRes)
      return onlyRes
    }

    this.$router.replace({name: 'home'})
  }

  public stuffUpdate(res: ReservationResponse) {
    Utils.setProp(this.$route.meta, ['topbar', 'title'], this.$t('booking.reservation', {number: res.number}))
    const vs = res.vehicle_slot
    this.vehicleCategory = vs.vehicle.category.type.toLowerCase()
    this.reservationColor = (vs.reservation_type + this.vehicleCategory).toUpperCase()
  }

  public created() {
    if (this.activeReservation) {
      this.stuffUpdate(this.activeReservation)
    }
  }
}
