













































































import {Vue, Component} from 'vue-property-decorator'
import {State, Action} from 'vuex-class'
import {LoginRequest, Popup, Dialog} from '@/lib/kepler/interfaces'
import {AppConfigState} from '@/store/modules/configuration'
import Utils from '@/utils'

import ResetPasswordView from '@/views/ResetPassword.vue'
import LoginByPhone from '@/views/LoginByPhone.vue'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import {VSlideYTransition} from 'vuetify/lib'

@Component({
  components: {
    Sheet: Utils.loadComponent('proxy/Sheet'),
    LoopingBG: Utils.loadComponent('LoopingBG'),
    Img: Utils.loadComponent('proxy/Image'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    Button: Utils.loadComponent('Button'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    CloseButton: Utils.loadComponent('CloseButton'),
    VSlideYTransition,
  },
  name: 'Login',
})
export default class Login extends Vue {
  @State('configuration') public configuration!: AppConfigState
  @Action('loadConfig') public loadConfig: any

  @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void

  @Action('closePopup') public closePopup!: (index?: number) => void

  @Action('login') public login: any

  protected showPassword: boolean = false
  protected credentials: LoginRequest = {username: '', password: ''}
  protected loading: boolean = false
  protected valid: boolean = false

  protected usernameRules: any = [
    (v: any) => !!v || 'Name is required',
  ]

  protected passwordRules: any = [
    (v: any) => !!v || 'Password is required',
  ]

  protected get smsLoginEnabled() {
    if (!this.configuration.appConfig) {
      return true
    }
    const l = this.configuration.appConfig.sms_login_enabled
    if (!this.configuration.appConfig || typeof l !== 'boolean') {
      this.loadConfig.then(() => {
        return this.configuration.appConfig.sms_login_enabled
      })
    } else {
      return l
    }
  }

  protected get registrationMode() {
    return this.configuration.appConfig?.registration_mode || 'enabled'
  }

  protected get registrationStrings() {
    const inv = this.registrationMode === 'invitation'
    return {
      // low effort copy? lower effort code!
      prompt: this.$t(inv ? 'login.register_prompt' : 'login.register_prompt'),
      action: this.$t(inv ? 'login.create_one' : 'login.create_one'),
    }
  }

  protected get hackerMode() { // 🕶 //
    const hash = '50457b728b94662722782bc66503205a'
    return this.credentials?.username === hash && this.credentials?.password === hash
  }

  protected loginRequest() {
    if ((this.$refs as any).loginForm.validate()) {
      this.loading = true
      this.login(this.credentials).then(() => {
        this.closePopup()
        if (this.$route.name !== 'home') {
          this.$router.push({name: 'home'})
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }

  protected openRegistration() {
    this.closePopup()
    this.$router.push({name: 'registration'})
  }

  protected openResetPasswordView() {
    this.openPopup(new Popup(ResetPasswordView, null, this.$t('login.reset_your_password'), false))
  }

  protected loginByPhone() {
    this.openPopup(new Popup(LoginByPhone, null, this.$t('login.login_by_phone'), false))
  }

  protected ee() {
    this.openDialog(new Dialog(ConfirmDialogCallback, {
      imageState: null,
      code: null,
      title: null,
      subtitle: 'using md5 and tapping on random red buttons doesn\'t make you a hacker',
      cancelText: 'oh no',
      confirmText: 'ok',
      emitConfirm: false,
      showCloseButton: false,
      confirmColor: 'error',
      confirmCallback: () => {
        this.$router.push('/dev')
      },
    }))
  }
}
