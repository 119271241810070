





















































































































import {Vue, Component} from 'vue-property-decorator'
import {State, Action, Getter} from 'vuex-class'

import {ProfileState} from '@/store/modules/profile'
import Locate, {Position} from '@/lib/location'
import {City, PoiData, Popup, Pos, ReservationResponse, VehicleSlot} from '@/lib/kepler/interfaces'
import ServiceMesh from '@/lib/serviceMesh'
import Utils from '@/utils'

import MapStyles from '@/lib/map/styles/MapStyles'
import {Filter, MapFiltersState, SearchParameters} from '@/store/modules/map'
import CenterPointIconSVG from '../../public/img/map/markers/map_position_icon.svg?inline'
import NMap from '@/lib/n-maps/src/NMap.vue'
import {EventBus} from '@/main'
import Loader from '@/components/Loader.vue'
import Sheet from '@/components/proxy/Sheet.vue'

interface MarkerLabel {
  color?: string
  fontFamily?: string
  fontSize?: string
  fontWeight?: string
  text: string
  origin?: [number, number]
}

interface ItemPosition {
  lat: number
  lng: number
}

interface Item {
  item: ReservationResponse
  position: ItemPosition
}

interface CityLoader {
  in: City | null,
  out: City | null,
  active: boolean
}

@Component({
  components: {
    Sheet,
    Loader,
    CenterPoint: Utils.loadComponent('CenterPoint'),
    CenterMapButton: Utils.loadView('Map/CenterMapButton'),
    CarouselContainer: Utils.loadView('Map/CarouselContainer'),
    FiltersContainer: Utils.loadView('Map/FiltersContainer'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    VehicleCardPopup: Utils.loadComponent('entities/vehicle/VehicleCardPopup'),
    CircleToggle: Utils.loadComponent('CircleToggle'),
    TopBar: Utils.loadComponent('TopBar'),
    Button: Utils.loadComponent('Button'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Alert: Utils.loadComponent('proxy/Alert'),
    ParkingCardPopup: Utils.loadComponent('entities/vehicle/ParkingCardPopup'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    Layout: Utils.loadComponent('proxy/Layout'),
  },
})
export default class Map extends Vue {
  // Store mappings
  @State('profile') public profileState!: ProfileState
  @State((state) => state.configuration.appConfig?.default_latitude) public default_latitude?: number
  @State((state) => state.configuration.appConfig?.default_longitude) public default_longitude?: number
  @State('popupState') public popupState!: Popup[]
  @State('filters') public filters!: MapFiltersState

  @Getter('popupIsOpen') public popupIsOpen!: boolean
  @Getter('debugMode') public debugMode!: boolean
  @Getter('cities') public cities!: City[] | null
  @Getter('city') public city!: City | null

  @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Action('closePopup') public closePopup!: (index?: number) => void

  @Action('setUserPosition') public setUserPosition!: (p: Position) => void
  @Action('setCity') public setCity!: (c?: City) => void
  @Action('_parameter') public updateParameter!: (p: SearchParameters) => void

  public carouselType: string = 'hidden'
  public carouselElements: VehicleSlot[] = []

  public gpsError: boolean = false
  public mapActive = false
  public centerPoint: Position = {
    lat: 0,
    lng: 0,
    acc: null,
    hdg: null,
  }
  public centerPointIcon = CenterPointIconSVG
  public loading: boolean = false
  public firstLoad: boolean = true
  public locationWatcher: number | null = null
  public headingWatcher: number | null = null
  public cityLoader: CityLoader = {in: null, out: null, active: false}

  public get mapStyle() {
    return MapStyles.getStyle(this.profileState.mapStyle ? this.profileState.mapStyle : 'default')
  }

  protected get mapOn() {
    return !this.popupState.length && this.mapActive
  }

  protected get filteredCities() {
    const cities = this.cities
    const city = this.city
    if (cities) {
      if (city && !cities.find((c) => c.id === city.id)) {
        this.setCity()
      }
      return cities.filter((c) => c.id !== city?.id)
    }
    return null
  }

  protected get layerMarkers() {
    return this.getLayer(this.filters.vehicles)
  }

  protected get parkingMarkers() {
    return this.getLayer(this.filters.park)
  }

  protected get zoneLayers() {
    return this.getLayer(this.filters.zones)
  }

  protected get clusteredPOI(): Filter<PoiData> {
    const p = this.filters.poi
    const obj: typeof p = {}
    for (const key in p) {
      if (p.hasOwnProperty(key) && p[key].active) {
        if (!(p[key] as any).visibility || (p[key] as any).visibility === 'normal') {
          obj[key] = p[key]
        }
      }
    }
    return obj
  }

  protected get unclusteredPOI() {
    const p = this.filters.poi
    const obj: typeof p = {}
    for (const key in p) {
      if (p.hasOwnProperty(key) && p[key].active) {
        if ((p[key] as any).visibility === 'important') {
          obj[key] = p[key]
        }
      }
    }
    return obj
  }

  protected get hasClusterable() {
    const layers = this.layerMarkers.length > 0
    const park = this.parkingMarkers.length > 0
    const poi = Object.keys(this.clusteredPOI).length
    return this.mapOn && (layers || park || poi)
  }

  protected get mapRef() {
    return this.$refs.map as NMap | undefined
  }

  protected getPolyLineColor(zone: string) {
    return ServiceMesh.getPolyLineColor(zone)
  }

  protected getLayer(f: Filter<any>) {
    let arr: any[] = []
    if (f && Object.keys(f).length) {
      for (const fKey in f) {
        if (f.hasOwnProperty(fKey)) {
          const f1 = f[fKey]
          if (f1.active) {
            arr = [...arr, ...f1.data]
          }
        }
      }
    }
    return arr
  }

  protected centerMap(pos?: Pos) {
    if (pos) {
      this.mapRef?.pan(pos)
    } else if (this.profileState.userPosition) {
      this.mapRef?.pan(this.profileState.userPosition)
    }
    EventBus.$emit('recenter')
    this.loading = true
  }

  protected setZoom(level: number) {
    this.mapRef?.zoom(level)
  }

  protected setCenterPoint(p: Partial<Position>) {
    const e = Object.entries(p)
    for (const [k, v] of e) {
      if (typeof v === 'number') {
        this.$set(this.centerPoint, k, v)
      }
    }
  }

  protected cloneObj(o: Record<any, any>) {
    return JSON.parse(JSON.stringify(o))
  }

  protected pleaseKeepLocateCallback(pos: Position | null = null) {
    const defaultCenterPoint: Position = {
      lat: this.default_latitude || 0,
      lng: this.default_longitude || 0,
      acc: null,
    }

    if (pos) {
      if (this.headingWatcher) {
        delete pos.hdg
      }
      this.setCenterPoint(pos)
      this.setUserPosition(pos)
      this.gpsError = false
    } else {
      this.setCenterPoint(defaultCenterPoint)
      this.setUserPosition(defaultCenterPoint)
      this.gpsError = true
    }
    this.loading = false
    if (this.firstLoad) {
      this.centerMap()
      this.firstLoad = false
    }
  }

  protected setupMap() {
    this.loading = true
    if (!this.locationWatcher) {
      return Locate.please_keep_locate(this.pleaseKeepLocateCallback)
    } else {
      this.loading = false
      this.centerMap()
      return this.locationWatcher
    }
  }

  protected setupHeading() {
    if (!this.headingWatcher) {
      if (device.platform === 'browser' && !this.debugMode) {
        return null
      } else {
        return Locate.getCompassHeading(((pos) => {
          if (pos?.hdg) {
            this.setCenterPoint({hdg: pos.hdg})
          }
        }))
      }
    } else {
      return this.headingWatcher
    }
  }

  protected parkingMarkerLabel(m: any): { label: MarkerLabel } {
    let available = m.parking_slots_availability.available
    available = available || ''
    const color = this.$vuetify.theme.PARKING ? this.$vuetify.theme.PARKING.toString() : ''
    return available ? {
      label: {
        text: available.toString(),
        fontSize: '10px',
        fontWeight: '600',
        color,
        origin: [25, 25],
      },
    } : {
      label: {
        text: ' ',
        origin: [15, 15],
      },
    }
  }

  protected selectCity(city?: City) {
    if (!city) {
      this.setCity()
      this.setupMap()
      return
    }

    this.cityLoader = {
      in: this.city,
      out: city,
      active: true,
    }

    EventBus.$once('recentered', () => {
      setTimeout(() => {
        this.cityLoader.active = false
      }, 500)
    })

    this.setCity(city)
    this.updateParameter({city_id: city.id})

    this.centerMap(city.position)
  }

  protected created() {
    EventBus.$on('recentered', () => {
      this.loading = false
    })
  }

  // Lifecycle hooks
  protected mounted() {
    this.setCenterPoint({
      lat: this.default_latitude || 0,
      lng: this.default_longitude || 0,
    })
    this.$nextTick(() => {
      this.locationWatcher = this.setupMap()
      this.headingWatcher = this.setupHeading()
      this.setZoom(15) // harcodey no likey but no really give a fuckey
    })
  }

  protected activated() {
    EventBus.$on('selected_city', () => {
      this.locationWatcher = this.setupMap()
    })

    this.$nextTick(() => {
      this.locationWatcher = this.setupMap()
      this.headingWatcher = this.setupHeading()
    })

    this.mapActive = true // TODO: figure out why these mofos are not activating at first load
    this.mapRef?.refresh()
  }

  protected deactivated() {
    EventBus.$off('selected_city')

    if (this.locationWatcher) {
      Locate.please_stop_locate(this.locationWatcher)
      this.locationWatcher = null
    }
    if (this.headingWatcher) {
      Locate.stopCompassWatcher(this.headingWatcher)
      this.headingWatcher = null
    }
    this.mapActive = false
  }

  protected keyBy(lat?: string | number, lng?: string | number, id?: string | number) {
    lat = lat?.toString().slice(0, 8) || ''
    lng = lng?.toString().slice(0, 8) || ''
    id = id ? id.toString() : ''
    return (id + lat + lng).replace(/[.]/g, '').padEnd(22, 'X')
  }

  protected selectMarker(type: string, m: any[], pos: Position) {
    this.carouselType = type
    this.carouselElements = m
    this.$nextTick(() => {
      this.mapRef?.pan(pos)
    })
  }
}
