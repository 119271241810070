






























import {Component, Prop, Vue} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import Icon from './proxy/Icon.vue'
import Button from './Button.vue'
import Scanner from '../qrScanner'
import {EventBus} from '@/main'
import Img from '@/components/proxy/Image.vue'
import CloseButton from '@/components/CloseButton.vue'
import Container from '@/components/proxy/Container.vue'
import Layout from '@/components/proxy/Layout.vue'

interface ElementList {
  [element: string]: {
    el: Element | null,
    style: string,
    class: string,
  }
}

@Component({
  components: {
    Layout,
    Container,
    CloseButton,
    Img,
    Button,
    Icon,
  },
})
export default class QrScanner extends Vue {
  @Action('closePopup') public closePopup!: (index?: number) => void
  @Prop() public qrImage!: string
  protected flashlightOn: boolean = false
  protected scanner?: Scanner

  protected elList = [
    {
      name: 'homeview',
      query: '#view-home',
      classOverride: ['d-none'],
    },
    {
      name: 'html',
      query: 'html',
      classOverride: ['no-bg'],
    },
    {
      name: 'body',
      query: 'body',
      classOverride: ['no-bg'],
    },
    {
      name: 'app',
      query: '#app',
      classOverride: ['no-bg'],
    },
    {
      name: 'topbar',
      query: '#topbar',
      classOverride: ['d-none'],
    },
    {
      name: 'modalWrapper',
      query: '#modal-wrapper',
      classOverride: ['no-bg', 'no-filter'],
    },
    {
      name: 'main',
      query: 'main.v-content',
      styleOverride: 'opacity: 0;',
      classOverride: ['no-bg', 'no-filter'],
    },
    {
      name: 'popup',
      query: '.popup',
      classOverride: ['no-bg', 'no-filter'],
    },
  ]
  protected elements: ElementList = {}

  public scan(err: any, val: string) {
    if (err) {
      return err
    } else {
      if (val.includes('=')) {
        val = val.split('=').reverse()[0]
      }
      EventBus.$emit('qrScanned', val)
      this.close()
    }
  }

  public toggleFlash() {
    if (this.scanner) {
      if (this.flashlightOn) {
        this.flashlightOn = false
        this.scanner.disableLight()
      } else {
        this.scanner.enableLight()
        this.flashlightOn = true
      }
    }
  }

  public applyStyle(name: string, value: string) {
    const el = this.elements?.[name].el || null
    if (el) {
      el.setAttribute('style', value)
    }
  }

  public applyClass(name: string, value: string[]) {
    const el = this.elements?.[name].el || null
    if (el) {
      const ogStyles = el.classList.value.split(' ')
      ogStyles.push(...value)
      el.setAttribute('class', ogStyles.join(' ').trim())
    }
  }

  public revertStyle(name: string) {
    const item = this.elements?.[name] || null
    if (item) {
      item.el?.setAttribute('style', item.style)
    }
  }

  public revertClass(name: string) {
    const item = this.elements?.[name] || null
    if (item) {
      item.el?.setAttribute('class', item.class)
    }
  }

  protected hideStuff() {
    this.elList.forEach((elListItem) => {
      const el = document.querySelector(elListItem.query)

      if (!this.elements[elListItem.name]) {
        Vue.set(this.elements, elListItem.name, {
          el,
          style: el?.getAttribute('style') || '',
          class: el?.getAttribute('class') || '',
        })
      }
    })

    this.elList.forEach((item) => {
      if (item.styleOverride) {
        this.applyStyle(item.name, item.styleOverride)
      }
      if (item.classOverride) {
        this.applyClass(item.name, item.classOverride)
      }
    })
  }

  protected showStuff() {
    for (const key in this.elements) {
      if (this.elements.hasOwnProperty(key)) {
        this.revertStyle(key)
        this.revertClass(key)
      }
    }
  }

  protected created() {
    EventBus.$emit('openQr')
  }

  protected mounted() {
    this.hideStuff()
    if (!(window as any).QRScanner) {
      return
    }
    this.scanner = new Scanner()
    if (this.scanner) {
      this.scanner.scan(this.scan)
      this.scanner.show()
    }
  }

  protected beforeDestroy() {
    if (this.scanner) {
      this.scanner.destroy()
    }
  }

  protected close() {
    this.showStuff()
    EventBus.$emit('closeQr')
    this.closePopup()
  }
}
