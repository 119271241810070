import _Vue from 'vue'
import store from '@/store/index'

export enum LogLevels {'debug', 'info', 'warn', 'error'}

export enum LogLevelColors {'#9e9e9e', '#2196f3', '#ffc107', '#f44336'}

export enum LogColorByLevel {
  'debug' = '#9e9e9e',
  'info' = '#2196f3',
  'warn' = '#ffc107',
  'error' = '#f44336'
}

const getMethodName = (): string => {
  let error: Error
  try {
    // noinspection ExceptionCaughtLocallyJS
    throw new Error('')
  } catch (e) {
    if (e instanceof Error) {
      error = e
      // IE9 does not have .stack property
      if (error.stack === undefined) {
        return ''
      }
      let stackTrace = error.stack.split('\n')[3]
      if (/ /.test(stackTrace)) {
        stackTrace = stackTrace.trim().split(' ')[1]
      }
      if (stackTrace && stackTrace.indexOf('.') > -1) {
        stackTrace = stackTrace.split('.')[1]
      }
      return stackTrace
    }
    return ''
  }
}

const channels: { [channel: string]: (v: any, level: LogLevels, method?: string) => void } = {
  'console': (v, level, method = '') => {
    if (v === 'playmooooooooooooooooove') {
      console.log('%c ', 'padding:28px 119px;line-height:100px;background:url(https://storage.colossus.playmoove.com/cdn/_playmoove/logo.svg) no-repeat;')
      return
    }
    const prefix = `${LogLevels[level].padEnd(5, ' ')} | ${method} | `

    if (LogLevelColors[level]) {
      v = ['%c' + prefix + '%s', 'color:' + LogLevelColors[level], v]
    } else {
      v = [prefix, v]
    }

    // noinspection IfStatementWithTooManyBranchesJS
    if (level === LogLevels.debug) {
      console.log(...v)
    } else if (level === LogLevels.info) {
      console.info(...v)
    } else if (level === LogLevels.warn) {
      console.warn(...v)
    } else if (level === LogLevels.error) {
      console.error(...v)
    }
  },
  store: (v, level, method = '') => {
    if (store.getters.debugMode || level > 1) { // only warn, error and fatal if not debugging
      const prefix = `${LogLevels[level].padEnd(5, ' ')} | ${method} | `
      store.dispatch('log', `${Date.now()}>>${prefix}${JSON.stringify(v)}`)
    }
  },
  // sentry: (v, level, method = '') => {},
}

export const log = (val: any, level: LogLevels = 0) => {
  const method = getMethodName() // doesnt bork in firefox
  Object.values(channels).forEach((f) => {
    f(val, level, method)
  })
}

export type log = typeof log

export default {
  install(Vue: typeof _Vue): void {
    Vue.prototype.$log = log
  },
}
