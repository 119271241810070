







































import {Vue, Component, Prop} from 'vue-property-decorator'
import {ReservationResponse, Dialog} from '@/lib/kepler/interfaces'
import {Action, State} from 'vuex-class'
import {EventBus} from '@/main'
import Utils from '@/utils'
import {DialogState} from '@/store/modules/dialogs'
import sdk from '@/lib/kepler/sdk'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'

@Component({
  components: {
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'PinRequestCallback',
})
export default class PinRequestCallback extends Vue {
  @State(
    (state) => !!state.configuration.appConfig?.fields_configuration?.recover_pin_by_sms_enabled,
  ) public canRecoverPinbySMS?: boolean
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('closeDialog') public closeDialog!: (index?: number) => void
  @State('dialogState') protected dialogState!: typeof DialogState
  @Prop() protected reservation?: ReservationResponse
  @Prop({default: true}) protected showCloseButton!: boolean
  @Prop({
    type: Number,
    default: 4,
  }) protected maxLength!: number
  @Prop({type: Function}) protected confirmCallback!: (pin: string) => void
  @Prop({type: Function}) protected cancelCallback!: () => void
  @Prop({type: String}) protected message?: string
  @Prop({type: Boolean, default: true}) protected closeAfterPinSent!: boolean

  protected loading: boolean = false
  protected disabled: boolean = false
  protected visibility: boolean = false
  protected confirmed: boolean = false
  protected recoverPinDialog: boolean = false
  protected digits: string = ''
  protected error: string = ''

  protected get display() {
    const arr = new Array(this.maxLength).fill('_')

    if (this.digits) {
      const d = this.digits
      const vis = this.visibility
      return arr.map((v, i) => {
        const char = d.charAt(i)
        if (char) {
          return vis ? d.charAt(i) : '*'
        } else {
          return v
        }
      }).join('')
    }
    return arr.join('')
  }

  protected mounted() {
    EventBus.$on('pinRequestError', this.onError)
    EventBus.$on('pinRequestSuccess', this.onSuccess)
    this.$smoothReflow?.({
      property: ['height'],
      el: '.confirm-dialog-box',
      hideOverflow: true,
    })
  }

  protected destroyed() {
    EventBus.$off('pinRequestError', this.onError)
    EventBus.$off('pinRequestSuccess', this.onSuccess)
  }

  protected sendPinRequest() {
    if (this.confirmCallback) {
      this.confirmCallback(this.digits)
      if (this.closeAfterPinSent) {
        this.closeDialog()
      }
    }
  }

  protected onSuccess() {
    this.loading = false
    this.confirmed = true
    this.$nextTick(() => {
      const check = (this.$refs.pinRequestSuccess as Vue)
      if (check && check.$el) {
        check.$el.classList.remove('v-ripple__animation')
      }
      setTimeout(() => {
        // to close safely, set popupTitle to 'pinDialog'
        const i = this.dialogState.findIndex((dialog: Dialog) => {
          return dialog.popupTitle === 'pinDialog'
        })
        i >= 0 ? this.closeDialog(i) : this.closeDialog()
      }, 1000)
    })
  }

  protected onError(r: string) {
    this.loading = false
    this.confirmed = false
    this.error = r || ''
    this.digits = ''
  }

  protected addDigit(val: string) {
    this.error = ''
    if (this.digits.length < this.maxLength) {
      this.digits += val
    }
  }

  protected removeDigit() {
    this.digits = this.digits.slice(0, -1)
  }

  protected close() {
    if (this.cancelCallback) {
      this.cancelCallback()
    }
  }

  protected recoverPin() {
    if (this.canRecoverPinbySMS) {
      this.loading = true
      sdk.profile.recover_pin().then(() => {
        this.openDialog(new Dialog(ConfirmDialogCallback, {
          title: this.$t('booking.pin_reminder.sent_title'),
          subtitle: this.$t('booking.pin_reminder.sent'),
          singleAction: true,
          showCloseButton: false,
        }))
      }).finally(() => {
        this.loading = false
        this.recoverPinDialog = false
      })
    }
  }

  protected emailDialog() {
    this.openDialog(new Dialog(ConfirmDialogCallback, {
      title: this.$t('booking.pin_reminder.email_title'),
      subtitle: this.$t('booking.pin_reminder.email_instructions'),
      singleAction: true,
      showCloseButton: false,
    }))
  }
}
