









import {Component} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import Utils from '@/utils'
import {State} from 'vuex-class'
import VehicleAddReport from '@/views/Vehicle/VehicleAddReport.vue'
import FuelPinDialog from '@/views/FuelPinDialog.vue'
import BookingDetailMixin from '@/lib/BookingDetailMixin'
import {Popup, Dialog} from '@/lib/kepler/interfaces'

interface BookingActionButton {
  clickAction: () => void,
  text: string
}

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Button: Utils.loadComponent('Button'),
    Divider: Utils.loadComponent('proxy/Divider'),
  },
  name: 'BookingActions',
})

export default class BookingActions extends mixins<BookingDetailMixin>(BookingDetailMixin) {
  @State((state) => state.configuration.appConfig.parking_report_id) public parkingReportId!: string | null

  protected get bookingActions() {
    return this.reservation.extra?.actions || null
  }

  protected get buttons() {
    const arr: BookingActionButton[] = []
    const list: Record<string, () => void> = {
      extend: this.extendAction,
      edit: this.edit,
      cancel: this.cancelReservation,
      refuel: this.getFuelPin,
      report_unavailable_parking: this.reportParkUnavailable,
    }

    const textString = (action: string): string => {
      const text = this.$isAvailable('booking.action.' + action) || null
      const nestedText = this.$isAvailable('booking.action.' + action + '.action') || null
      return text || nestedText || action
    }

    this.bookingActions?.forEach((operation) => {
      const extraAction = () => this.sendExtra(operation)
      const text = textString(operation)
      const clickAction = !!list[operation] ? list[operation] : extraAction
      arr.push({text, clickAction})
    })
    return arr
  }

  // actions
  protected getFuelPin() {
    let text = this.$t('vehicle.refuel.not_necessary')
    let callback: (() => void) | undefined
    if (this.reservation.vehicle_slot.vehicle.fuel_level < 30) {
      text = this.$t('vehicle.refuel.necessary')
      callback = () => {
        this.closeDialog()
        this.openPopup(new Popup(VehicleAddReport, {
          vehicleSlot: this.reservation.vehicle_slot,
          vehicle: this.reservation.vehicle_slot.vehicle,
        }, this.$t('vehicle.report.diary_report')))
      }
    }

    this.loading = true
    this.$store.dispatch('getFuelPin', this.reservation.number).then(
      (r) => {
        this.openDialog(new Dialog(FuelPinDialog, {
          imageState: 'success.svg',
          confirmText: 'close',
          pin: r.fuel_card_pin,
          odometer: r.vehicle_odometer,
          callback,
          data: text,
          singleAction: true,
          emitConfirm: false,
        }))
      },
    ).finally(() => {
      this.loading = false
    })
  }

  protected reportParkUnavailable() {
    this.openPopup(new Popup(VehicleAddReport, {
      vehicleSlot: this.reservation.vehicle_slot,
      vehicle: this.reservation.vehicle_slot.vehicle,
      reservation: this.reservation,
      reportType: this.parkingReportId,
      description: this.$t('vehicle.report.park_occupied'),
    }, this.$t('vehicle.report.diary_report')))
  }

  protected extendAction() {
    this.loading = true
    this.getAvailability().then(this.openExtend).finally(() => {
      this.loading = false
    })
  }
}
