











import {Vue, Component, VModel} from 'vue-property-decorator'
import {Action, Getter} from 'vuex-class'
import {City} from '@/lib/kepler/interfaces'
import SelectTag from '@/components/proxy/Inputs/SelectTag.vue'

@Component({
  components: {SelectTag},
  name: 'CitySelector',
})

export default class CitySelector extends Vue {
  @VModel() public internalValue?: string

  @Getter('cities') public cities!: City[] | null
  @Getter('city') public getCity!: City | null

  @Action('setCity') public setCity!: (c?: City) => void

  public get city() {
    this.internalValue = this.getCity?.id
    return this.getCity
  }

  public set city(c: City | null) {
    this.setCity(c || undefined)
  }
}
