







import {Component, Prop, Vue} from 'vue-property-decorator'
import {VBtn, VCard, VIcon} from 'vuetify/lib'
import PlanDetail from '@/views/PlanDetail.vue'
import {SubscriptionPlan} from '@/lib/kepler/interfaces'
import {Action} from 'vuex-class'

@Component({
  components: {
    VCard, VBtn, VIcon, PlanDetail,
  },
  name: 'PlanDetailDialog',
})
export default class PlanDetailDialog extends Vue {
  @Action('closeDialog') public closeDialog!: (index?: number) => void

  @Prop({type: Object}) private plan!: SubscriptionPlan
}
