


























import {Vue, Component} from 'vue-property-decorator'
import {
  BookingMode,
  BookRequest,
  ReservationResponse,
  Vehicle,
  VehicleType,
  Popup,
  Dialog,
} from '@/lib/kepler/interfaces'
import {Action} from 'vuex-class'
import {EventBus} from '@/main'
import Utils from '@/utils'

import VehicleBookingAddMemoDialog from '@/views/Vehicle/VehicleBookingAddMemoDialog.vue'
import VehicleConfirmBooking from '@/views/Vehicle/VehicleConfirmBooking.vue'
import QrScanner from '@/components/QrScanner.vue'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'

@Component({
  components: {
    TopBar: Utils.loadComponent('TopBar'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    IsLogged: Utils.loadComponent('IsLogged'),
    Digits: Utils.loadComponent('Digits'),
    Button: Utils.loadComponent('Button'),
    VehicleConfirmImage: Utils.loadComponent('entities/vehicle/VehicleConfirmImage'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    CloseButton: Utils.loadComponent('CloseButton'),
  },
  name: 'VehicleQrUnlock',
})
export default class VehicleQrUnlock extends Vue {
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('closeDialog') public closeDialog!: (index?: number) => void
  @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Action('selectReservation') public selectReservation!: (r: ReservationResponse) => Promise<ReservationResponse>
  @Action('book') public bookAction!: (payload: BookRequest) => Promise<ReservationResponse>
  @Action('track') public track!: () => Promise<void>

  public reservationRequest: BookRequest = {start: null, end: null, plate: null, memo: ''}
  public reservation: ReservationResponse | null = null
  public digitsCompleted: boolean = false
  protected confirmed: boolean = false
  protected digits!: string

  protected get confirmImage() {
    return 'img/booking/bike.svg'
  }

  protected get vehicle() {
    const v = new Vehicle()
    if (this.vehicleType) {
      v.category.type = this.vehicleType
    }
    return v
  }

  protected get vehicleType() {
    const type = this.$route.params.type?.toUpperCase()
    if (type) {
      const vehicleTypeCheck = Object.keys(VehicleType).includes(type)
      return vehicleTypeCheck ? type as VehicleType : null
    }
    return null
  }

  protected get bookingMode() {
    const mode = this.$route.params.mode?.toUpperCase()
    if (mode) {
      const bookingModeCheck = Object.keys(BookingMode).includes(mode)
      return bookingModeCheck ? mode as BookingMode : null
    }
    return null
  }

  protected get color() {
    const bm = this.bookingMode
    const vt = this.vehicleType
    if (bm && vt) {
      return VuetifyColorHelper.color(bm + vt)
    }
    return 'primary'
  }

  protected get hasExtra(): string | false {
    const vt = this.vehicleType
    if (vt) {
      return this.$isAvailable(`booking.enter_license_place_extra.${vt.toLowerCase()}`) || false
    }
    return false
  }

  protected mounted() {
    if (!this.vehicle || !this.vehicleType) {
      this.$router.back()
      return
    }
    EventBus.$on('qrScanned', (e: string) => {
      this.reservationRequest.plate = e
      this.bookAndGo()
    })
  }

  protected destroyed() {
    EventBus.$off('qrScanned')
  }

  protected completed(val: string) {
    this.digitsCompleted = true
    this.reservationRequest.plate = val
  }

  protected bookAndGo() {
    this.book(() => {
      if (this.reservation) {
        this.selectReservation(this.reservation)
        this.$router.push({name: 'reservation'})
      }
    })
  }

  protected book(callback?: () => void) {
    return this.openDialog(new Dialog(VehicleBookingAddMemoDialog, {
      vehicle: this.vehicle,
      bookRequest: this.reservationRequest,
      confirmCallback: () => {
        if (callback !== undefined) {
          this.sendBooking(callback)
        } else {
          this.sendBooking()
        }
      },
    }))
  }

  protected sendBooking(callback?: () => void) {
    this.bookAction(this.reservationRequest)
      .then((r) => {
        this.reservation = r
        this.openPopup(new Popup(
          VehicleConfirmBooking, {
            vehicleSlot: r.vehicle_slot,
            confirmCallback: callback,
          }, null, true))
      })
      .catch(() => {
        return '💩'
      })
  }

  protected openQr() {
    let qrImage

    switch (this.vehicle.category.type.toLowerCase()) {
      case 'car':
        qrImage = 'img/icons/carqr.svg'
        break
      case 'bike':
        qrImage = 'img/icons/bikeqr.svg'
        break
      case 'scooter':
        qrImage = 'img/icons/scooterqr.svg'
        break
      case 'kickscooter':
        qrImage = 'img/icons/kickscooterqr.svg'
        break
    }

    this.openPopup(new Popup(QrScanner, {qrImage}, null, true))
  }
}
