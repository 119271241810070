






























import {Component, Vue} from 'vue-property-decorator'
import {Action} from 'vuex-class'

import Button from '@/components/Button.vue'
import FormTag from '@/components/proxy/Inputs/FormTag.vue'
import TextField from '@/components/proxy/Inputs/TextField.vue'
import {ChangePasswordRequest, Dialog} from '@/lib/kepler/interfaces'
import Validations from '@/lib/Validations'
import CloseButton from '@/components/CloseButton.vue'
import Card from '@/components/proxy/Card/Card.vue'
import Layout from '@/components/proxy/Layout.vue'
import sdk from '@/lib/kepler/sdk'
import ConfirmDialog from '@/views/ConfirmDialog.vue'

@Component({
  components: {
    Layout,
    Card,
    CloseButton,
    TextField,
    FormTag,
    Button,
  },
  name: 'ChangePasswordDialog',
})
export default class ChangePasswordDialog extends Vue {
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('closeDialog') public closeDialog!: (index?: number) => void

  public valid: boolean = false
  public changePasswordRequest: ChangePasswordRequest = {
    new_password: '',
    new_password_retype: '',
    old_password: '',
  }
  protected rules: any = Validations.rules

  public created() {
    if (!this.$isLogged()) {
      this.closeDialog()
    }
  }

  public submit() {
    sdk.profile.change_password(this.changePasswordRequest).then((r) => {
      const response = r.data
      if (r.data.result_code === 'password-changed') {
        this.closeDialog()
        this.openDialog(new Dialog(ConfirmDialog, {
          imageState: 'success.svg',
          confirmText: this.$t('common.continue'),
          code: '',
          subtitle: response.messages.join('\n'),
          title: response.result,
          singleAction: true,
          emitConfirm: false,
        }))
      }
    })
  }
}
