























import {Vue, Component, Prop} from 'vue-property-decorator'
import {Driver, Dialog} from '@/lib/kepler/interfaces'
import Utils from '../utils'
import ProfileWithImage from '../components/ProfileWithImage.vue'
import Icon from '../components/proxy/Icon.vue'
import Button from '../components/Button.vue'
import {Action, State} from 'vuex-class'
import ConfirmDialogCallback from './ConfirmDialogCallback.vue'
import Img from '../components/proxy/Image.vue'
import Avatar from '../components/proxy/Avatar.vue'
import Card from '@/components/proxy/Card/Card.vue'
import {ProfileState} from '@/store/modules/profile'
import collect from 'collect.js'
import ProgressCircular from '@/components/proxy/ProgressCircular.vue'
import Sheet from '@/components/proxy/Sheet.vue'
import Divider from '@/components/proxy/Divider.vue'

@Component({
  components: {
    Divider,
    Sheet,
    ProgressCircular,
    Card,
    Avatar,
    Img,
    ConfirmDialogCallback,
    Button,
    Icon,
    ProfileWithImage,
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    StatusLabel: Utils.loadComponent('StatusLabel'),
  },
  name: 'MyDriversProfile',
})

export default class MyDriversProfile extends Vue {
  @Prop() public driver!: Driver
  @State('profile') public profileState!: ProfileState
  @Action('getDrivers') public getDrivers!: () => void
  @Action('suspendDriver') protected suspendDriver!: (d: Driver) => Promise<void>
  @Action('reactivateDriver') protected reactivateDriver!: (d: Driver) => Promise<void>
  @Action('openDialog') protected openDialog!: (dialog: Dialog) => void
  @Action('closePopup') protected closePopup!: (index?: number) => void

  protected loading: boolean = false

  get driverStatus(): boolean {
    return this.driver ? this.driver.status === 'ACTIVE' || false : false
  }

  protected suspend() {
    this.loading = true
    this.suspendDriver(this.driver).then(() => {
      this.openDialog(new Dialog(ConfirmDialogCallback, {
        imageState: 'success.svg',
        code: '',
        title: this.$t('profile.suspended_confirm'),
        subtitle: '',
        confirmCallback: () => {
          this.closePopup(0)
        },
      }, null, false))
      this.loading = false
    })
  }

  protected reactivate() {
    this.loading = true
    this.reactivateDriver(this.driver).then(() => {
      this.openDialog(new Dialog(ConfirmDialogCallback, {
        imageState: 'success.svg',
        code: '',
        title: this.$t('profile.reactivated_confirm'),
        subtitle: '',
        confirmCallback: () => {
          this.closePopup(0)
        },
      }, null, false))
      this.loading = false
    })
  }

  protected get driverHasPlan() {
    const c = collect((this.profileState.subscriptions as any).drivers)
    return !!c.firstWhere('id', this.driver.id)
  }

  protected goToSubs() {
    this.closePopup()
    this.$router.push({name: 'my subscriptions'})
  }

  protected showEditButton() {
    return ['ACTIVE', 'ON HOLD'].includes(this.driver.status)
  }
}
