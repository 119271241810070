interface Style {
  elementType?: string
  stylers: Styler[]
  featureType?: string
}

interface Styler {
  color?: string
  visibility?: string
  weight?: number
  saturation?: number
  lightness?: number
}

import styleJson from "./mapStyles.json"

class MapStyles {
  private styles: {[key: string]: Style[]} = styleJson

  public getStyle(style: string): Style[] {
    return this.styles[style]
  }

  public get styleList() {
    return Object.keys(this.styles)
  }
}

export default new MapStyles()
