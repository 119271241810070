

















import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import Utils from '@/utils'
import AddCredit from '../views/AddCredit.vue'
import {Dialog} from '@/lib/kepler/interfaces'

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    CardWithReport: Utils.loadComponent('CardWithReport'),
    AddCredit,
  },
  name: 'BuyTopUp',
})

export default class BuyTopUp extends Vue {
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Prop({type: String, default: () => 'topUp'}) public theme!: string

  private openAddCredit() {
    this.openDialog(new Dialog(AddCredit, {
      confirmText: this.$t('activities.book_and_shop.buy'),
    }))
  }
}
