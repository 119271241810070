

















import {Component, Watch} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import {Action, State} from 'vuex-class'
import TabHistoryMixin from '../lib/TabHistory'
import Utils from '@/utils'

@Component({
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    MyDriversListHistory: Utils.loadView('Booking/MyDriversListHistory'),
    UpcomingListHistory: Utils.loadView('Booking/UpcomingListHistory'),
    FullListHistory: Utils.loadView('Booking/FullListHistory'),
    Tabs: Utils.loadComponent('proxy/Tabs/Tabs'),
    Tab: Utils.loadComponent('proxy/Tabs/Tab'),
    TabItems: Utils.loadComponent('proxy/Tabs/TabItems'),
    TabItem: Utils.loadComponent('proxy/Tabs/TabItem'),
    IsLogged: Utils.loadComponent('IsLogged'),
  },
  mixins: [TabHistoryMixin],
})
export default class History extends mixins<TabHistoryMixin>(TabHistoryMixin) {
  @State(((state) => state.profile.driver?.main_driver)) public mainDriver!: boolean
  @Action('flushActionButtons') public flushActionButtons!: any

  public currentTab: number | null = null
  public tabMap: { [key: string]: number } = {
    '': 0,
    '#all': 1,
    '#drivers': 2,
  }

  @Watch('currentTab')
  private onTabChange() {
    this.flushActionButtons()
  }
}
