





































import {Component, Prop, Vue} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import {Dialog, Driver, Subscription, SubscriptionPlan} from '@/lib/kepler/interfaces'
import sdk from '@/lib/kepler/sdk'

import ConfirmDialog from '@/views/ConfirmDialog.vue'
import Utils from '@/utils'
import CloseButton from '@/components/CloseButton.vue'

interface CondensedDriver {
  driver: Driver
  surname: string
  name: string
  hasThisSubscription: boolean
  hasSubscription: boolean
  active: boolean
  profile_picture: string
  id: string
  status: 'ON HOLD' | 'PENDING REVIEW' | 'APPROVED' | 'REFUSED' | 'ACTIVE' | 'SUSPENDED'
}

@Component({
  components: {
    Alert: Utils.loadComponent('proxy/Alert'),
    CloseButton,
    Card: Utils.loadComponent('proxy/Card/Card'),
    Button: Utils.loadComponent('Button'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
    CardDriver: Utils.loadComponent('CardDriver'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Icon: Utils.loadComponent('proxy/Icon'),
  },
})
export default class SubscriptionAssignDialog extends Vue {
  @State((state) => state.profile.drivers) public drivers!: Driver[]
  @State((state) => state.profile.subscriptions) public subscriptions!: Subscription[]
  @Action('getSubscriptions') public getSubscriptions!: () => Promise<Subscription[]>
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void

  @Getter('defaultPlan') public defaultPlan!: SubscriptionPlan | null

  @Prop({required: true, type: Object}) public subscription!: Subscription

  private selectedDrivers: string[] = []
  private loading: boolean = false

  protected get changedDrivers() {
    const arr: string[] = []
    this.selectedDrivers.forEach((driverId) => {
      if (!this.subscription.drivers.find((d) => d.id === driverId)) {
        arr.push(driverId)
      }
    })
    return arr
  }

  protected get activeDrivers(): CondensedDriver[] {
    const drivers = this.drivers || []
    const subscriptionDrivers = this.subscription.drivers

    return drivers.map((driver) => {
      const {id, name, surname, profile_picture, status} = driver
      const hasThisSubscription = subscriptionDrivers.findIndex((d) => d.id === driver.id) >= 0
      const hasSubscription = this.checkDriverForSubscription(driver)

      return {
        id,
        name,
        surname,
        profile_picture,
        status,
        hasThisSubscription,
        hasSubscription,
        driver,
        active: !(hasThisSubscription || hasSubscription),
      }
    })
  }

  protected mounted() {
    this.init()
  }

  protected init() {
    this.getSubscriptions().then(() => {
        this.subscription.drivers.forEach((driver: Driver) => {
          this.selectedDrivers.push(driver.id)
        })
      },
    )
  }

  private contactSupport(d: CondensedDriver) {
    if (d.hasThisSubscription) {
      this.openDialog(new Dialog(ConfirmDialog, {
        singleAction: true,
        imageState: 'support.svg',
        code: '',
        title: '',
        subtitle: this.$t('subscription.cannot_remove_driver'),
        confirmText: this.$t('action.close'),
      }, null, false, null))
    } else if (d.hasSubscription) {
      this.openDialog(new Dialog(ConfirmDialog, {
        singleAction: true,
        imageState: 'support.svg',
        code: '',
        title: '',
        subtitle: this.$t('subscription.driver_already_have_a_subscription'),
        confirmText: this.$t('action.close'),
      }, null, false, null))
    }
  }

  private checkDriverForSubscription(d: Driver) {
    const id = d.id
    const subscriptionMode = this.subscription.plan.booking_mode
    const subscriptions = this.subscriptions
    let result: boolean = false
    if (subscriptions) {
      subscriptions.forEach((subscription) => {
        if (subscription.plan.booking_mode === subscriptionMode) {
          return false
        }
        const driverInSubscription = subscription.drivers.filter((driver) => {
          return driver.id === id
        }).length > 0
        if (driverInSubscription) {
          result = true
        }
      })
    }
    return result
  }

  private submitChanges() {
    this.loading = true
    this.changedDrivers.forEach((driverId, i) => {
      sdk.subscription.addDriver(this.subscription.id, driverId).then(
        () => {
          if (i === this.changedDrivers.length - 1) {
            const str = this.changedDrivers.length > 1 ? 'subscription.drivers_added' : 'subscription.driver_added'
            this.openDialog(new Dialog(ConfirmDialog, {
              singleAction: true,
              imageState: 'success.svg',
              code: '',
              title: '',
              subtitle: this.$t(str),
              confirmText: this.$t('action.close'),
            }, null, false, null))
            this.init()
            this.loading = false
          }
        },
      ).catch(() => (
        this.loading = false
      ))
    })
  }
}
