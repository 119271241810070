


























import {Vue, Component} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import {ProfileState} from '@/store/modules/profile'
import {Subscription, SubscriptionPlan} from '@/lib/kepler/interfaces'
import moment from 'moment'
import Utils from '@/utils'

@Component({
  components: {
    FilteredListWithAddButton: Utils.loadComponent('FilteredListWithAddButton'),
    CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
    Flex: Utils.loadComponent('proxy/Flex'),
    CardSubscription: Utils.loadComponent('subscriptions/CardSubscription'),
  },
  name: 'MySubscriptions',
})
export default class MySubscriptions extends Vue {
  @State('profile') public profileState!: ProfileState
  @Getter('defaultPlan') public defaultPlan!: SubscriptionPlan | null
  @Action('getSubscriptions') public getSubscriptions: any

  protected showExpired: boolean = false

  protected get subscriptions() {
    const subscriptions = this.profileState.subscriptions || []
    return subscriptions.filter((sub) => {
      return this.showExpired ? true : (this.isActive(sub) || this.hasDefaultPlan(sub))
    })
  }

  protected get hasExpired() {
    const subscriptions = this.profileState.subscriptions || []
    return !!subscriptions.find((sub) => {
      return !(this.isActive(sub) || this.hasDefaultPlan(sub))
    })
  }

  protected hasDefaultPlan(subscription: Subscription) {
    return this.defaultPlan?.id === subscription.plan.id
  }

  protected mounted() {
    this.getSubscriptions()
  }

  protected openPlansPage() {
    this.$router.push({name: 'plans'})
  }

  protected isActive(s: Subscription) {
    const now = moment()
    const end = moment.unix(s.end_timestamp)
    return now.isBefore(end)
  }
}
