





import {Vue, Component, Prop} from 'vue-property-decorator'
import {VehicleSlot} from '@/lib/kepler/interfaces'
import Utils from '@/utils'
import VehicleSlotHelper from '@/lib/vehicleSlot'

@Component({
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Chip: Utils.loadComponent('proxy/Chip'),
  },
  name: 'VehicleTypeChip',
})
export default class VehicleTypeChip extends Vue {
  @Prop() protected vehicleSlot!: VehicleSlot

  protected get slotHelper() {
    return new VehicleSlotHelper(this.vehicleSlot)
  }
}
