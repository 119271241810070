import {ActionTree, MutationTree} from 'vuex'
import rootState, {RootState} from '@/store'
import {Vue} from 'vue-property-decorator'
import sdk from '@/lib/kepler/sdk'
import {
  MapMarker,
  ParkingLot,
  Poi,
  PoiData,
  Position,
  ServiceZone,
  VehicleSlot, Zone,
} from '@/lib/kepler/interfaces'
import ServiceMesh from '@/lib/serviceMesh'
import collect from 'collect.js'

type ValueOf<T> = T[keyof T]

export interface Filter<T> {
  [id: string]: {
    active: boolean,
    data: T[],
    icon?: string,
    color?: string,
  }
}

export interface SearchParameters {
  [name: string]: string | number,
}

export class MapFiltersState {
  [k: string]: Filter<PoiData | Zone | ParkingLot | MapMarker> | SearchParameters

  public park: Filter<ParkingLot> = {}
  public poi: Filter<PoiData> = {}
  public vehicles: Filter<MapMarker> = {}
  public zones: Filter<Zone> = {}
  public parameters: SearchParameters = {}
}

const mutations: MutationTree<MapFiltersState> = {
  TOGGLE_FILTER: (mState, path) => {
    const p = mState[path[0]]?.[path[1]]
    if (typeof p === 'object' && typeof p.active === 'boolean') {
      Vue.set(p, 'active', !p.active)
    }
  },
  MODIFY_FILTER: (mState, path: [string, string, ValueOf<Filter<any>>]) => {
    const payload = path[2]
    const p = mState[path[0]]?.[path[1]]
    if (typeof p === 'object' && typeof p.active === 'boolean') {
      payload.active = p.active
    }
    Vue.set(mState[path[0]], path[1], payload)
  },
  DELETE_FILTER: (mState, path: [string, string]) => {
    Vue.delete(mState[path[0]], path[1])
  },
  MODIFY_PARAMETER: (state, payload: SearchParameters) => {
    if (!state.parameters) {
      state.parameters = {}
    }
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        Vue.set(state.parameters, key, payload[key])
      }
    }
  },
}

const actions: ActionTree<MapFiltersState, RootState> = {
  _initFilters({state, commit, dispatch}, position: Position) {
    return new Promise<void>((resolve, reject) => {
      if (!position) {
        position = {
          latitude: 0,
          longitude: 0,
        }
      }
      Promise.allSettled([
        dispatch('_getPoi'),
        dispatch('_getServiceZones'),
        dispatch('_getParkingLots', position),
        dispatch('_getVehicles', position),
      ]).then((a) => {
        const poiReq = a[0] as PromiseSettledResult<Promise<{ [p: string]: Poi }>>
        const zonesReq = a[1] as PromiseSettledResult<Promise<{ [p: string]: ServiceZone }>>
        const parkReq = a[2] as PromiseSettledResult<Promise<ParkingLot[]>>
        const vehicleReq = a[3] as PromiseSettledResult<Promise<{ [p: string]: VehicleSlot }>>

        const checkRemoved = (type: string, added: string[]) => {
          Object.keys(state[type]).forEach((key) => {
            if (!added.includes(key)) {
              commit('DELETE_FILTER', [type, key])
            }
          })
        }

        if (poiReq.status === 'fulfilled') {
          const poiAdded: string[] = []
          Object.entries(poiReq.value).forEach(([id, poi]) => {
            const p = poi as Poi
            commit('MODIFY_FILTER', ['poi', id, {
              ...p,
              active: false,
              name: p.display_text,
              data: p.data,
              icon: p.icon,
            }])
            poiAdded.push(id)
          })
          checkRemoved('poi', poiAdded)
        }
        if (zonesReq.status === 'fulfilled') {
          const zoneAdded: string[] = []
          Object.entries(zonesReq.value ?? []).forEach(([id, zone]) => {
            const z = zone as ServiceZone
            commit('MODIFY_FILTER', ['zones', id, {
              active: true,
              name: id,
              data: z.zones,
            }])
            zoneAdded.push(id)
          })
          checkRemoved('zones', zoneAdded)
        }
        if (parkReq.status === 'fulfilled') {
          commit('MODIFY_FILTER', ['park', 'parkings', {
            active: true,
            name: 'parkings',
            data: parkReq.value,
          }])
        }
        if (vehicleReq.status === 'fulfilled') {
          const vehicleAdded: string[] = []
          Object.entries(vehicleReq.value).forEach(([id, vehicles]) => {
            const sm = new ServiceMesh()
            const markers: MapMarker[] = []
            const name: string = sm.vehicleType[id]
            const c = collect(vehicles as VehicleSlot[])

            // Group by vehicle position
            const g = c.groupBy((i: VehicleSlot) => {
              return i.position.latitude + ',' + i.position.longitude
            })
            // For each group i have to make a marker
            // noinspection JSUnusedAssignment
            const j = JSON.parse(g.toJson()) as {[pos: string]: VehicleSlot[]}
            // collect.js typings are rubbish, i'm worse
            for (const k in j) {
              if (j.hasOwnProperty(k)) {
                const items = j[k]
                const first = items[0]
                markers.push({
                  items,
                  key: k,
                  icon: sm.getImageForVehicle(first, 'OK'),
                  distance: first.distance,
                  position: {
                    lat: first.position.latitude,
                    lng: first.position.longitude,
                  },
                })
              }
            }
            commit('MODIFY_FILTER', ['vehicles', id, {
              active: true,
              data: markers,
              name,
              icon: markers[0].icon,
              color: ServiceMesh.colors[id],
            }])
            vehicleAdded.push(id)
          })
          checkRemoved('vehicles', vehicleAdded)
        }
        resolve()
      }).catch(reject)
    })
  },
  _toggle({commit, dispatch}, path: [string, string]) {
    commit('TOGGLE_FILTER', path)
    dispatch('sleep', 'toggle_filter')
  },
  _parameter({commit}, payload: SearchParameters) {
    commit('MODIFY_PARAMETER', payload)
  },
  _updateFilters({}) {
    // TODO: update filters from current and stuff
  },
  // current({rootState, dispatch, commit}): Promise<ReservationResponse[]> | undefined {
  //   if (rootState.userToken) {
  //     return sdk.booking.current().then((r) => {
  //       const reservations = r.data
  //       commit('BOOKING_SET_ACTIVE_RESERVATIONS', reservations)
  //       reservations.forEach((reservation) => {
  //         dispatch('setZone', reservation.vehicle_slot.id)
  //       })
  //       dispatch('sleep')
  //       commit('BOOKING_START_CRON', () => {
  //         dispatch('current')
  //       })
  //       return r.data
  //     })
  //   }
  // },
  _getPoi({}) {
    return sdk.map.getPoi()
      .then((r) => {
        const obj: { [id: string]: Poi } = {}
        r.data.forEach((p) => {
          obj[p.id] = p
        })
        return obj
      })
      .catch(() => {
        //
      })
  },
  _getServiceZones({}) {
    const city_id = rootState.getters.city?.id
    return sdk.map.serviceZones(city_id)
      .then((r) => {
        const obj: { [name: string]: ServiceZone } = {}
        r.data.forEach((p) => {
          obj[p.name] = p
        })
        return obj
      })
      .catch(() => {
        //
      })
  },
  _getParkingLots({}, position: Position) {
    const {latitude, longitude} = position
    const city_id = rootState.getters.city?.id
    return sdk.booking.getParkingLots({latitude, longitude, booking_mode: 'OWFF', city_id})
      .then((r) => r.data)
  },
  _getVehicles({state}, position?: Position): Promise<{ [id: string]: VehicleSlot[] }> {
    return new Promise((resolve, reject) => {
      sdk.booking.serviceMesh().then(() => {
        const singleSearch = true
        if (singleSearch) {
          sdk.booking.search({
            latitude: position?.latitude,
            longitude: position?.longitude,
            ...state.parameters,
          }).then((searchResponse) => {
            const obj: { [name: string]: VehicleSlot[] } = {}
            searchResponse.data.forEach((p) => {
              const id = p.reservation_type + p.vehicle.category.type.toUpperCase()
              if (!obj[id]) {
                obj[id] = []
              }
              obj[id].push(p)
            })
            resolve(obj)
          }).catch(reject)
        } else {
          // TODO: all the above, but with separate requests
          // const searchRequests: SearchRequest[] = []
          // r.data.forEach((mesh) => {
          //   searchRequests.push({
          //     booking_mode: mesh.booking_mode,
          //     vehicle_type_id: mesh.vehicle_type_id,
          //     latitude: position.latitude,
          //     longitude: position.longitude,
          //   })
          // })
          // Promise.allSettled(searchRequests.map((sr) => sdk.booking.publicSearch(sr)))
          //   .then((all) => {
          //     const x = all.map((vs) => {
          //
          //     })
          //     const obj: { [name: string]: ServiceZone } = {}
          //     r.data.forEach((p) => {
          //       obj[p.name] = p
          //     })
          //     return obj
          //   })
        }
      })
    })
  },
}

const getters = {
  city_id: () => rootState.getters.city?.id,
}

export default {
  state: new MapFiltersState(),
  mutations,
  actions,
  getters,
}
