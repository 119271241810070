























import {Component} from 'vue-property-decorator'
import Utils from '../utils'
import {Action} from 'vuex-class'
import {
  ReservationResponse,
  VehicleCategory,
} from '@/lib/kepler/interfaces'
import sdk from '../lib/kepler/sdk'
import AutoComplete from '@/components/proxy/Inputs/AutoComplete.vue'
import SelectTag from '@/components/proxy/Inputs/SelectTag.vue'
import TextField from '@/components/proxy/Inputs/TextField.vue'
import moment from 'moment'
import RTVehicleSearch from '@/views/VehicleSearch.vue'

@Component({
  components: {
    TextField,
    SelectTag,
    AutoComplete,
    IsLogged: Utils.loadComponent('IsLogged'),
    VehicleBookingDateTimeSelect: Utils.loadView('Vehicle/VehicleBookingDateTimeSelect'),
    CheckInOutPicker: Utils.loadComponent('CheckInOutPicker'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    Chip: Utils.loadComponent('proxy/Chip'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'CorporateVehicleSearch',
})

export default class CorporateVehicleSearch extends RTVehicleSearch {
  @Action('selectReservation') public selectReservation!: (r: ReservationResponse) => Promise<ReservationResponse>
  @Action('current') public current!: () => Promise<void | ReservationResponse[]>

  public categories: any[] = []
  public bookingMode: string = 'CRT'

  protected bookRequest: any = {
    start: null,
    end: null,
    parking_lot_id: null,
    vehicle_category_id: null,
    memo: '',
  }

  protected lots: any[] = []

  protected created() {
    sdk.booking.getPrivateLots().then((r) => {
      this.lots = r.data
    })
  }

  protected categoryString(category: VehicleCategory) {
    let type = category.type.toLowerCase()
    const name = category.name.toLowerCase()
    type = name.includes(type) ? '' : type + ' - '
    return type + name
  }

  protected toggleCategory(category: any) {
    if (category.id === this.searchRequest.vehicle_category_id) {
      this.searchRequest.vehicle_category_id = undefined
      return
    }
    this.searchRequest.vehicle_category_id = category.id
  }

  protected selectedFeatures() {
    return this.features.filter((feature) => {
      return feature.selected === true
    })
  }

  protected populateFields(val: { start: number, end: number }) {
    this.bookRequest.start = val.start
    this.bookRequest.end = val.end
  }

  protected setCategories() {
    const arr = this.categoriesState
    if (arr) {
      arr.forEach((category) => {
        this.$set(category, 'selected', true)
      })
      if (this.$route.params.type) {
        arr.filter((cat) => {
          return cat.type === this.$route.params.type
        })
      }
      this.categories = arr
    }
  }

  protected mounted() {
    this.setPos()
    this.setCategories()
    this.locate()
  }

  protected isCurrent(r: ReservationResponse) {
    if (r.start_timestamp && r.end_timestamp) {
      const start = moment(r.start_timestamp, 'X')
      const end = moment(r.end_timestamp, 'X')
      return moment().isBetween(start, end)
    }
  }

  protected isFuture(r: ReservationResponse) {
    if (r.start_timestamp) {
      const start = moment(r.start_timestamp, 'X')
      return moment().isBefore(start)
    }
  }

  protected submit() {
    this.loading = true
    sdk.booking.autoBook(this.bookRequest)
      .then((r) => {
        if (this.isCurrent(r.data)) {
          this.selectReservation(r.data).then(() => {
            this.current().then(() => {
              this.$router.push({name: 'home'}).then(() => {
                this.$router.push({name: 'reservation'})
              })
            })
          })
        } else if (this.isFuture(r.data)) {
          this.$router.push({name: 'history', hash: '#future', params: {reloadHistory: 'future'}})
        }
      })
      .catch((e) => {
        this.$log(e, 2)
        this.loading = false
      })
  }
}
