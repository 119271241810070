





import {Component, VModel, Vue, Watch} from 'vue-property-decorator'

@Component({})
export default class ClickOutside extends Vue {
  @VModel({type: Boolean}) private show!: boolean

  @Watch('show', {immediate: true})
  private onShow(val: boolean) {
    if (val) {
      ['mousedown', 'touchstart'].forEach((evt) => {
        this.$root.$el.addEventListener(evt, this.clickOutside, {passive: true})
      })
    }
  }

  private clickOutside(e: Event) {
    if (!(this.$el === e.target || this.$el.contains(e.target as Node))) {
      ['mousedown', 'touchstart'].forEach(evt => this.$root.$el.removeEventListener(evt, this.clickOutside))
      this.show = false
    }
  }
}
