





























































































































































































































































































































































































































































































/* tslint:disable: max-file-line-count cyclomatic-complexity */

import {Component, Vue, Watch} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'

import {
  Attachment,
  BookingMode,
  Highlight,
  VehicleType,
  Popup,
  Dialog,
} from '@/lib/kepler/interfaces'
import {TermOfServicesState} from '@/store/modules/termOfServices'
import {ProfileState} from '@/store/modules/profile'
import ServiceMesh from '@/lib/serviceMesh'
import Validations from '@/lib/Validations'
import {EventBus} from '@/main'
import Utils from '@/utils'

import ErrorView from '@/views/Error.vue'
import AddCredit from '@/views/AddCredit.vue'
import ConfirmDialog from '@/views/ConfirmDialog.vue'
import FuelPinDialog from '@/views/FuelPinDialog.vue'
import BookingEdit from '@/views/Booking/BookingEdit.vue'
import ChangeLangDialog from '@/views/ChangeLangDialog.vue'
import AlreadyHasAccount from '@/views/AlreadyHasAccount.vue'
import PhoneVerificationView from '@/views/PhoneVerification.vue'
import ForceTerminateDialog from '@/views/Booking/ForceTerminateDialog.vue'
import VehicleConfirmBooking from '@/views/Vehicle/VehicleConfirmBooking.vue'
import VehicleBookingDateTimeSelect from '@/views/Vehicle/VehicleBookingDateTimeSelect.vue'
import VehicleBookingAddMemoDialogView from '@/views/Vehicle/VehicleBookingAddMemoDialog.vue'

import PinRequestCallback from '@/views/Vehicle/PinRequestCallback.vue'
import {FlowInputsState} from '@/store/modules/flowInputs'
import {VMenu, VSlider} from 'vuetify/lib'
import ChecklistItem from '@/components/ChecklistItem.vue'
import Checklist from '@/components/Checklist.vue'
import ForceTerminateChecklist from '@/views/Booking/ForceTerminateChecklist.vue'
import LoginDialog from '@/views/LoginDialog.vue'
import VehicleAddReportDialog from '@/views/Vehicle/VehicleAddReportDialog.vue'
import BrowserCamera from '@/components/BrowserCamera.vue'
import Img from '@/components/proxy/Image.vue'
import ImageCrop from '@/components/ImageCrop.vue'
import Flex from '@/components/proxy/Flex.vue'
import CameraDialog from '@/views/uploader_flow/CameraDialog.vue'
import DevCamera from '@/views/DevCamera.vue'
import CameraPreset from '@/lib/camera/cameraPresets'
import {blobToDataURL} from '@/lib/BlobHelper'
import MultipleDialogCallback from '@/views/MultipleDialogCallback.vue'
import ChangePasswordDialog from '@/views/ChangePasswordDialog.vue'
import AddCardDialog from '@/views/AddCardDialog.vue'
import TerminateMode from '@/views/TerminateMode.vue'
import Sheet from '@/components/proxy/Sheet.vue'
import FiltersLayout from '@/components/filters/FiltersLayout.vue'
import List from '@/components/proxy/List/List.vue'
import ListTile from '@/components/proxy/List/ListTile.vue'
import ListTileTitle from '@/components/proxy/List/ListTileTitle.vue'
import AddDriver from '@/components/AddDriver.vue'
import {LogColorByLevel} from '@/lib/plugins/logger'
import QuickExtendDialog from '@/components/QuickExtendDialog.vue'
import VehiclePinRequestDialog from '@/views/Vehicle/VehiclePinRequestDialog.vue'
import fakeData from '@/lib/fakeData'
import ChecklistDialogCallback from '@/views/ChecklistDialogCallback.vue'
import TextareaDialog from '@/views/TextareaDialog.vue'
import moment from 'moment'

@Component({
  components:
    {
      VehicleIcon: Utils.loadComponent('VehicleIcon'),
      ListTileTitle,
      ListTile,
      List,
      FiltersLayout,
      Sheet,
      CameraButton: Utils.loadComponent('uploader/CameraButton'),
      DevCamera,
      CameraDialog,
      Flex,
      ImageCrop,
      Img,
      BrowserCamera,
      Checklist,
      ChecklistItem,
      VSlider,
      VMenu,
      AccordionContent: Utils.loadComponent('proxy/Accordion/AccordionContent'),
      CardSubscription: Utils.loadComponent('subscriptions/CardPlan'),
      Accordion: Utils.loadComponent('proxy/Accordion/Accordion'),
      CardButtonNumeric: Utils.loadComponent('CardButtonNumeric'),
      ToggleableButton: Utils.loadComponent('ToggleableButton'),
      TextField: Utils.loadComponent('proxy/Inputs/TextField'),
      SelectTag: Utils.loadComponent('proxy/Inputs/SelectTag'),
      PhoneWithPrefix: Utils.loadComponent('PhoneWithPrefix'),
      CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
      CardWithReport: Utils.loadComponent('CardWithReport'),
      CardText: Utils.loadComponent('proxy/Card/CardText'),
      TabItems: Utils.loadComponent('proxy/Tabs/TabItems'),
      MonthSwitcher: Utils.loadComponent('MonthSwitcher'),
      TabItem: Utils.loadComponent('proxy/Tabs/TabItem'),
      Container: Utils.loadComponent('proxy/Container'),
      GradientCard: Utils.loadComponent('GradientCard'),
      StatusLabel: Utils.loadComponent('StatusLabel'),
      FlowTest: Utils.loadComponent('flow/FlowTest'),
      CustomIcon: Utils.loadComponent('CustomIcon'),
      CardButton: Utils.loadComponent('CardButton'),
      CardDriver: Utils.loadComponent('CardDriver'),
      Divider: Utils.loadComponent('proxy/Divider'),
      Card: Utils.loadComponent('proxy/Card/Card'),
      Tabs: Utils.loadComponent('proxy/Tabs/Tabs'),
      Layout: Utils.loadComponent('proxy/Layout'),
      LoopingBG: Utils.loadComponent('LoopingBG'),
      Avatar: Utils.loadComponent('proxy/Avatar'),
      Tab: Utils.loadComponent('proxy/Tabs/Tab'),
      Badge: Utils.loadComponent('proxy/Badge'),
      Chip: Utils.loadComponent('proxy/Chip'),
      Icon: Utils.loadComponent('proxy/Icon'),
      Btn: Utils.loadComponent('proxy/Btn'),
      TopBar: Utils.loadComponent('TopBar'),
      Button: Utils.loadComponent('Button'),
    },
})

export default class Dev extends Vue {
  @State('profile') public profileState!: ProfileState
  @State('termOfServices') public TermsOfServiceState!: TermOfServicesState
  @State('flowInputs') public flowInputs!: FlowInputsState
  @State((state) => state.logs) public logs!: string[]

  @Action('getPlans') public getPlans: any
  @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('setDebugMode') public setDebugMode!: (enabled: boolean) => void
  @Action('setTranslationMode') public setTranslationMode!: (enabled: boolean) => void
  @Action('setDebugTab') public setTab!: (tab: number) => void
  @Action('addProfilePicture') public addProfilePicture!: any
  @Action('clearLogs') public clearLogs!: () => void
  @Action('toggleDevFlow') public toggleDevFlow!: () => void
  @Action('switchProfile') public switchProfile!: (token: string) => Promise<void>

  @Getter('debugTab') public debugTab!: number
  @Getter('debugMode') public debugMode!: boolean
  @Getter('translationMode') public translationMode!: boolean
  public rules: any = Validations.rules
  public phoneNumber: string = ''
  public testBool1: boolean = false
  public reversedLogs: boolean = true
  public flowName: string = ''
  public flowPage: number = 0
  public cameraPresets = CameraPreset.prototype.getPresets()
  public cameraResult: string | null = null
  public cameraResultToken: string | null = null
  public uploadResultUrl: string | null = null
  public token: string = ''
  public tokenSwitching: boolean = false

  public tabs = [
    'misc',
    'camera',
    'dialogs/popups',
    'flow',
    'branding',
    'typography',
    'buttons',
    'animated background',
    'biometrics',
    'filters',
    'log',
    'token',
  ]
  public highlights: Highlight[] = [
    {
      title: 'COST PER HOUR',
      content: '€1.75',
      subcontent: '/ h',
    },
    {
      title: `COST PER ${this.$distance().toUpperCase()}`,
      content: '€0.35',
      subcontent: `/${this.$distance()}`,
    },
    {
      title: 'SUBSCRIPTION',
      content: '€12',
      subcontent: '/ month',
    },
  ]

  public bookingConfirmSelected = {
    bookingMode: null,
    vehicleType: null,
    action: null,
  }
  public bookingConfirmData = {
    bookingModes: ['RT', 'FF', 'OWFF'],
    vehicleTypes: ['car'],
    actions: ['confirm', 'keep'],
  }
  private fakeVehicleSlot = fakeData.vehicleSlot
  private fakeBookRequest = fakeData.bookRequest
  private fakeReservation = fakeData.reservation
  private checkList = [
    {
      id: 'item1',
      title: 'ignition is off',
      text: 'I\'ve checked the control panel and there are no blinking lights.',
      icon: 'mdi-key-variant',
    },
    {
      id: 'item2',
      title: 'combobulator is off',
      description: 'I don\'t know what it is but I\'ve checked very carefully.',
      icon: 'mdi-lock-outline',
    },
    {
      id: 'item3',
      title: 'reaction control system is nominal',
      description: 'Also, I\'ve degaussed the flywheels thoroughly.',
      icon: 'mdi-flash-off',
    },
    {
      id: 'item4',
      title: 'I\'ve parked decently',
      description: 'I\'ve checked with mission control the stability of the orbit.',
      icon: 'mdi-parking',
    },
  ]
  private checkListValues: Record<string, boolean> = {}

  public get currentTab() {
    return !!this.debugTab ? Number(this.debugTab) : 0
  }

  public set currentTab(tab: number) {
    this.setTab(tab)
  }

  public get devModeOn() {
    return this.debugMode
  }

  public set devModeOn(enabled: boolean) {
    this.setDebugMode(enabled)
  }
  public get translationModeOn() {
    return this.translationMode
  }

  public set translationModeOn(enabled: boolean) {
    this.setTranslationMode(enabled)
  }

  public get testBool2() {
    return !this.testBool1
  }

  public set testBool2(val) {
    this.testBool1 = !val
  }

  public get flowNames() {
    const arr: string[] = []
    Object.keys(this.flowInputs).forEach((s) => {
      arr.push(s)
    })
    return arr
  }

  public get selectedFlow() {
    if (this.flowNames && this.flowName && this.flowNames.includes(this.flowName)) {
      return this.flowInputs[this.flowName]
    }
    return null
  }

  public get flowPages() {
    return this.selectedFlow ? this.selectedFlow.steps.length - 1 : null
  }

  public get theme() {
    const theme = this.$branding.theme
    const mesh = ServiceMesh.colors
    return {
      common: {
        primary: theme.primary,
        secondary: theme.secondary,
        accent: theme.accent,
        error: theme.error,
        info: theme.info,
        success: theme.success,
        warning: theme.warning,
      },
      buttons: {
        buttonDefaultColor: theme.buttonDefaultColor,
        buttonDefaultColorOverride: theme.buttonDefaultColorOverride,
      },
      cards: {
        topBar: {
          top: theme.topBarTop,
          bottom: theme.topBarBottom,
          override: theme.topBarContrastOverride,
          angle: this.$branding.gradientAngle.topBar,
          customCss: this.$branding.customCss.topBar,
        },
        filters: {
          top: theme.filtersTop,
          bottom: theme.filtersBottom,
          override: theme.filtersContrastOverride,
          angle: this.$branding.gradientAngle.filters,
          customCss: this.$branding.customCss.filters,
        },
        profile: {
          top: theme.profileTop,
          bottom: theme.profileBottom,
          override: theme.profileContrastOverride,
          angle: this.$branding.gradientAngle.profile,
          customCss: this.$branding.customCss.profile,
        },
        wallet: {
          top: theme.walletTop,
          bottom: theme.walletBottom,
          override: theme.walletContrastOverride,
          angle: this.$branding.gradientAngle.wallet,
          customCss: this.$branding.customCss.wallet,
        },
        topUp: {
          top: theme.topUpTop,
          bottom: theme.topUpBottom,
          override: theme.topUpContrastOverride,
          angle: this.$branding.gradientAngle.topUp,
          customCss: this.$branding.customCss.topUp,
        },
        coupon: {
          top: theme.couponTop,
          bottom: theme.couponBottom,
          override: theme.couponContrastOverride,
          angle: this.$branding.gradientAngle.coupon,
          customCss: this.$branding.customCss.coupon,
        },
        plan: {
          top: theme.planTop,
          bottom: theme.planBottom,
          override: theme.planContrastOverride,
          angle: this.$branding.gradientAngle.plan,
          customCss: this.$branding.customCss.plan,
        },
        planDefault: {
          top: theme.planDefaultTop,
          bottom: theme.planDefaultBottom,
          override: theme.planDefaultContrastOverride,
          angle: this.$branding.gradientAngle.planDefault,
          customCss: this.$branding.customCss.planDefault,
        },
        subscription: {
          top: theme.subscriptionTop,
          bottom: theme.subscriptionBottom,
          override: theme.subscriptionContrastOverride,
          angle: this.$branding.gradientAngle.subscription,
          customCss: this.$branding.customCss.subscription,
        },
        document: {
          top: theme.documentTop,
          bottom: theme.documentBottom,
          override: theme.documentContrastOverride,
          angle: this.$branding.gradientAngle.document,
          customCss: this.$branding.customCss.document,
        },
        toc: {
          top: theme.tocTop,
          bottom: theme.tocBottom,
          override: theme.tocContrastOverride,
          angle: this.$branding.gradientAngle.toc,
          customCss: this.$branding.customCss.toc,
        },
        overviewWallet: {
          top: theme.overviewWalletTop,
          bottom: theme.overviewWalletBottom,
          override: theme.overviewWalletContrastOverride,
          angle: this.$branding.gradientAngle.overviewWallet,
          customCss: this.$branding.customCss.overviewWallet,
        },
        overviewPrepaid: {
          top: theme.overviewPrepaidTop,
          bottom: theme.overviewPrepaidBottom,
          override: theme.overviewPrepaidContrastOverride,
          angle: this.$branding.gradientAngle.overviewPrepaid,
          customCss: this.$branding.customCss.overviewPrepaid,
        },
        invoice: {
          top: theme.invoiceTop,
          bottom: theme.invoiceBottom,
          override: theme.invoiceContrastOverride,
          angle: this.$branding.gradientAngle.invoice,
          customCss: this.$branding.customCss.invoice,
        },
        mission: {
          top: theme.missionTop,
          bottom: theme.missionBottom,
          override: theme.missionContrastOverride,
          angle: this.$branding.gradientAngle.mission,
          customCss: this.$branding.customCss.mission,
        },
      },
      mesh: {
        'Free Floating': {
          KICKSCOOTER: mesh.FFKICKSCOOTER,
          BIKE: mesh.FFBIKE,
          SCOOTER: mesh.FFSCOOTER,
          AMI: mesh.FFAMI,
          MICRO: mesh.FFMICRO,
          CAR: mesh.FFCAR,
          MINIBUS: mesh.FFMINIBUS,
          VAN: mesh.FFVAN,
        },
        'Round Trip': {
          KICKSCOOTER: mesh.RTKICKSCOOTER,
          BIKE: mesh.RTBIKE,
          SCOOTER: mesh.RTSCOOTER,
          AMI: mesh.RTAMI,
          MICRO: mesh.RTMICRO,
          CAR: mesh.RTCAR,
          MINIBUS: mesh.RTMINIBUS,
          VAN: mesh.RTVAN,
          PLATFORM: mesh.RTPLATFORM,
        },
        'Rent': {
          KICKSCOOTER: mesh.STRKICKSCOOTER,
          BIKE: mesh.STRBIKE,
          SCOOTER: mesh.STRSCOOTER,
          AMI: mesh.STRAMI,
          MICRO: mesh.STRMICRO,
          CAR: mesh.STRCAR,
          MINIBUS: mesh.STRMINIBUS,
          VAN: mesh.STRVAN,
        },
        'One Way': {
          KICKSCOOTER: mesh.OWFFKICKSCOOTER,
          BIKE: mesh.OWFFBIKE,
          SCOOTER: mesh.OWFFSCOOTER,
          AMI: mesh.OWFFAMI,
          MICRO: mesh.OWFFMICRO,
          CAR: mesh.OWFFCAR,
          MINIBUS: mesh.OWFFMINIBUS,
          VAN: mesh.OWFFVAN,
        },
        'Corporate': {
          CAR: mesh.CRTCAR,
          HORSE: mesh.CRTHORSE,
        },
        'Parking': {
          PARKING: theme.PARKING,
        },
      },
    }
  }

  public get formattedLogs() {
    const result = this.logs.map((l) => {
      let timestamp: string | null
      let text: string

      if (l.includes('>>')) {
        [timestamp, text] = l.split('>>')
      } else {
        text = l
        timestamp = null
      }
      return {
        text,
        time: timestamp ? moment(Number(timestamp)).calendar(null, {
          sameDay: 'LTS',
          lastDay: 'DD/MM LTS',
          lastWeek: 'DD/MM LTS',
          sameElse: 'DD/MM LTS',
        }) : null,
        color: this.logColor(text),
      }
    })
    if (this.reversedLogs) {
      return result.reverse()
    }
    return result
  }

  @Watch('selectedFlow', {immediate: true})
  public onSelectedFlowChange() {
    this.flowPage = 0
  }

  public changePage(step: number) {
    this.flowPage = step
  }

  public openConfirm() {
    this.openDialog(new Dialog(ConfirmDialog, {
      imageState: 'success.svg',
    }))
  }

  public openAlert() {
    this.openDialog(new Dialog(ErrorView, {
      code: 'code 123',
      title: 'title',
      subtitle: 'error subtitle',
      singleAction: true,
    }))
  }

  public openSingleAction() {
    this.openDialog(new Dialog(ConfirmDialog, {
      imageState: 'logo.png',
      confirmText: 'confirmText',
      singleAction: true,
      emitConfirm: false,
    }))
  }

  public openStuff(repeatTimes: number = 5, intervalBetween: number = 250) {
    const repeatXI = (callback: () => any, interval: number, repeats: number) => {
      const trigger = () => {
        callback()
        --repeats
        if (repeats === 0) {
          clearInterval(timer)
        }
      }
      const timer = setInterval(trigger, interval)
      trigger()
    }

    let iteration = 1

    const dialog = () => {
      this.openDialog(new Dialog(ConfirmDialog, {
        imageState: 'logo.png',
        confirmText: 'okay',
        code: `repeated ${iteration} times of ${repeatTimes}`,
        title: '',
        subtitle: '',
        singleAction: true,
        emitConfirm: false,
      }))

      iteration++
    }
    repeatXI(dialog, intervalBetween, repeatTimes)
  }

  public openConfirmBooking(bookingMode: BookingMode, vehicleType: VehicleType, action: string = 'confirm') {
    const vehicleSlot = this.fakeVehicleSlot
    this.fakeVehicleSlot.reservation_type = bookingMode
    this.fakeVehicleSlot.vehicle.category.type = vehicleType
    this.openPopup(new Popup(VehicleConfirmBooking, {vehicleSlot, action}, null, true))
  }

  public mounted() {
    this.getPlans()
    EventBus.$on('dialogConfirm', (payload: any) => {
      alert('confirmed, payload: \n' + payload)
    })
    this.bookingConfirmData.bookingModes = ServiceMesh.bookingModes
    this.bookingConfirmData.vehicleTypes = ServiceMesh.vehicleTypes

    this.$log('playmooooooooooooooooove', 0)
  }

  public beforeDestroy() {
    EventBus.$off('dialogConfirm')
  }

  public dialog(type: string) {
    switch (type) {
      case 'confirm':
        this.openConfirm()
        break
      case 'multiple':
        this.openDialog(new Dialog(MultipleDialogCallback, {
          title: 'pick your poison',
          subtitle: 'but be careful what you wish for',
          options: [{
            text: 'i want eklfdj',
            value: 'eklfdj',
          }, {
            text: 'i\'d fancy dgsdgg',
            color: 'purple darken-4',
            value: 'dgsdgg',
          }, {
            text: 'i require sdgdfh',
            color: 'error',
            value: 'sdgdfh',
          }],
          callback: (option: string) => {
            alert(`you got ${option}`)
          },
        }))
        break
      case 'checklist':
        this.openDialog(new Dialog(ChecklistDialogCallback, {
          allRequired: false,
          title: 'I HEREBY DECLARE',
          subtitle: 'take a deep breath...',
          confirmColor: 'accent',
          options: [
            {
              title: 'ignition is off',
              text: 'I\'ve checked the control panel and there are no blinking lights.',
              icon: 'mdi-key-variant',
              value: 'ignition',
            },
            {
              title: 'combobulator is off',
              text: 'I don\'t know what it is but I\'ve checked very carefully.',
              icon: 'mdi-lock-outline',
              value: 'combobulator',
            },
            {
              title: 'reaction control system is nominal',
              text: 'Also, I\'ve degaussed the flywheels thoroughly.',
              icon: 'mdi-flash-off',
              value: 'RCS',
            },
            {
              title: 'I\'ve parked decently',
              text: 'I\'ve checked with mission control the stability of the orbit.',
              icon: 'mdi-parking',
              value: 'parking',
            },
          ],
          callback: (options: string[]) => {
            alert(`you selected ${options.join(', ')}.`)
          },
        }))
        break
      case 'checklistSimple':
        this.openDialog(new Dialog(ChecklistDialogCallback, {
          allRequired: true,
          title: 'Parking check',
          options: [
            {
              text: 'I falsely affirm that I\'ve parked decently.',
              value: 'parking',
            },
          ],
          callback: () => {
            alert(`you liar`)
          },
        }))
        break
      case 'error':
        this.openAlert()
        break
      case 'ChangeLang':
        this.openDialog(new Dialog(ChangeLangDialog, {}))
        break
      case 'phoneVerification':
        this.openDialog(new Dialog(PhoneVerificationView, {
          mobileNumber: this.phoneNumber,
          isDialog: true,
        }))
        break
      case 'pinRequest':
        this.openDialog(new Dialog(PinRequestCallback, {
            reservation: this.fakeReservation,
            showCloseButton: true,
            confirmCallback: () => {
              this.$log('pinRequest dialog confirmed', 1)
            },
            cancelCallback: () => {
              this.$log('pinRequest dialog cancelled', 1)
            },
          },
        ))
        break
      case 'pinRequestOld':
        this.openDialog(new Dialog(VehiclePinRequestDialog, {
            bookAndUnlock: false,
            askForMemo: true,
            vehicleSlot: this.fakeVehicleSlot,
            reservation: this.fakeReservation,
            showCloseButton: true,
            confirmCallback: () => {
              this.$log('pinRequest dialog confirmed', 1)
            },
            cancelCallback: () => {
              this.$log('pinRequest dialog cancelled', 1)
            },
          },
        ))
        break
      case 'fuelPin':
        this.openDialog(new Dialog(FuelPinDialog, {
          imageState: 'success.svg',
          confirmText: 'close',
          pin: 12345,
          odometer: 12345,
          callback: () => {
            alert('callback')
          },
          data: this.$t('vehicle.refuel.necessary'),
          singleAction: true,
          emitConfirm: false,
        }))
        break
      case 'memo':
        this.openDialog(new Dialog(VehicleBookingAddMemoDialogView, {
          vehicle: this.fakeVehicleSlot.vehicle,
          bookRequest: this.fakeBookRequest,
          confirmCallback: () => {
            alert('confirmed')
          },
          cancelCallback: () => {
            alert('cancelled')
          },
        }))
        break
      case 'addCredit':
        this.openDialog(new Dialog(AddCredit, {
          confirmText: '*Buy*',
        }))
        break
      case 'book':
        this.openDialog(new Dialog(VehicleBookingDateTimeSelect, {
          vehicleSlot: this.fakeVehicleSlot,
          bookRequest: this.fakeBookRequest,
        }, null, false, 'quickBookingDateSelected'))
        break
      case 'terminate':
        const checkBoxLabel = this.$t('booking.terminate_mode.accept_undesirable')
        this.openDialog(new Dialog(ForceTerminateDialog, {
          checkBoxLabel,
          terminateMessages: ['', '1000'],
        }))
        break
      case 'bookingEdit':
        this.openDialog(new Dialog(BookingEdit, {
          reservation: this.fakeReservation,
          confirmCallback: () => {
            alert('confirm')
          },
          cancelCallback: () => {
            alert('cancel')
          },
        }))
        break
      case 'bookingExtend':
        this.openDialog(new Dialog(QuickExtendDialog, {
          reservation: this.fakeReservation,
          availability: [], // TODO: fake this
          onConfirm: () => {
            this.$log('fake extend done', 1)
          },
        }, null, false, 'quickBookingDateSelected'))
        break
      case 'alreadyHasAccount':
        const error = {
          result: 'Hai già un account',
          result_code: 'exceptions.system.already-has-account-exception',
          messages: ['Recupera la tua password per accedere'],
        }
        this.openDialog(new Dialog(AlreadyHasAccount, {err: error}, null, false, null))
        break
      case 'forceTerminateChecklist':
        this.openDialog(new Dialog(ForceTerminateChecklist, {
          list: this.checkList,
          title: 'I\'m pretty sure that...',
        }))
        break
      case 'loginDialog':
        this.openDialog(new Dialog(LoginDialog, {}, null, true, null))
        break
      case 'addReportDialog':
        this.openDialog(new Dialog(VehicleAddReportDialog, {
          vehicleSlot: this.fakeReservation.vehicle_slot,
          vehicle: this.fakeReservation.vehicle_slot.vehicle,
          reportType: null,
          reservationId: this.fakeReservation.id,
        }))
        break
      case 'changePasswordDialog':
        this.openDialog(new Dialog(ChangePasswordDialog, {}))
        break
      case 'addCard':
        this.openDialog(new Dialog(AddCardDialog, {}))
        break
      case 'terminateModePopup':
        const loader = false
        this.openPopup(new Popup(
          TerminateMode,
          {
            reservation: this.fakeReservation,
            terminateCb: () => undefined,
            loader,
          },
          this.$t('booking.terminate_mode.title'),
        ))
        break
      case 'addDriver':
        this.openDialog(new Dialog(AddDriver, {}))
        break
      case 'textArea':
        this.openDialog(new Dialog(TextareaDialog, {
          title: 'Text area',
          subtitle: 'to write some stuff',
          placeholder: 'jot something down',
          confirmText: 'and press this button',
          required: true,
          confirmCallback: (t: string) => {
            alert('you wrote: ' + t)
          },
        }))
    }
  }

  public biometricsAvailable() {
    const f = (window as any).Fingerprint
    if (f) {
      const fx = new Promise((resolve, reject) => {
        f.isAvailable(resolve, reject)
      })
      const alert = (r: any) => {
        alert(r)
      }
      fx.then(alert).catch(alert)
    } else {
      alert('not available')
    }
  }

  public biometricsShow() {
    return new Promise((resolve, reject) => {
      (window as any).Fingerprint.isAvailable(resolve, reject)
    })
  }

  public exitForward() {
    alert('exit forward')
  }

  public exitBack() {
    alert('exit back')
  }

  protected gotUploadResponse(a: Attachment) {
    this.cameraResultToken = a.token
    this.uploadResultUrl = a.url
  }

  protected openLink(url: string) {
    cordova.InAppBrowser.open(url, '_system')
  }

  private setChecklist(v: Record<string, boolean>) {
    this.checkListValues = v
  }

  private gotPhoto(r: string) {
    this.cameraResult = r
    this.$log('got string', 0)
  }

  private gotBlob(b: Blob) {
    this.$log('got blob', 0)
    blobToDataURL(b).then((r) => {
      this.cameraResult = (r as string)
    })
  }

  private resetCameraResults() {
    this.cameraResult = null
    this.cameraResultToken = null
  }

  private testLog() {
    this.$log('[test] all is fine.', 0)
    this.$log('[test] this is fine', 1)
    this.$log('[test] this is not fine', 2)
    this.$log('[test] EVERYTHING IS BURNING', 3)
  }

  private logColor(log: string) {
    const level = log.split('|')[0].trim()
    return (LogColorByLevel as { [key: string]: string })[level]
  }

  private loginFromToken(token: string) {
    this.tokenSwitching = true
    this.switchProfile(token).finally(() => {
      this.tokenSwitching = false
    }).then(() => {
      this.token = ''
      this.$router.push({name: 'home'})
    })
  }
}
