




































































import {Vue, Component, Watch} from 'vue-property-decorator'
import Utils from '@/utils'
import {
  VLayout,
  VFlex,
  VSelect,
  VCard,
  VExpansionPanel,
  VExpansionPanelContent,
  VChip,
  VSpacer,
  VProgressLinear,
  VAlert,
  VProgressCircular,
  VBtn,
  VIcon,
} from 'vuetify/lib'
import sdk from '@/lib/kepler/sdk'
import Locate, {Position as LocationPosition} from '@/lib/location'
import {
  Dialog,
  InterventionParkingLot,
  InterventionRequest,
  Position,
  ReservationResponse,
} from '@/lib/kepler/interfaces'
import {Action, Getter} from 'vuex-class'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'

@Component({
  components: {
    VLayout,
    VFlex,
    VAlert,
    VSelect,
    VCard,
    VExpansionPanel,
    VExpansionPanelContent,
    VChip,
    VSpacer,
    VProgressLinear,
    VProgressCircular,
    VBtn,
    VIcon,
    Button: Utils.loadComponent('Button'),
    Plate: Utils.loadComponent('Plate'),
    LoopingBG: Utils.loadComponent('LoopingBG'),
  },
})
export default class Maintenance extends Vue {
  @Getter('defaultLocation') public defaultLocation!: { acc: null; lng: number; lat: number } | null
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('current') public current!: () => Promise<ReservationResponse[]>
  @Action('selectReservation') public selectReservation!: (r: ReservationResponse) => Promise<ReservationResponse>

  public selectedLot: InterventionParkingLot | null = null
  public selectedZone: string | null = null

  public position: Position = {latitude: 0, longitude: 0}
  public accuracy: number = 20

  public interventionLocations: InterventionParkingLot[] = []
  public interventionZones: string[] = []
  public response: InterventionRequest[] | null = []

  public loading: boolean = false
  public loadingText: string = ''
  public error: string = ''
  public warning: string = ''
  public noPos = false

  public icons = {
    ORDINARY_MAINTENANCE: {name: 'mdi-wrench-clock', color: ''},
    NO_RES_IN_DAYS: {name: 'mdi-clock-alert-outline', color: ''},
    ALMOST_EMPTY: {name: 'mdi-gas-station', color: 'warning'},
    BATTERY_EMPTY: {name: 'mdi-car-battery', color: 'error'},
    EV_PLUGGED_IN: {name: 'mdi-power-plug', color: 'success'},
    FUEL_UNDER_LIMIT_THRESHOLD: {name: 'mdi-gas-station', color: 'error'},
    RANGE_UNDER_LIMIT_THRESHOLD: {name: 'mdi-gauge-empty', color: 'error'},
    BACKOFFICE_DISABLE: {name: 'mdi-car-off', color: ''},
  }

  protected accuratePosition(maxWait: number = 1): Promise<Position> {
    return new Promise<Position>(((resolve, reject) => {
        let positionWatch: number
        let timeoutWatch: NodeJS.Timeout

        let skippedFirst = false

        const clean = () => {
          clearTimeout(timeoutWatch)
          navigator.geolocation.clearWatch(positionWatch)
        }

        const onError = (msg: string) => {
          clean()
          reject(msg)
        }

        const onSuccess = (position: Position) => {
          clean()
          resolve(position)
        }

        positionWatch = navigator.geolocation.watchPosition((position: GeolocationPosition) => {
          if (skippedFirst) {
            if (position.coords.accuracy <= this.accuracy) {
              onSuccess({latitude: position.coords.latitude, longitude: position.coords.longitude})
            }
          } else {
            skippedFirst = true
          }
        }, (error: GeolocationPositionError) => {
          onError('GPS_ERROR_' + JSON.stringify(error))
        }, {
          maximumAge: 0,
          timeout: maxWait * 1000,
          enableHighAccuracy: true,
        })

        timeoutWatch = setTimeout(() => {
          onError('TIMEOUT')
        }, maxWait * 1000)
      }
    ))
  }

  protected getPosition(): Promise<Position> {
    return new Promise((resolve) => {
      this.loadingText = 'getting location...'
      this.accuratePosition(3)
        .then((position) => {
          this.position = position
          resolve(position)
        })
        .catch(() => {
          Locate.locate((pos: LocationPosition) => {
              this.loadingText = 'accurate location unavailable, still working...'
              this.$log('got position: ' + JSON.stringify(pos), 1)
              const position: Position = {latitude: pos.lat, longitude: pos.lng}

              this.position = position
              resolve(position)
            },
            this.defaultLocation ? this.defaultLocation : {lat: 0, lng: 0, acc: null},
            () => {
              this.noPos = true
              const d = this.defaultLocation
              this.warning = 'cannot get precise position, ' + d ? 'using default' : 'no default position set'
              this.position = d ? {latitude: d.lat, longitude: d.lng} : {latitude: 0, longitude: 0}

              resolve(this.position)
            },
          )
        })
    })
  }

  @Watch('selectedLot', {deep: true})
  @Watch('selectedZone', {deep: true})
  protected onSelectionChange() {
    const latitude = this.position.latitude
    const longitude = this.position.longitude
    this.loading = true
    this.loadingText = 'updating data...'

    const parking_lot_id = this.selectedLot?.parking_lot.id
    const zone_name = this.selectedZone || undefined
    sdk.maintenance.get({latitude, longitude, parking_lot_id, zone_name}).then((r) => {
      this.response = r.data
    }).finally(() => {
      this.loading = false
      this.loadingText = ''
    })
  }

  protected onLotSelection(p: { plate: string }) {
    this.$log(p, 1)

    sdk.maintenance.take(p.plate).then((r) => {
      this.openDialog(new Dialog(ConfirmDialogCallback, {
        imageState: '',
        confirmText: this.$t('maintenance.confirmed.go_to_reservation'),
        cancelText: this.$t('maintenance.confirmed.stay_on_page'),
        code: null,
        title: this.$t('maintenance.confirmed.title'),
        subtitle: null,
        showCloseButton: false,
        confirmCallback: () => {
          this.selectReservation(r.data).then(() => {
            this.$router.push({name: 'reservation'})
          })
        },
        cancelCallback: () => this.request(true),
      }))
    }).finally()
  }

  protected request(alsoQueryInterventions: boolean = false) {
    const req = () => new Promise<void>((resolve, reject) => {
      this.getPosition().then((position) => {
        this.loadingText = 'getting data...'
        Promise.all([sdk.maintenance.parkingLots(position), sdk.maintenance.zones()]).then((result) => {
          this.interventionLocations = result[0].data as InterventionParkingLot[]
          this.interventionZones = result[1].data as string[]
          resolve()
        }).catch(reject)
      }).catch(() => {
        this.$log('cannot get position', 3)
        this.error = 'cannot get position, check if location is enabled'
        this.loading = false
      })
    })

    this.loading = true
    return req().finally(() => {
      if (alsoQueryInterventions) {
        this.onSelectionChange()
      } else {
        this.loadingText = ''
        this.loading = false
      }
    })
  }

  protected distanceString(d: number) {
    const str = ''
    if (this.$distance().toLowerCase() === 'km') {
      return str + ' ' + (d >= 1000 ? this.$distance(Math.round((d / 1000) * 10) / 10) : Math.floor(d) + 'm')
    } else {
      return str + ' ' + this.$distance(Math.round(d))
    }
  }

  protected init() {
    this.error = ''
    this.warning = ''
    this.request(true)
  }

  protected mounted() {
    this.init()
  }
}
