




























































import {Vue, Component, Watch} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'

import Utils from '@/utils'
import {ProfileState} from '@/store/modules/profile'
import {AppConfigState} from '@/store/modules/configuration'

import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import LoginDialog from '@/views/LoginDialog.vue'
import {LoginResponse, Dialog} from '@/lib/kepler/interfaces'
import {Account} from '@/store/modules/accounts'
import ConfirmDialog from '@/views/ConfirmDialog.vue'
import MapStyles from '@/lib/map/styles/MapStyles'
import ChangePasswordDialog from '@/views/ChangePasswordDialog.vue'
import TextareaDialog from '@/views/TextareaDialog.vue'
import sdk from '@/lib/kepler/sdk'

@Component({
  components: {
    Img: Utils.loadComponent('proxy/Image'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    CardText: Utils.loadComponent('proxy/Card/CardText'),
    CardButton: Utils.loadComponent('CardButton'),
    Button: Utils.loadComponent('Button'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    SelectTag: Utils.loadComponent('proxy/Inputs/SelectTag'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
  },
})
export default class Settings extends Vue {
  @Action('saveAccount') public saveAccount!: (p?: Account & { id: string }) => Promise<void>
  @State('profile') protected profileState!: ProfileState
  @State('configuration') protected configState!: AppConfigState
  @Action('setMapStyle') protected setMapStyle!: any
  @Action('closePopup') protected closePopup!: (index?: number) => void
  @Action('openDialog') protected openDialog!: (dialog: Dialog) => void
  @Action('setLang') protected setLang!: any
  @Action('addProfile') protected addProfile!: any
  @Action('closeOverlays') protected closeOverlays!: any
  @Action('logout') protected logout!: any
  @Action('closeDialog') protected closeDialog!: (index?: number) => void

  protected styleChanged: boolean = false
  protected loading: boolean = false
  protected lang: string = ''
  protected mapStyle: string = ''
  protected eec: number = 0

  protected get mapStyles() {
    return MapStyles.styleList.map((s) => {
      return {
        label: s.replace('_', ' '),
        value: s,
      }
    })
  }

  protected get selectValue() {
    switch (this.profileState.language) {
      case 'en':
        return 'us'
      case 'sc':
        return 'srd'
      case 'es':
        return 'es'
      case 'it':
        return 'it'
      case 'hu':
        return 'hu'
      case 'pt':
        return 'pt'
      default:
        return this.profileState.language ? this.profileState.language : this.configState.appConfig.default_language as string
    }
  }

  protected get supportUrl() {
    return this.configState.appConfig.support_center_url
  }

  protected isBrand(name: string) {
    return this.$env.PAGE_TITLE.toLowerCase() === name.toLowerCase()
  }

  protected mounted() {
    this.lang = this.selectValue
    this.mapStyle = this.profileState.mapStyle ? this.profileState.mapStyle : 'semiClassico'
    if (this.profileState.mapStyle === undefined) {
      this.setMapStyle(this.mapStyle).then()
    }
  }

  @Watch('mapStyle')
  protected onMapStyleChanged(val: string) {
    this.styleChanged = this.profileState.mapStyle !== val
  }

  protected addAccount() {
    const id = this.profileState.driver?.id
    if (id) {
      this.saveAccount().then(() => {
        this.openDialog(new Dialog(LoginDialog, {
          leaveOpen: true,
          callback: (r: LoginResponse) => {
            this.loading = true
            this.addProfile(r.token)
              .then(() => {
                this.closeOverlays()
                this.openDialog(new Dialog(ConfirmDialog, {
                  imageState: 'success.svg',
                  confirmText: this.$t('common.continue'),
                  code: '',
                  subtitle: this.$isAvailable('profile.switch.added.subtitle'),
                  title: this.$t('profile.switch.added.title'),
                  singleAction: true,
                  emitConfirm: false,
                }))
              })
              .finally(() => {
                this.loading = false
              })
          },
        }, null, true, null))
      })
    }
  }

  protected logoutUser() {
    this.logout().then(() => {
      this.$router.push({name: 'home'})
      this.closePopup()
    })
  }

  protected requestDeletion() {
    const t = (x: string) => this.$t('profile.settings.delete_account.' + x)
    this.openDialog(new Dialog(TextareaDialog, {
      title: t('title'),
      subtitle: t('subtitle'),
      confirmText: t('confirm'),
      confirmColor: 'error',
      confirmCallback: (text: string) => {
        sdk.profile.request_deletion(text).then(() => {
          this.openDialog(new Dialog(ConfirmDialogCallback, {
            title: t('confirmation.title'),
            subtitle: t('confirmation.subtitle'),
          }))
        })
      },
      cancelCallback: this.closeDialog,
    }))
  }

  protected saveSettings() {
    this.loading = true
    this.setMapStyle(this.mapStyle).then(() => {
      this.loading = false
      location.reload()
    })
  }

  protected changeLang(lang: string) {
    if (lang === 'us') {
      lang = 'en'
    } else if (lang === 'srd') {
      lang = 'sc'
    }
    this.setLang(lang)
    this.$langLoader(lang)
  }

  protected openSupport() {
    cordova.InAppBrowser.open(this.supportUrl, '_system')
  }

  protected openTocs() {
    this.closePopup()
    this.$router.push({name: 'terms'})
  }

  protected openChangePassword() {
    this.openDialog(new Dialog(ChangePasswordDialog, {}))
  }

  protected ee() {
    if (this.eec < 25) {
      this.eec++
    } else {
      this.openDialog(new Dialog(ConfirmDialogCallback, {
        imageState: null,
        code: null,
        title: null,
        subtitle: 'tapping on random numbers doesn\'t make you a developer',
        cancelText: 'oh no',
        confirmText: 'oh yes',
        emitConfirm: false,
        showCloseButton: false,
        confirmColor: 'error',
        confirmCallback: () => {
          this.$router.push('/dev')
        },
      }))
      this.eec = 0
    }
  }
}
