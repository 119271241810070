









import {Component, Prop} from 'vue-property-decorator'
import {EventBus} from '@/main'
import Utils from '@/utils'
import ConfirmDialog from '../ConfirmDialog.vue'

@Component({
  components: {
    CheckBox: Utils.loadComponent('proxy/Inputs/CheckBox'),
    Button: Utils.loadComponent('Button'),
    ConfirmDialog,
  },
})
export default class ForceTerminateDialog extends ConfirmDialog {
  @Prop() public checkBoxLabel!: string
  @Prop() public terminateMessages!: string[]

  protected undesiderableCheck: boolean = false
  protected forbiddenCheck: boolean = false

  protected boxChecked: boolean = false

  protected get cost() {
    if (this.terminateMessages?.length > 1) {
      return this.$currency(Math.abs(parseInt(this.terminateMessages[1], 10)))
    }
  }

  protected confirm() {
    EventBus.$emit('userForcedTerminate')
    this.closeDialog()
  }
}
