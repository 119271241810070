
















import {Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'
import {ReservationResponse, CheckInOut, BookTimeSelectRequest} from '@/lib/kepler/interfaces'
import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import moment from 'moment'

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    RangeEstimate: Utils.loadComponent('rangeSelector/rangeEstimate'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    CloseButton: Utils.loadComponent('CloseButton'),
    VehicleQuickSummary: Utils.loadComponent('entities/vehicle/VehicleQuickSummary'),
    Divider: Utils.loadComponent('proxy/Divider'),
    CheckInOutPicker: Utils.loadComponent('CheckInOutPicker'),
    Button: Utils.loadComponent('Button'),
  },
  name: 'BookingEdit',
})
export default class BookingEdit extends ConfirmDialogCallback {
  @Prop() public reservation!: ReservationResponse

  protected editRequest: CheckInOut = {
    checkOutTime: '',
    checkOutDate: '',
    checkInDate: '',
    checkInTime: '',
  }
  protected estimateRequest: BookTimeSelectRequest | null = null

  protected get confirmDisabled(): boolean {
    if (!this.estimateRequest) {
      return true
    }
    const empty: boolean = this.reservation.start === null || this.reservation.end === null
    const start = moment((this.reservation as any).start).unix()
    const end = moment((this.reservation as any).end).unix()
    const startUx = this.reservation.start_timestamp
    const endUx = this.reservation.end_timestamp
    return (start === startUx) && (end === endUx) || empty
  }

  protected pupulateFields(val: any) {
    this.reservation.start_timestamp = val.start
    this.reservation.end_timestamp = val.end

    this.$set(this, 'estimateRequest', {...val})
  }

  private created() {
    if (this.reservation.start_timestamp && this.reservation.end_timestamp) {
      const start = moment.unix(this.reservation.start_timestamp)
      const end = moment.unix(this.reservation.end_timestamp)

      this.editRequest.checkInDate = start.format('YYYY-MM-DD')
      this.editRequest.checkOutDate = end.format('YYYY-MM-DD')
      this.editRequest.checkInTime = start.format('LT')
      this.editRequest.checkOutTime = end.format('LT')
    }
  }
}
