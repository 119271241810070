




































import {Vue, Component, Prop, VModel} from 'vue-property-decorator'
import {State} from 'vuex-class'

import {VCard, VLayout} from 'vuetify/lib'
import {ValidationProvider} from 'vee-validate'
import Utils from '@/utils'
import moment from 'moment'

interface OuterValue {
  start: number | null,
  end: number | null
}

@Component({
  components: {
    GradientCard: Utils.loadComponent('GradientCard'),
    DateTimeField: Utils.loadComponent('fields/DateTimeField'),
    VLayout,
    VCard,
    ValidationProvider,
  },
  name: 'DateTimeRangeField',
})
export default class DateTimeRangeField extends Vue {
  @State((state) => state.configuration.appConfig?.time_slot_dimension || 30) public tsd!: number

  @Prop({
    type: [String, Object],
    default: '',
  }) public readonly rules!: string | Record<string, any>

  @Prop({
    type: String,
    required: false,
  }) public name?: string
  @Prop({
    type: String,
    required: false,
  }) public description?: string
  @Prop({
    type: Boolean,
    default: false,
  }) public disabled?: boolean

  @VModel({
    type: Object,
    default: null,
  }) public range!: OuterValue | null

  public get start() {
    return this.range?.start || null
  }
  public set start(v: number | null) {
    const obj: OuterValue = this.range ? JSON.parse(JSON.stringify(this.range)) : {start: null, end: null}
    obj.start = v
    this.range = obj
  }

  public get end() {
    return this.range?.end || null
  }
  public set end(v: number | null) {
    const obj: OuterValue = this.range ? JSON.parse(JSON.stringify(this.range)) : {start: null, end: null}
    obj.end = v
    this.range = obj
  }

  public uniformDown(timestamp: number) {
    const rest = timestamp % (this.tsd * 60)
    return rest !== 0 ? timestamp - rest : timestamp
  }

  public get boundaries() {
    /* tslint:disable: cyclomatic-complexity */ // WHY U MAD?

    const getMoment = (x: 'before' | 'after') => {
      switch (typeof this.rules) {
        case 'object':
          const t = moment(this.rules[x] || null)
          return t.isValid() ? t : null
        default:
          const m = moment(this.rules.split('|').find((s) => s.includes(x)) || null)
          return m.isValid() ? m : null
      }
    }
    const cycleSeconds = this.tsd * 60

    let a: moment.Moment | number | null = getMoment('after')
    if (a === null) {
      a = this.uniformDown(moment().unix()) - 1 // minus one second so that the current slot is selectable
    } else {
      a = a.unix()
    }

    const after: number = a || 0
    const before = getMoment('before')?.unix() || 0

    return {
      start: {
        min: after || null,
        max: Math.max(before - cycleSeconds, 0) || null,
      },
      end: {
        min: Math.max(after, this.start || 0) || null,
        max: before || null,
      },
    }
  }
}
