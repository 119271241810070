














import {Component, Prop, Vue} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import Utils from '../../utils'
import {BookRequest, ReservationResponse, VehicleSlot, Popup} from '@/lib/kepler/interfaces'
import VehicleConfirmBooking from './VehicleConfirmBooking.vue'
import IsLogged from '../../components/IsLogged.vue'
import CustomIcon from '../../components/CustomIcon.vue'
import FormTag from '../../components/proxy/Inputs/FormTag.vue'

@Component({
  components: {
    FormTag,
    CustomIcon,
    IsLogged,
    VehicleConfirmBooking,
    VehicleConfirmImage: Utils.loadComponent('entities/vehicle/VehicleConfirmImage'),
    Button: Utils.loadComponent('Button'),
    Container: Utils.loadComponent('proxy/Container'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
  },
  name: 'VehicleBookingAddMemo',
})
export default class VehicleBookingAddMemo extends Vue {

  @Action('book') public bookAction!: (req: BookRequest) => Promise<ReservationResponse>
  @Action('closePopup') public closePopup!: (index?: number) => void
  @Action('openPopup') public openPopup!: (popup: Popup) => void

  @Prop() public vehicleSlot!: VehicleSlot
  @Prop({
    required: true,
    default: () => {
      return {
        start: null,
        end: null,
        plate: null,
        memo: null,
      }
    },
  }) public bookRequest!: BookRequest

  public loading: boolean = false

  public sendBooking() {
    if (!this.loading) {
      this.loading = true
      this.bookAction(this.bookRequest)
        .then(() => {
          this.loading = false
          this.closePopup()
          this.openPopup(new Popup(VehicleConfirmBooking, {vehicleSlot: this.vehicleSlot}, null, true))
        })
        .catch(() => {
          this.loading = false
          this.closePopup()
        })
    }
  }
}
