


















import {Vue, Component, Prop} from 'vue-property-decorator'

import DateHelper from '@/lib/DateHelper'
import {EventBus} from '@/main'
import {Action} from 'vuex-class'
import {ReservationResponse} from '@/lib/kepler/interfaces'
import ServiceMesh from '@/lib/serviceMesh'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'
import {VSpacer} from 'vuetify/lib'
import Utils from '@/utils'

@Component({
  components: {
    VSpacer,
    Plate: Utils.loadComponent('Plate'),
    Img: Utils.loadComponent('proxy/Image'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Chip: Utils.loadComponent('proxy/Chip'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Toolbar: Utils.loadComponent('proxy/Toolbar'),
    CircleStatus: Utils.loadComponent('CircleStatus'),
    GradientCard: Utils.loadComponent('GradientCard'),
    ToolbarTitle: Utils.loadComponent('proxy/ToolbarTitle'),
    ProgressLinear: Utils.loadComponent('proxy/ProgressLinear'),
  },
  name: 'ActiveReservationBar',
})
export default class ActiveReservationBar extends Vue {
  @Action('selectReservation') public selectReservation!: (r: ReservationResponse) => Promise<ReservationResponse>
  @Action('current') public current!: any

  @Prop() protected reservation!: any
  @Prop({type: Boolean, default: false}) protected hideChevron!: boolean

  protected progress: number = 0
  protected timeLeft: string = ''

  get vehicleName() {
    return `${this.reservation.vehicle_slot.vehicle.brand} ${this.reservation.vehicle_slot.vehicle.model}`
  }

  protected get typeColor() {
    const key = `${this.reservation.vehicle_slot.reservation_type}${this.reservation.vehicle_slot.vehicle.category.type}`.toUpperCase()
    return ServiceMesh.colors[key]
  }

  protected get isLate() {
    return this.progress > 100
  }

  protected get standardColor() {
    return this.isLate ? VuetifyColorHelper.color('error') : VuetifyColorHelper.color('success')
  }

  protected mounted() {
    this.updateProgress(this.reservation)
    EventBus.$on('cron1', () => {
      this.current().then(() => {
        this.updateProgress(this.reservation)
      })
    })
  }

  protected destroyed() {
    EventBus.$off('cron1')
  }

  protected updateProgress(reservation: ReservationResponse) {
    const timeStart: any = reservation.start_timestamp
    const timeEnd: any = reservation.end_timestamp
    const now = new Date().getTime() / 1000
    const totalHours = (timeEnd - timeStart) / 3600
    const hoursSpent = (now - timeStart) / 3600
    this.progress = 100 / totalHours * hoursSpent

    if (reservation.vehicle_slot.reservation_type === 'RT') {
      this.timeLeft = DateHelper.getTimeOffset(now, timeEnd).formatTimeOffset('dhm')
      return
    }

    this.timeLeft = DateHelper.getTimeOffset(timeStart, new Date()).formatTimeOffset('dhm')
  }

  protected openReservationDetail() {
    this.selectReservation(this.reservation)
    this.$router.push({name: 'reservation'})
  }
}
