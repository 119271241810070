import {Component, Prop, Vue} from 'vue-property-decorator'
import theme, {customClasses, customCss, gradientAngle} from '@/lib/vuetify/theme'
import {SubscriptionPlan} from '@/lib/kepler/interfaces'
import {VNodeData} from 'vue/types/vnode'

interface Theme {
  [key: string]: string
}

export default class VuetifyColorHelper {
  public static theme(): Theme {
    return theme ? theme : Vue.prototype.$branding.theme
  }

  public static color(c: string) {
    const t = this.theme()
    return t[c] !== undefined ? t[c] : c
  }

  public static isCssColor(color?: string | false): boolean {
    return !!color && !!color.match(/^(#|(rgb|hsl)a?\()/)
  }

  public static setTextColor(color?: string | false, data: VNodeData = {}): VNodeData {
    if (this.isCssColor(color) && typeof data.style === 'object') {
      data.style = {
        ...data.style,
        'color': `${color}`,
        'caret-color': `${color}`,
      }
    } else if (color) {
      const [colorName, colorModifier] = color.toString().trim().split(' ', 2) as Array<string | undefined>
      data.class = {
        ...data.class,
        [colorName + '--text']: true,
      }
      if (colorModifier) {
        data.class['text--' + colorModifier] = true
      }
    }
    return data
  }

  public static setBackgroundColor(color?: string | false, data: VNodeData = {}): VNodeData {
    if (this.isCssColor(color) && typeof data.style === 'object') {
      data.style = {
        ...data.style,
        'background-color': `${color}`,
        'border-color': `${color}`,
      }
    } else if (color) {
      data.class = {
        ...data.class,
        [color]: true,
      }
    }
    return data
  }

  public static css(c: string) {
    return Object.keys(customCss).includes(c) ? customCss[c] : null
  }

  public static gradientAngle(c: string) {
    return Object.keys(gradientAngle).includes(c) ? gradientAngle[c] : null
  }

  public static classes(c: string) {
    return Object.keys(customClasses).includes(c) ? customClasses[c] : null
  }

  public static parseColor(color: string | number) {
    const memoize = function (factory: (col: any) => number[], ctx?: CanvasRenderingContext2D) {
      const cache: {[index: string]: number[]} = {}
      return function (key: string | number) {
        if (!(key in cache)) {
          cache[key] = factory.call(ctx, key)
        }
        return cache[key]
      }
    }

    const colorToRGBA = (function () {
      const canvas = document.createElement('canvas')
      canvas.width = canvas.height = 1
      const ctx = canvas.getContext('2d')!
      // invalid values of "col" are ignored
      return memoize(function (col) {
        ctx.clearRect(0, 0, 1, 1)
        ctx.fillStyle = col
        ctx.fillRect(0, 0, 1, 1)
        return [...ctx!.getImageData(0, 0, 1, 1).data]
      })
    })()

    return {
      r: colorToRGBA(color)[0],
      g: colorToRGBA(color)[1],
      b: colorToRGBA(color)[2],
      a: colorToRGBA(color)[3],
    }
  }

  public static averageColors = (color1: string | number, color2: string | number) => {
    const c1 = VuetifyColorHelper.parseColor(color1)
    const c2 = VuetifyColorHelper.parseColor(color2)
    return {
      r: (c1.r + c2.r)/ 2,
      g: (c1.g + c2.g)/ 2,
      b: (c1.b + c2.b)/ 2,
      a: (c1.a + c2.a)/ 2,
    }
  }

  public static lightContrast = (color1: string | number, color2?: string | number) => {
    const rgb = color2 ? VuetifyColorHelper.averageColors(color1, color2) : VuetifyColorHelper.parseColor(color1)!
    const luminance = (0.2126 * rgb['r'] + 0.7152 * rgb['g'] + 0.0722 * rgb['b'])
    return (luminance > 167)
  }
}

@Component
export class CustomColorableMixin extends Vue {
  @Prop({type: String}) protected customCSS!: string | null
  @Prop({type: String, default: () => ''}) protected colorTop?: string
  @Prop({type: String, default: () => ''}) protected colorBottom?: string
  @Prop({
    default: 180,
    type: Number,
  }) protected angle?: number | string
  @Prop({type: Boolean, default: false}) protected ripple?: boolean
  @Prop({type: Boolean}) protected light?: boolean
  @Prop({type: Boolean}) protected dark?: boolean
  @Prop({type: Boolean, default: true}) protected flat?: boolean

  protected get customCss() {
    if (this.customCSS) {
      const c = VuetifyColorHelper.css(this.customCSS)
      if (c) {
        if (window.cordova.platformId === 'ios' && c['background-attachment']) {
          c['background-attachment'] = 'scroll' // Workaround for iOs
        }
        return c
      }
    }
    return null
  }

  protected get customStyle() {
    const arr = []
    if (this.colorTop && this.colorBottom) {
      arr.push({
        'background-image': `linear-gradient(${this.gradientAngle || this.angle}deg, ${this.color(this.colorTop)}, ${this.color(this.colorBottom)})`,
      })
    } else {
      arr.push({
        background: VuetifyColorHelper.color('primary'),
      })
    }
    if (this.customCss) {
      arr.push(this.customCss)
    }
    return arr
  }

  protected get customClasses() {
    return this.themeName ? VuetifyColorHelper.classes(this.themeName) : null
  }

  protected get gradientAngle() {
    if (this.themeName && VuetifyColorHelper?.gradientAngle(this.themeName)) {
      return VuetifyColorHelper.gradientAngle(this.themeName)
    }
    return this.angle || null
  }

  protected get themeName() {
    if (typeof this.customCSS === 'string') {
      return this.customCSS
    }
    if (this.colorTop?.includes('Top')) {
      return this.colorTop?.replace('Top','')
    }
    if (this.colorBottom?.includes('Bottom')) {
      return this.colorBottom?.replace('Bottom','')
    }
    return null
  }

  protected get contrastOverride(): boolean | null {
    if (this.themeName) {
      const c = VuetifyColorHelper.color(this.themeName + 'ContrastOverride')
      if (!c.includes('ContrastOverride')) {
        switch (c) {
          case 'light':
            return true
          case 'dark':
            return false
          default:
            return c ? VuetifyColorHelper.lightContrast(c) : null
        }
      }
    }
    return null
  }

  protected get isLight() {
    const top = this.colorTop ? this.color(this.colorTop) : null
    const bottom = this.colorBottom ? this.color(this.colorBottom) : null
    if (this.customClasses?.includes('theme--dark')) {
      return false
    }
    if (this.customClasses?.includes('theme--light')) {
      return true
    }
    if (this.contrastOverride !== null) {
      return this.contrastOverride
    } else if (top && bottom) {
      return VuetifyColorHelper.lightContrast(top, bottom)
    }
    return !this.$vuetify.dark
  }

  protected color(c: string) {
    if (VuetifyColorHelper.color(c) !== c) {
      return VuetifyColorHelper.color(c)
    } else if (c.startsWith('#')) {
      return c
    } else {
      return null
    }
  }
}

@Component
export class PlanColorMixin extends Vue{
  public getPlanColors(plan: SubscriptionPlan, isDefault: boolean ) {
    const hasDefault = (str: string) => VuetifyColorHelper.color('planDefault' + str) !== 'planDefault' + str

    const getColor = (str: string) => {
      if (isDefault && hasDefault(str)) {
        return VuetifyColorHelper.color('planDefault' + str)
      } else {
        return VuetifyColorHelper.color('plan' + str)
      }
    }
    const obj = {
      top: getColor('Top'),
      bottom: getColor('Bottom'),
    }
    if (plan?.color) {
      const c = plan.color.split('|')
      obj.top = c[0]
      obj.bottom = c[1] ? c[1] : c[0]
    }
    return obj
  }

  public getPlanCSS(isDefault: boolean) {
    return isDefault? 'planDefault' : 'plan'
  }
}
