
















import {Component, Prop} from 'vue-property-decorator'
import Button from './Button.vue'

@Component({
  components: {
    Button,
  },
  name: 'ToggleableButton',
})
export default class ToggleableButton extends Button {
  @Prop({
    type: Boolean,
    default: true,
  }) protected value!: boolean
  @Prop({
    default: 'secondary',
  }) protected borderColor?: string
  protected styleObj?: object = {}
  @Prop({
    type: Boolean,
    default: true,
  }) private outline?: boolean

  protected toggle() {
    this.$emit('input', !this.value)
  }

  protected get colorName() {
    if (this.color!.startsWith('#')) {
      this.styleObj = {
        'background-color': this.color,
        'border-color': this.value ? this.color : this.borderColor,
        'color': this.value ? 'white' : this.borderColor,
      }
    } else {
      return this.color
    }
  }
}
