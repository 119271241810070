














import {Component, Prop, Vue} from 'vue-property-decorator'
import Utils from '@/utils'
import Layout from '@/components/proxy/Layout.vue'
import {State} from 'vuex-class'
import Avatar from '@/components/proxy/Avatar.vue'

@Component({
  name: 'MapFiltersButton',
  components: {
    Avatar,
    Layout,
    Container: Utils.loadComponent('proxy/Container'),
    Btn: Utils.loadComponent('proxy/Btn'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
  },
})
export default class MapFiltersButton extends Vue {
  @State(
    (state) => state.configuration.appConfig?.fields_configuration?.filter_menu_style,
  ) public filterMenuStyle?: string

  @Prop() public showFilters?: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public showBottomNav!: boolean

  protected get isHome() {
    return this.$route.name === 'home'
  }

  protected get buttonClass() {
    let cls = ''
    if (!!this.imageMenu) {
      cls = `image-button ${this.imageMenu.class || ''}`
    }
    if (!!this.labeledMenu) {
      cls = `text-button ${this.labeledMenu.class || ''}`
    }
    if (!!this.iconMenu) {
      cls = `icon-button ${this.iconMenu.class || ''}`
    }
    return cls.trim()
  }

  protected get imageMenu() {
    // example config string: image|src=img/logo.png|class=accent--text
    if (this.filterMenuStyle?.startsWith('image')) {
      const obj: { [key: string]: string } = {src: 'img/icons/android-chrome-192x192.png', class: 'accent--text'}
      this.filterMenuStyle?.split('|').forEach((s) => {
        const prop = s.split('=')
        if (prop.length > 1) {
          obj[prop[0]] = prop[1]
        }
      })
      return obj
    }
    return null
  }

  protected get labeledMenu() {
    // example config string: "labeled|class=v-btn--left font-weight-bold accent--text|icon="
    if (this.filterMenuStyle?.startsWith('labeled')) {
      const obj: { [key: string]: string } = {icon: 'mdi-filter-variant', label: this.$t('map.filters.title')}
      this.filterMenuStyle?.split('|').forEach((s) => {
        const prop = s.split('=')
        if (prop.length > 1) {
          obj[prop[0]] = prop[1]
        }
      })
      return obj
    }
    return null
  }

  protected get iconMenu() {
    // example config string: icon|icon=mdi-filter
    if (this.filterMenuStyle?.startsWith('icon') || this.filterMenuStyle === undefined) {
      const obj: { [key: string]: string } = {icon: 'mdi-filter-variant'}
      this.filterMenuStyle?.split('|').forEach((s) => {
        const prop = s.split('=')
        if (prop.length > 1) {
          obj[prop[0]] = prop[1]
        }
      })
      return obj
    }
    return null
  }
}
