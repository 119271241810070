









import {Vue, Component, Prop} from 'vue-property-decorator'
import {
  DamagePosition,
  DamagePoints as Dmp,
  VehicleSlot,
  Report,
} from '@/lib/kepler/interfaces'
import {Action} from 'vuex-class'
import {AxiosPromise} from 'axios'
import {EventBus} from '@/main'

@Component({
  components: {},
  name: 'VehicleDamageAll',
})
export default class VehicleDamageAll extends Vue {
  @Prop({type: Object}) public vehicleSlot!: VehicleSlot
  @Action('diaryReports') private diaryReports!: (id: string) => AxiosPromise<Report[]>

  private points: Dmp[] = []
  private reports: Report[] = []

  private created() {
    EventBus.$on('sentReport', this.loadReports)
  }

  private beforeDestroyed() {
    EventBus.$off('sentReport')
  }

  private loadReports() {
    this.diaryReports(this.vehicleSlot.vehicle.id).then((r) => {
      this.reports = r.data
      this.getDamagePoints()
    })
  }

  private getDamagePoints() {
    const carImg = this.$refs.carOutline as Element
    if (carImg) {
      this.reports.forEach((item) => {
        if (item.damage_position_raw) {
          item.damage_position_raw.forEach((dmg: DamagePosition) => {
            this.points.push({
              title: item.id,
              left: ((dmg.left) * carImg.clientWidth) / dmg.containerWidth,
              top: ((dmg.top) * carImg.clientHeight) / dmg.containerHeight,
            })
          })
        }
      })
    }
  }
}
