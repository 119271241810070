





































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {State} from 'vuex-class'
import {Address, AppConfig, FlowInputField, Pos} from '@/lib/kepler/interfaces'
import CountryList from '@/lib/kepler/countries'
import countries from '@/lib/kepler/countries'
import {debounce} from '@/lib/Debounce'
import {VAutocomplete, VLayout} from 'vuetify/lib'
import Utils from '@/utils'

@Component({
  components: {
    VAutocomplete,
    VLayout,
  },
  name: 'RegistrationAddressComponent',
})
export default class RegistrationAddressComponent extends Vue {
  // @State('registration') public registrationState!: {[k: string]: any}
  @State('flowOutputs') public flowOutputs!: { [k: string]: any }

  @Prop({
    type: String,
  }) public context!: string
  @Prop({
    type: [String, Object],
    default: '',
  }) public readonly rules!: string | object

  @State((state) => state.profile.userPosition) public position!: Pos | null
  @State((state) => state.configuration.appConfig) public appConfig!: AppConfig
  @Prop() public field!: FlowInputField

  public required: boolean = false
  public valid: boolean = false
  public loading: boolean = false
  public errorMessages: string[] = []
  public google: typeof google | boolean = false
  public autoComplete: google.maps.places.AutocompleteService | null = null
  public countries: any = CountryList

  public details: google.maps.places.PlaceResult | null = null

  public req: google.maps.places.AutocompletionRequest = {
    input: '',
    radius: 10000,
    types: ['address'],
  }

  public places: google.maps.places.AutocompletePrediction[] = []

  public address: google.maps.places.AutocompletePrediction | null = null

  public map: google.maps.Map | null = null
  public placesService: google.maps.places.PlacesService | null = null

  public get currentFlow() {
    return this.flowOutputs[this.context]
  }

  public get addressOutput() {
    return this.currentFlow?.address
  }

  public set addressOutput(payload) {
    this.$set(this.currentFlow, 'address', payload)
  }

  public get location(): google.maps.LatLng | undefined {
    const [lat, lng] = [this.appConfig.default_latitude, this.appConfig.default_longitude]
    if (this.position) {
      return new google.maps.LatLng(this.position.lat, this.position.lng)
    } else if (lat && lng) {
      return new google.maps.LatLng(lat, lng)
    }
  }

  private get mapUrl() {
    const proxyUrl = this.$env.GOOGLE_MAP_PROXY_URL
    const apiKey = this.$env.GOOGLE_MAPS_KEY
    return proxyUrl && proxyUrl !== '' ? proxyUrl : `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places,visualization`
  }

  public search(req: google.maps.places.AutocompletionRequest) {
    if (req.input && this.autoComplete) {
      this.loading = true
      req.location = this.location
      this.autoComplete.getPlacePredictions(req, (data: google.maps.places.AutocompletePrediction[]) => {
        if (data !== null) {
          this.places = data || []
          this.$set(this, 'places', data)
          this.loading = false
        }
      })
    } else {
      this.$set(this, 'places', [])
    }
  }

  @Watch('req', {deep: true})
  public onSearch(req: google.maps.places.AutocompletionRequest) {
    if (req.input && req.input.length > 5) {
      this.$emit('autocompleteSearchInput', req)
    }
  }

  @Watch('address', {deep: true})
  public onAddressSelected(addr: google.maps.places.AutocompletePrediction) {
    this.map = this.map || new google.maps.Map(this.$refs.map as Element)
    this.placesService = this.placesService || new google.maps.places.PlacesService(this.map)
    if (!addr || this.placesService === null) {
      return
    }
    const req: google.maps.places.PlaceDetailsRequest = {
      // fields: ['address_components', 'adr_address', 'formatted_address'],
      fields: ['address_components'],
      placeId: addr.place_id,
    }
    this.placesService.getDetails(req, (result: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus) => {
      this.details = result
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        throw new Error('get details error')
      }
      if (result.address_components) {
        this.setAddressComponentProps(this.parseAddressComponents(result.address_components))
      }
      if (result.adr_address) {
        this.parseAdrAddress(result.adr_address)
      }
    })
  }

  public parseAdrAddress(adr_address?: string) {
    const address = Utils.getProp(this.currentFlow, this.field.name.split('.'))
    if (adr_address && address) {
      const el = document.createElement('html')
      const getProp = (propName: string) => {
        const arr = el.getElementsByClassName(propName)
        if (arr.length) {
          return arr[0].innerHTML.toString()
        }
        return ''
      }

      el.innerHTML = adr_address

      address.street = getProp('street-address')
      address.postal_code = getProp('postal-code') || '--'
      address.city = getProp('locality') || getProp('extended-address') || '--'
      address.region = getProp('region') || '--'

      const country = countries.find((c) => {
        return c.name === getProp('country-name')
      })

      if (country) {
        address.country = country.code
      }

      return address
    }
    return false
  }

  public parseAddressComponents(addressComponents: google.maps.GeocoderAddressComponent[]) {
    // https://medium.com/@almestaadmicadiab/how-to-parse-google-maps-address-components-geocoder-response-774d1f3375d
    const ShouldBeComponent: { [k: string]: string[] } = {
      street_number: ['street_number'],
      postal_code: ['postal_code'],
      street: ['street_address', 'route'],
      region: [
        'administrative_area_level_1',
        'administrative_area_level_2',
        'administrative_area_level_3',
        'administrative_area_level_4',
        'administrative_area_level_5',
      ],
      province: [
        'administrative_area_level_2',
        'administrative_area_level_3',
        'administrative_area_level_4',
        'administrative_area_level_5',
      ],
      city: [
        'locality',
        'sublocality',
        'sublocality_level_1',
        'sublocality_level_2',
        'sublocality_level_3',
        'sublocality_level_4',
      ],
      country: ['country'],
    }

    const address: Address = {
      street_number: '',
      postal_code: '',
      street: '',
      region: '',
      province: '',
      city: '',
      country: '',
    }
    addressComponents.forEach((component) => {
      for (const shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === 'country' || shouldBe === 'province') {
            address[shouldBe] = component.short_name
          } else {
            address[shouldBe] = component.long_name
          }
        }
      }
    })
    // if (address.hasOwnProperty('street_number')) {
    //   address.street = (address.street + ` ${address.street_number}`).trim()
    //   delete address.street_number
    // }
    if (address.country === 'IT') {
      address.region = address.province
      delete address.province
    }
    return address
  }

  public setAddressComponentProps(r: Address) {
    const strNumbErrIdx = this.errorMessages.indexOf(this.$t('registration.address.street_number_error'))

    const addressOut = Utils.getProp(this.currentFlow, this.field.name.split('.'))
    const setProp = (key: string, value: string) => {
      Utils.setProp(addressOut, [key], value)
    }

    for (const key of Object.keys(r)) {
      if (key === 'street') { // LET THE JANK BEGIN
        if (r.street_number) {
          if (this.$currentLang() === 'en') {
            setProp(key, `${r.street_number} ${r.street}`)
          } else {
            setProp(key, `${r.street} ${r.street_number}`)
          }

          if (strNumbErrIdx >= 0) {
            this.errorMessages.splice(strNumbErrIdx, 1)
          }
          continue
        } else if (strNumbErrIdx < 0) {
          this.errorMessages.push(this.$t('registration.address.street_number_error'))
        }
      }
      setProp(key, r[key])
    }
  }

  protected created() {
    if (!this.currentFlow) {
      this.$set(this.flowOutputs, this.context, {})
    }
    if (!Utils.getProp(this.currentFlow, this.field.name.split('.'))) {
      Utils.setProp(this.currentFlow, this.field.name.split('.'), {})
    }
    this.createGoogleMaps().then(() => {
      this.google = window.google
      this.autoComplete = new google.maps.places.AutocompleteService()
    })
  }

  protected mounted() {
    this.$on('autocompleteSearchInput', debounce(this.search, 300))
  }

  protected createGoogleMaps(): Promise<unknown> {
    return new Promise<void | Event>((resolve, reject) => {
      if (document.getElementById('google-map-loader') === null) {
        const gmap = document.createElement('script')
        gmap.setAttribute('id', 'google-map-loader')
        gmap.src = this.mapUrl
        gmap.type = 'text/javascript'
        gmap.onload = resolve
        gmap.onerror = reject
        document.body.appendChild(gmap)
      } else {
        resolve()
      }
    })
  }

  protected beforeDestroy() {
    this.$off('autocompleteSearchInput')
  }

  protected getAddressData(e: any) {
    this.address = e
  }
}
