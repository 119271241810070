






import {Component, Vue} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import {Dialog, HomeModeEnum, Popup} from '@/lib/kepler/interfaces'

import Map from '@/views/Map.vue'
import CorporateActivities from '@/views/CorporateActivities.vue'
import ListingHomeMode from '@/views/ListingHomeMode.vue'
import HomeMenu from '@/components/HomeMenu.vue'

@Component({
  components: {
    HomeMenu,
  },
  name: 'HomeMode',
})
export default class HomeMode extends Vue {
  @State((state) => state.configuration.appConfig.fields_configuration) public fieldConfigState!: any
  @State((state) => state.configuration.homeMode) public homeMode!: HomeModeEnum
  @State('popupState') public popupState!: Popup[]
  @State('dialogState') public dialogState!: Dialog[]

  @Getter('popupIsOpen') public popupIsOpen!: boolean

  @Action('setHomeMode') public setHomeMode!: (mode: HomeModeEnum) => void

  public get mode() {
    switch (this.homeMode) {
      case HomeModeEnum.mission:
        return {component: CorporateActivities, key: 'home_mission'}
      case HomeModeEnum.map:
        return {component: Map, key: 'home_map'}
      case HomeModeEnum.listing:
      default:
        return {component: ListingHomeMode, key: 'home_listing'}
    }
  }

  protected get showBottomNav() {
    return !this.popupIsOpen && this.$route.name ? ['home', 'activities', 'history'].includes(this.$route.name) : false
  }

  public created() {
    const conf = this.fieldConfigState.app_mode
    const env = this.$env.FORCE_MAP_MODE
    const empty = !(conf || env)
    if (empty && this.homeMode !== HomeModeEnum.map) {
      this.setHomeMode(HomeModeEnum.map)
    }
    if (env) {
      this.setHomeMode(env as HomeModeEnum)
      return
    }
    if (conf && conf !== this.homeMode) {
      this.setHomeMode(conf)
    }
  }

  public switchHomeMode(mode?: HomeModeEnum) {
    this.setHomeMode(mode ? mode : HomeModeEnum.map)
  }

}
