















































import {Component, Vue} from 'vue-property-decorator'
import ServiceMesh from '../lib/serviceMesh'
import {Action, State} from 'vuex-class'
import Utils from '@/utils'
import {BookingState} from '@/store/modules/booking'

import VehicleQrUnlock from './Vehicle/VehicleQrUnlock.vue'
import Icon from '@/components/proxy/Icon.vue'
import DateHelper from '@/lib/DateHelper'
import moment from 'moment'
import Sheet from '@/components/proxy/Sheet.vue'
import IsLogged from '@/components/IsLogged.vue'
import Btn from '@/components/proxy/Btn.vue'
import {ProfileState} from '@/store/modules/profile'
import {BookingMode, Popup, VehicleType} from '@/lib/kepler/interfaces'

@Component({
  components: {
    Btn,
    IsLogged,
    Sheet,
    Icon,
    ActiveReservationCard: Utils.loadComponent('entities/reservation/ActiveReservationCard'),
    LoopingBG: Utils.loadComponent('LoopingBG'),
    Layout: Utils.loadComponent('proxy/Layout'),
    GradientCard: Utils.loadComponent('GradientCard'),
    Container: Utils.loadComponent('proxy/Container'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    VehicleQrUnlock,
  },
})

export default class CorporateActivities extends Vue {
  @State((state) => state.booking) public bookingState!: BookingState
  @State((state) => state.profile) public profileState!: ProfileState
  @State((state) => state.booking.fakeTerminatedReservations) public markedReservations!: number[]
  @Action('openPopup') public openPopup!: (popup: Popup) => void

  protected get crtMesh() {
    return this.bookingState.meshes.find((m) => {
      return m.booking_mode === 'RT'
    })
  }

  protected get futures() {
    return this.bookingState.futureReservations.filter((res) => {
      const start = res.start_timestamp
      if (start) {
        return start > DateHelper.getTimestamp()
      }
    })
  }

  protected get nextMission() {
    const startArr = this.futures.map((res) => {
      return res.start_timestamp || Infinity
    })
    const nextMissionDate = moment.unix(Math.min(...startArr))
    return nextMissionDate.isValid() ? moment().to(nextMissionDate) : false
  }

  protected get currents() {
    return this.bookingState.activeReservations
  }

  protected vehicleModeString(mesh: ServiceMesh) {
    return (mesh.booking_mode + mesh.vehicle_type).toLowerCase()
  }

  protected action(mesh: ServiceMesh) {
    const type = mesh.vehicle_type.toLowerCase()
    const mode = mesh.booking_mode.toLowerCase()
    return this.$router.push({name: 'book', params: {type, mode}})
  }

  protected color(vehicleType: string) {
    return {backgroundColor: ServiceMesh.colors[vehicleType]}
  }

  protected icon(bookingMode: BookingMode, vehicleType: VehicleType) {
    const serviceMesh: ServiceMesh = new ServiceMesh()
    return serviceMesh.getImage(bookingMode, vehicleType, 'OK')

  }

  protected bookBike() {
    this.openPopup(new Popup(VehicleQrUnlock, {bookAndUnlock: true}))
  }

  protected text(mesh: ServiceMesh) {
    const key = `activities.booking.${mesh.vehicle_type}.${mesh.booking_mode}`.toLowerCase()
    return {
      action: this.$t(`${key}.action`),
      description: this.$isAvailable(`${key}.description`),
    }
  }

}
