interface Status {
  authorized: boolean
  canChangeCamera: boolean
  canEnableLight: boolean
  canOpenSettings: boolean
  currentCamera: number
  denied: boolean
  lightEnabled: boolean
  prepared: boolean
  previewing: boolean
  restricted: boolean
  scanning: boolean
  showing: boolean
}

export default class QrScanner {
  private scanner: any
  private status!: Status

  public constructor() {
    const scanner = (window as any).QRScanner
    if (scanner) {
      this.scanner = scanner
      this.prepare()
      this.scanner.getStatus((status: Status) => {
        this.status = status
      })
    }
  }

  public prepare() {
    this.scanner.prepare()
  }

  public destroy() {
    this.scanner.destroy()
  }

  public scan(scanFunction: (err: any, val: any) => void) {
    this.scanner.scan(scanFunction)
  }

  public cancelScan() {
    this.scanner.cancelScan()
  }

  public show() {
    this.scanner.show()
  }

  public hide() {
    this.scanner.hide()
  }

  public pausePreview() {
    this.scanner.pausePreview()
  }

  public resumePreview() {
    this.scanner.resumePreview()
  }

  public enableLight() {
    this.scanner.enableLight()
  }

  public disableLight() {
    this.scanner.disableLight()
  }

  public useCamera() {
    this.scanner.useCamera()
  }

  public useFrontCamera() {
    this.scanner.useFrontCamera()
  }

  public useBackCamera() {
    this.scanner.useBackCamera()
  }

  public openSettings() {
    this.scanner.openSettings()
  }

  public getStatus(statusCb?: (status: Status) => void) {
    this.scanner.getStatus(statusCb)
  }
}
