
























import {Component, Prop, Vue} from 'vue-property-decorator'
import Button from '../components/Button.vue'
import {Action, State} from 'vuex-class'
import ForceTerminateDialog from './Booking/ForceTerminateDialog.vue'
import {EventBus} from '@/main'
import {BookingMode, Dialog, Popup, ReservationResponse, TerminateRequest, VehicleType} from '@/lib/kepler/interfaces'

import {BookingState} from '@/store/modules/booking'
import Locate, {Position} from '@/lib/location'
import ServiceMesh from '@/lib/serviceMesh'
import {ProfileState} from '@/store/modules/profile'
import {VehicleSlotState} from '@/store/modules/vehicle'
import {AppConfigState} from '@/store/modules/configuration'
import MapStyles from '@/lib/map/styles/MapStyles'

@Component({
  components: {ForceTerminateDialog, Button},
})
export default class TerminateMode extends Vue {

  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Action('closeOverlays') public closeOverlays!: () => void
  @Action('terminateModeOff') public terminateModeOff!: any
  @Action('current') public current!: any
  @Action('terminateReservation') public terminateReservation!: any
  @State('booking') public bookingState!: BookingState

  @Prop() public reservation!: ReservationResponse
  @Prop() public terminateCb!: (payload?: Partial<TerminateRequest>) => void

  @State('profile') public profileState!: ProfileState
  @State('vehicle') public vehicleState!: VehicleSlotState
  @State('configuration') public configState!: AppConfigState

  public tmLoading: boolean = false
  public centerIcon = 'img/map/markers/POINTER.svg'
  public centerPoint: Position = {
    lat: 0,
    lng: 0,
    acc: null,
  }

  protected get position() {
    const p = this.reservation.current_vehicle_position || {latitude: 0, longitude: 0}
    return {
      lat: p.latitude,
      lng: p.longitude,
    }
  }

  protected get activeReservationIcon() {
    const serviceMesh: ServiceMesh = new ServiceMesh()
    return serviceMesh.getImage(
      this.reservation.vehicle_slot.reservation_type,
      this.reservation.vehicle_slot.vehicle.category.type,
      'OK',
    )
  }

  protected get mapStyle() {
    return MapStyles.getStyle(this.profileState.mapStyle ? this.profileState.mapStyle : 'default')
  }

  protected icon(bookingMode: BookingMode, vehicleType: VehicleType) {
    const serviceMesh: ServiceMesh = new ServiceMesh()
    return serviceMesh.getImage(bookingMode, vehicleType, 'OK')
  }

  protected mounted() {

    Locate.locate((pos: Position) => {
        this.centerPoint = pos
      },
      this.centerPoint,
      () => {
        // TODO: investigate further use for error callback
      },
    )
    EventBus.$on('userForcedTerminate', () => {
      this.tmLoading = true
      this.terminateCb({
        reservation_number: this.reservation.number,
        acceptUndesiderable: true,
        forceForbidden: true,
      })
    })
  }

  protected destroyed() {
    EventBus.$off('userForcedTerminate')
  }

  protected openTerminate() {
    const checkBoxLabel = this.bookingState.terminateModeMessages.includes('Vehicle is in orange zone')
      ? this.$t('booking.terminate_mode.accept_undesirable') : this.$t('booking.terminate_mode.accept_forbidden')
    const terminateMessages = this.bookingState.terminateModeMessages
    this.openDialog(new Dialog(ForceTerminateDialog, {checkBoxLabel, terminateMessages},
    ))
  }

  protected getPolyLineColor(zone: string) {
    return ServiceMesh.getPolyLineColor(zone)
  }
}
