












































import {Component, Prop, Vue} from 'vue-property-decorator'
import {State} from 'vuex-class'
import {ChangePasswordRequest} from '@/lib/kepler/interfaces'
import {ValidationProvider} from 'vee-validate'
import {VLayout, VTextField} from 'vuetify/lib'

@Component({
  components: {ValidationProvider, VLayout, VTextField},
  name: 'PasswordFieldWithValidation',
})
export default class PasswordFieldWithValidation extends Vue {
  @State('flowOutputs') public flowOutputs!: { [k: string]: any }

  @Prop({
    type: [String, Object],
    default: '',
  }) public readonly rules!: string | object

  @Prop({
    type: String,
  }) public context!: string

  public innerValue: ChangePasswordRequest = {
    new_password: '',
    new_password_retype: '',
    old_password: '',
  }

  public showPassword: boolean = false

  public get rulesRetype() {
    return this.rules + '|password:' + this.innerValue.new_password + ',' + this.innerValue.old_password
  }

  public updateValue(key: string, value: string) {
    if (this.innerValue.hasOwnProperty(key)) {
      this.innerValue[key] = value
      this.$emit('update:value', this.innerValue)
    }
  }

  public transErrors(errors: string[]) {
    return errors.map((err) => this.$t(err))
  }

}
