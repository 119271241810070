
























import {Vue, Component, Prop} from 'vue-property-decorator'
import Utils from '@/utils'
import sdk from '@/lib/kepler/sdk'
import FormTag from '@/components/proxy/Inputs/FormTag.vue'
import {Action, State} from 'vuex-class'
import ConfirmDialog from '@/views/ConfirmDialog.vue'
import {Dialog} from '@/lib/kepler/interfaces'

@Component({
  components: {
    FormTag,
    Btn: Utils.loadComponent('proxy/Btn'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Container: Utils.loadComponent('proxy/Container'),
    CardWithReport: Utils.loadComponent('CardWithReport'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
  },
  name: 'AddCoupon',
})

export default class AddCoupon extends Vue {
  @State((state) => state.configuration.appConfig.fields_configuration.coupon_disabled) public couponsDisabled?: string
  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('getWallets') public getWallets!: () => Promise<void>
  @Prop({type: String, default: () => 'coupon'}) public theme!: string

  protected coupon: string = ''
  protected loading: boolean = false
  protected error: boolean = false

  protected get hasCoupons() {
    return !this.couponsDisabled
  }

  protected sendCoupon() {
    if (this.coupon && !this.error) {
      this.loading = true
      sdk.billing.coupon(this.coupon)
        .then((r) => {
          const response = r.data
          const amount = this.$currency(response.amount)
          this.openDialog(new Dialog(ConfirmDialog, {
            imageState: 'success.svg',
            confirmText: this.$t('common.continue'),
            code: '',
            subtitle: this.$t('profile.wallet.coupon.success', {amount}),
            title: response.reason,
            singleAction: true,
            emitConfirm: false,
          }))
          this.coupon = ''
          this.getWallets()
        })
        .catch(() => {
          this.error = true
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
