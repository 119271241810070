






















import {Vue, Component, Prop, Watch} from 'vue-property-decorator'

import {VAutocomplete, VSelect, VLayout, VTextField} from 'vuetify/lib'
import {ValidationProvider} from 'vee-validate'

interface InnerValue {
  code: string
  dial_code: string
  flag: string
  name: string
}

@Component({
  components: {VSelect, ValidationProvider, VAutocomplete, VLayout, VTextField},
  name: 'CountriesFieldWithValidation',
})
export default class CountriesFieldWithValidation extends Vue {
  @Prop({
    type: [String, Object],
    default: () => '',
  }) public readonly rules!: string | object

  @Prop({
    type: [String, Object],
    default: () => null,
  }) public readonly value!: string | null

  @Prop({
    type: Array,
    default: () => [],
  }) public items!: InnerValue[]

  public innerValue: InnerValue | null = null

  @Watch('innerValue')
  public onInnerValueChange(newVal: InnerValue) {
    if (newVal) {
      this.$emit('input', newVal.code)
    }
  }

  @Watch('value')
  public onValueChange(newVal: string) {
    this.updateVal(newVal)
  }

  public updateVal(val: string | null) {
    if (val) {
      const v = this.items.find((i) => {
        return i.code.toLowerCase() === val.toLowerCase()
      })
      if (v) {
        this.innerValue = v
      }
    }
  }

  public created() {
    const r = this.rules
    const defaultValue = (() => {
      if (r && typeof r === 'string') {
        return r.split('|').find((rule: string) => rule.toLowerCase().startsWith('default'))?.split(':')[1]
      } else if (r && typeof r === 'object' && r.hasOwnProperty('default')) {
        return (r as any)[`default`] as string | undefined
      }
    })()

    this.updateVal(this.value ? this.value : (defaultValue?.toUpperCase() || null))

    // TODO: reverse geocoding from position
  }
}
