


































































import {Vue, Component, Prop} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import {
  AppConfig,
  City,
  ParkingLot,
  Pos,
  ReservationResponse,
  VehicleSlot,
  ZoneResponse,
} from '@/lib/kepler/interfaces'
import Locate, {Position} from '@/lib/location'
import ServiceMesh from '@/lib/serviceMesh'
import Utils from '@/utils'
import sdk from '@/lib/kepler/sdk'
import MapStyles from '@/lib/map/styles/MapStyles'
import CenterMapButton from '@/views/Map/CenterMapButton.vue'

interface MarkerLabel {
  color?: string
  fontFamily?: string
  fontSize?: string
  fontWeight?: string
  text: string
  origin?: [number, number]
}

@Component({
  components: {
    CenterMapButton,
    Card: Utils.loadComponent('proxy/Card/Card'),
    Dialog: Utils.loadComponent('proxy/Dialog'),
    ParkingCardPopup: Utils.loadComponent('entities/vehicle/ParkingCardPopup'),
    Alert: Utils.loadComponent('proxy/Alert'),
    Btn: Utils.loadComponent('proxy/Btn'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Button: Utils.loadComponent('Button'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Icon: Utils.loadComponent('proxy/Icon'),
    CloseButton: Utils.loadComponent('CloseButton'),
  },
  name: 'VehicleLocation',
})
export default class VehicleLocation extends Vue {
  @State((state) => state.profile.userPosition) public userPosition!: Pos | null
  @State((state) => state.profile.mapStyle) public userMapStyle!: string
  @State((state) => state.configuration.appConfig) public appConfig!: AppConfig
  @State((state) => state.vehicle.zones) public zones!: Map<string, ZoneResponse>

  @Getter('city') public city!: City | null

  @Action('setUserPosition') public setUserPosition: any

  @Prop() public reservation?: ReservationResponse
  @Prop() public vehicleSlot?: VehicleSlot

  @Prop({
    type: Boolean,
    default: () => false,
  }) public showParkingLot!: boolean
  @Prop({
    type: Boolean,
    default: true,
  }) public fabEnabled!: boolean

  public dialog: boolean = false

  public directionsError: boolean = false
  public gpsError: boolean = false
  public loading: boolean = false
  public centerPoint: Position = {
    lat: 0,
    lng: 0,
    acc: null,
  }
  public parkingLotMarkers: ParkingLot[] = []

  public routes: Pos[][] = []

  protected get vehicleImage() {
    const serviceMesh = new ServiceMesh()

    if (this.computedVehicleSlot) {
      const vehicleImage = serviceMesh.getImageForVehicle(this.computedVehicleSlot, 'OK')
      const parkingImage = serviceMesh.getImageForParking(this.computedVehicleSlot)
      return this.destination ? vehicleImage : parkingImage
    } else {
      return null
    }
  }

  protected get bookingColor() {
    const slot = this.computedVehicleSlot
    if (slot) {
      const str = slot.reservation_type + slot.vehicle.category.type.toUpperCase()
      return ServiceMesh.colors[str]
    }
    return null
  }

  protected get computedVehicleSlot() {
    return this.reservation?.vehicle_slot || this.vehicleSlot || null
  }

  protected get destination() {
    const position = this.currentPosition || this.slotPosition
    if (position) {
      return {lat: position.latitude, lng: position.longitude, acc: null}
    }
  }

  protected get mapStyle() {
    return MapStyles.getStyle(this.userMapStyle ? this.userMapStyle : 'default')
  }

  protected get resType() {
    return this.computedVehicleSlot?.reservation_type
  }

  protected get slotPosition() {
    return this.computedVehicleSlot?.position
  }

  protected get lotPosition() {
    const pos = this.computedVehicleSlot?.lot.position
    if (pos) {
      return {lat: pos?.latitude, lng: pos?.longitude}
    }
    return null
  }

  protected get currentPosition() {
    return this.reservation?.current_vehicle_position
  }

  protected get mapRef() {
    return this.$refs.map as any
  }

  protected mounted() {
    this.centerPoint = {
      lat: this.appConfig.default_latitude,
      lng: this.appConfig.default_longitude,
      acc: null,
    }
    sdk.booking.getParkingLots(
      {
        latitude: this.centerPoint.lat,
        longitude: this.centerPoint.lng,
        booking_mode: 'OWFF',
        city_id: this.city?.id || undefined,
      }).then((r: any) => {
      if (r) {
        this.parkingLotMarkers = r.data
      }
    })
  }

  protected openMapDialog() {
    this.dialog = true
    this.getRoute()
    this.$nextTick(() => {
      this.setup()
      this.mapRef.refresh()
    })
  }

  protected closeMapDialog() {
    this.dialog = false
  }

  protected getRoute(mode: 'walking' | 'driving' | 'cycling' = 'walking') {
    if (this.userPosition && this.destination) {
      const {acc, ...vehiclePosition} = this.destination
      const pos = [this.userPosition, vehiclePosition]
      if (this.slotPosition) {
        const lot = {lat: this.slotPosition.latitude, lng: this.slotPosition.longitude}
        if (lot) {
          pos.push(lot)
        }
      }
      sdk.map.getRoute({pos, mode})
        .then((r) => {
          this.directionsNotAvailable(!r.data.length)
          this.routes = r.data
        })
        .catch(() => {
          this.directionsNotAvailable()
        })
        .finally(() => {
          this.loading = false
        })
    }
  }

  protected setup() {
    this.loading = true
    Locate.locate((pos) => {
        this.centerPoint = pos
        this.setUserPosition(pos)
        this.gpsError = false
        this.loading = false
        this.centerMap()
      },
      this.centerPoint,
      () => {
        this.gpsError = true
        this.loading = false
        this.centerMap()
      },
    )
  }

  protected centerMap() {
    if (this.mapRef) {
      if (this.destination) {
        this.mapRef.getBoundsAndPan([this.destination, this.centerPoint])
      } else {
        this.mapRef.pan(this.centerPoint)
      }
    }
  }

  protected openInMaps() {
    if (this.userPosition && this.destination) {
      const origin = `&origin=${this.userPosition.lat},${this.userPosition.lng}`
      const destination = `&destination=${this.destination.lat},${this.destination.lng}`
      const url = `https://www.google.com/maps/dir/?api=1${origin + destination}&=travelmode=walking`
      cordova.InAppBrowser.open(url, '_system')
    }
  }

  protected directionsNotAvailable(v: boolean = true) {
    this.directionsError = v
    this.loading = false
  }

  // noinspection JSMethodCanBeStatic
  protected getPolyLineColor(zone: string) {
    return ServiceMesh.getPolyLineColor(zone)
  }

  protected parkingMarkerLabel(m: any): { label: MarkerLabel } {
    let available = m.parking_slots_availability.available
    available = available || ''
    const color = this.$branding.theme.PARKING ? this.$branding.theme.PARKING.toString() : ''
    return available ? {
      label: {
        text: available.toString(),
        fontSize: '10px',
        fontWeight: '600',
        color,
        origin: [25, 25],
      },
    } : {
      label: {
        text: ' ',
        origin: [15, 15],
      },
    }
  }
}
