




































import {Vue, Component, Prop} from 'vue-property-decorator'
import {SubscriptionPlan, Rate} from '@/lib/kepler/interfaces'
import Utils from '@/utils'

@Component({
  components: {
    Accordion: Utils.loadComponent('proxy/Accordion/Accordion'),
    AccordionContent: Utils.loadComponent('proxy/Accordion/AccordionContent'),
    VehicleFares: Utils.loadComponent('entities/vehicle/VehicleFares'),
    GradientCard: Utils.loadComponent('GradientCard'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Chip: Utils.loadComponent('proxy/Chip'),
    Img: Utils.loadComponent('proxy/Image'),
  },
})
export default class PlanDetailRates extends Vue {
  @Prop() public plan!: SubscriptionPlan
  @Prop({
    type: String,
  }) public mode!: string
  @Prop({
    type: Array,
    default: () => [],
  }) public rates!: Rate[]
  @Prop({
    type: String,
  }) public color1!: string
  @Prop({
    type: String,
  }) public color2!: string

  public accordionIndex: number | null = null

  public onlyOne: boolean = false

  public get textGradient() {
    const c1 = this.color1
    const c2 = this.color2
    return {
      color: c1 ? c1 : 'inherit',
      background: c1 && c2 ? `linear-gradient(45deg, ${c1}, ${c2})` : 'currentColor',
    }
  }

  public get parsedRates() {
    const obj: Record<string, any> = {}
    this.rates.forEach((r) => {
      const [timeCost, timeCycle] = r.info.time_rate?.split('|') ?? [null, null]
      const [distanceCost, distanceCycle] = r.info.distance_rate?.split('|') ?? [null, null]
      obj[r.id] = {
        time: {
          cost: timeCost !== null ? this.$currency(timeCost) : null,
          cycle: timeCycle !== null ? this.$parseMinutes(timeCycle) : null,
        },
        distance: {
          cost: distanceCost !== null ? this.$currency(distanceCost) : null,
          cycle: distanceCycle !== null ? this.parseDistance(distanceCycle) : null,
        },
      }
    })
    return obj
  }

  public bookingModes(rate?: Rate) {
    let bm = ''
    if (!rate && this.plan) {
      for (const r of this.plan.rates) {
        if (!bm.includes(r.booking_mode)) {
          bm += bm.length > 0 ? ',' + r.booking_mode : r.booking_mode
        }
      }
    } else if (rate) {
      bm = rate.booking_mode
    }
    return bm.split(',')
  }

  public created() {
    if (this.rates.length === 1) {
      this.accordionIndex = 0
      this.onlyOne = true
    }
  }

  public parseDistance(val: string) {
    return this.$distance(val && Number(val) !== 1 ? val : undefined)
  }
}
