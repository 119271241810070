
































import {Vue, Component, Prop} from 'vue-property-decorator'
import {State} from 'vuex-class'
import Utils from '@/utils'
import {Popup} from '@/lib/kepler/interfaces'

@Component({
  components: {
    Layout: Utils.loadComponent('proxy/Layout'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    Img: Utils.loadComponent('proxy/Image'),
    BottomNav: Utils.loadComponent('proxy/BottomNav'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Divider: Utils.loadComponent('proxy/Divider'),
    Btn: Utils.loadComponent('proxy/Btn'),
  },
})
export default class HomeMenu extends Vue {
  @State((state) => state.configuration.appConfig?.fields_configuration?.home_menu_style) public homeMenuStyle?: string
  @State('popupState') public popupState!: Popup[]

  @Prop({
    type: Boolean,
    default: false,
  }) public showBottomNav!: boolean

  protected get isHome() {
    return this.$route.name === 'home'
  }

  protected get buttonClass() {
    let cls = ''
    if (this.labeledMenu?.class) {
      cls += `text-button ${this.labeledMenu.class}`
    }
    if (this.logoMenu?.class) {
      cls += `logo-button ${this.logoMenu.class}`
    }
    return cls
  }

  protected get logoMenu() {
    // example config string: logo|src=img/logo.png|class=accent--text
    if (this.homeMenuStyle?.startsWith('logo')) {
      const obj: { [key: string]: string } = {src: 'img/icons/android-chrome-192x192.png', class: 'accent--text'}
      this.homeMenuStyle?.split('|').forEach((s) => {
        const prop = s.split('=')
        if (prop.length > 1) {
          obj[prop[0]] = prop[1]
        }
      })
      return obj
    }
    return null
  }

  protected get labeledMenu() {
    // example config string: labeled|icon=mdi-arrow-right-bold|label=go
    if (this.homeMenuStyle?.startsWith('labeled')) {
      const obj: { [key: string]: string } = {label: 'menu.bottom_bar.activities', icon: 'mdi-shape'}
      this.homeMenuStyle?.split('|').forEach((s) => {
        const prop = s.split('=')
        if (prop.length > 1) {
          obj[prop[0]] = prop[1]
        }
      })
      return obj
    }
    return null
  }
}
