

















import {Vue, Component} from 'vue-property-decorator'
import {Action, State} from 'vuex-class'
import {ProfileState} from '@/store/modules/profile'
import {Driver, Dialog, Popup} from '@/lib/kepler/interfaces'
import collect from 'collect.js'
import Utils from '@/utils'

import AddDriver from '@/components/AddDriver.vue'
import MyDriversProfile from './MyDriversProfile.vue'

@Component({
  components: {
    FilteredListWithAddButton: Utils.loadComponent('FilteredListWithAddButton'),
    CardDriver: Utils.loadComponent('CardDriver'),
  },
  name: 'Drivers',
})

export default class Drivers extends Vue {
  @State('profile') public profileState!: ProfileState

  @Action('openDialog') public openDialog!: (dialog: Dialog) => void
  @Action('openPopup') public openPopup!: (popup: Popup) => void
  @Action('closePopup') public closePopup!: (index?: number) => void
  @Action('getDrivers') public getDrivers!: () => void

  protected mounted() {
    this.getDrivers()
  }

  protected inviteDriver() {
    this.openDialog(new Dialog(AddDriver, {}))
  }

  protected openDriverDetail(driver: Driver) {
    if (driver.id === (this.profileState as any).driver.id) {
      return
    }
    this.openPopup(new Popup(MyDriversProfile, {driver}, `Driver ${driver.id}`))
  }

  protected driverHasPlan(driver: Driver) {
    return !!collect(this.profileState.subscriptions).first((subscription) => {
      return !!collect(subscription.drivers).firstWhere('id', driver.id)
    })
  }
}
