





















import {Vue, Component, Prop} from 'vue-property-decorator'
import {Attribute} from '@/lib/kepler/interfaces'
import Utils from '@/utils'
import VuetifyColorHelper from '@/lib/vuetify/VuetifyColorHelper'

@Component({
  name: 'VehicleFeatures',
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Divider: Utils.loadComponent('proxy/Divider'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Avatar: Utils.loadComponent('proxy/Avatar'),
    Chip: Utils.loadComponent('proxy/Chip'),
    Icon: Utils.loadComponent('proxy/Icon'),
  },
})
export default class VehicleFeatures extends Vue {
  @Prop({
    type: Array,
    default: () => [],
  }) public attributes!: Attribute[]
  @Prop({
    type: Boolean,
    default: false,
  }) public small!: boolean
  @Prop({
    type: Boolean,
    default: false,
  }) public extended!: boolean

  protected detailed: string = ''

  protected textColor() {
    let contrast = VuetifyColorHelper.lightContrast(VuetifyColorHelper.color('primary'))
    const override = this.$branding.theme.buttonDefaultColorOverride as string | undefined
    if (override) {
      contrast = VuetifyColorHelper.lightContrast(VuetifyColorHelper.color(override))
    }
    return contrast ? 'black' : 'white'
  }

  protected attributeParser(attr: Attribute) {
    const list: { [key: string]: { icon: string } } = {
      'seats': {icon: 'mdi-car-seat'},
      'luggage': {icon: 'mdi-bag-carry-on'},
      'hand-luggage': {icon: 'mdi-bag-personal'},
      'transmission': {icon: 'mdi-car-shift-pattern'},
      'pets-friendly': {icon: 'mdi-paw'},
      'baby-seat': {icon: 'mdi-car-child-seat'},
      'isofix': {icon: 'mdi-car-child-seat'},
      'air-conditioning': {icon: 'mdi-air-conditioner'},
      'doors': {icon: 'mdi-car-door'},
      'bluetooth': {icon: 'mdi-bluetooth'},
      'shitter': {icon: 'mdi-toilet'},
      'luggage-large': {icon: 'mdi-bag-suitcase'},
      'luggage-small': {icon: 'mdi-bag-personal'},
    }
    return list[attr.name] || {icon: null}
  }

  protected detail(id: string) {
    if (this.detailed !== id) {
      this.detailed = id
    } else {
      this.detailed = ''
    }
  }
}
