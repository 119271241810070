







































import {Component, Prop} from 'vue-property-decorator'
import {Action} from 'vuex-class'
import Utils from '@/utils'
import {BookRequest, ReservationResponse, Vehicle} from '@/lib/kepler/interfaces'
import ConfirmDialogCallback from '../ConfirmDialogCallback.vue'
import storage from '@/lib/storage'
import Chip from '@/components/proxy/Chip.vue'
import {
  VSelect,
  VListTileAvatar,
  VListTileContent,
  VListTileSubTitle,
  VListTileTitle,
  VExpansionPanelContent, VExpansionPanel,
} from 'vuetify/lib'
import Layout from '@/components/proxy/Layout.vue'

@Component({
  components: {
    Layout,
    Icon: Utils.loadComponent('proxy/Icon'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    Chip,
    ConfirmDialogCallback,
    VehicleConfirmImage: Utils.loadComponent('entities/vehicle/VehicleConfirmImage'),
    TextField: Utils.loadComponent('proxy/Inputs/TextField'),
    Container: Utils.loadComponent('proxy/Container'),
    CloseButton: Utils.loadComponent('CloseButton'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    IsLogged: Utils.loadComponent('IsLogged'),
    Button: Utils.loadComponent('Button'),
    VSelect,
    VListTileContent,
    VListTileAvatar,
    VListTileTitle,
    VListTileSubTitle,
    VExpansionPanel,
    VExpansionPanelContent,
  },
  name: 'VehicleBookingAddMemoDialog',
})
export default class VehicleBookingAddMemoDialog extends ConfirmDialogCallback {
  @Action('book') public bookAction!: (req: BookRequest) => Promise<ReservationResponse>
  @Prop() public bookRequest!: BookRequest
  @Prop() public vehicle!: Vehicle
  public memos: string[] = []
  public memo: string | null = ''
  public prepended: string | null = ''

  public editMemos: number | null = null
  public memoToAdd: string = ''

  public created() {
    this.getMemos()
  }

  protected submitMemo() {
    this.bookRequest.memo = this.memo ? this.prepended + ' ' + this.memo : this.prepended
    this.callAndClose(this.confirmCallback)
  }

  protected getMemos() {
    this.memos = storage.load('memos') || []
    if (!this.memos) {
      storage.save('memos', [])
    }
  }

  protected selectMemo(memo: string) {
    this.prepended = memo
    this.editMemos = null
  }

  protected addMemoToStorage() {
    const memo = this.memoToAdd
    if (memo) {
      this.memos.push(memo)
      storage.save('memos', this.memos)
      this.memoToAdd = ''
      this.prepended = memo
      this.editMemos = null
    }
  }

  protected removeMemoFromStorage(memo: string) {
    this.memos = this.memos.filter((m) => m !== memo)
    if (this.prepended === memo) {
      this.prepended = null
    }
    storage.save('memos', this.memos)
  }

}
