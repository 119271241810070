import {BleDevice} from "@/lib/BtSDKs/BleDevice";

export default class OmniLock extends BleDevice {
  private SERV_DATA_UUID = '0783B03E-8535-B5A0-7140-A304D2495CB7'
  private CHAR_DATA_WRITE_UUID = '0783B03E-8535-B5A0-7140-A304D2495CBA'
  private CHAR_DATA_NOTIFY_UUID = '0783B03E-8535-B5A0-7140-A304D2495CB8'

  private key: string = ""

  private mac: string = ""

  private encKey: number = 0x00

  constructor(logger: any, mac: string, key: string) {
    super(logger)
    this.key = key
    this.mac = mac
    this.log("Initialized class for " + mac)
  }

  // https://github.com/donvercety/node-crc16/blob/master/crc16.js
  crc16(byte_array: number[]) {
    let CRCHi = [
      0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41, 0x01, 0xC0,
      0x80, 0x41, 0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41,
      0x00, 0xC1, 0x81, 0x40, 0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0,
      0x80, 0x41, 0x01, 0xC0, 0x80, 0x41, 0x00, 0xC1, 0x81, 0x40,
      0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41, 0x00, 0xC1,
      0x81, 0x40, 0x01, 0xC0, 0x80, 0x41, 0x01, 0xC0, 0x80, 0x41,
      0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41, 0x00, 0xC1,
      0x81, 0x40, 0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41,
      0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41, 0x01, 0xC0,
      0x80, 0x41, 0x00, 0xC1, 0x81, 0x40, 0x00, 0xC1, 0x81, 0x40,
      0x01, 0xC0, 0x80, 0x41, 0x01, 0xC0, 0x80, 0x41, 0x00, 0xC1,
      0x81, 0x40, 0x01, 0xC0, 0x80, 0x41, 0x00, 0xC1, 0x81, 0x40,
      0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41, 0x01, 0xC0,
      0x80, 0x41, 0x00, 0xC1, 0x81, 0x40, 0x00, 0xC1, 0x81, 0x40,
      0x01, 0xC0, 0x80, 0x41, 0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0,
      0x80, 0x41, 0x01, 0xC0, 0x80, 0x41, 0x00, 0xC1, 0x81, 0x40,
      0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41, 0x01, 0xC0,
      0x80, 0x41, 0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41,
      0x00, 0xC1, 0x81, 0x40, 0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0,
      0x80, 0x41, 0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41,
      0x01, 0xC0, 0x80, 0x41, 0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0,
      0x80, 0x41, 0x00, 0xC1, 0x81, 0x40, 0x00, 0xC1, 0x81, 0x40,
      0x01, 0xC0, 0x80, 0x41, 0x01, 0xC0, 0x80, 0x41, 0x00, 0xC1,
      0x81, 0x40, 0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41,
      0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41, 0x01, 0xC0,
      0x80, 0x41, 0x00, 0xC1, 0x81, 0x40
    ]

    let CRCLo = [
      0x00, 0xC0, 0xC1, 0x01, 0xC3, 0x03, 0x02, 0xC2, 0xC6, 0x06,
      0x07, 0xC7, 0x05, 0xC5, 0xC4, 0x04, 0xCC, 0x0C, 0x0D, 0xCD,
      0x0F, 0xCF, 0xCE, 0x0E, 0x0A, 0xCA, 0xCB, 0x0B, 0xC9, 0x09,
      0x08, 0xC8, 0xD8, 0x18, 0x19, 0xD9, 0x1B, 0xDB, 0xDA, 0x1A,
      0x1E, 0xDE, 0xDF, 0x1F, 0xDD, 0x1D, 0x1C, 0xDC, 0x14, 0xD4,
      0xD5, 0x15, 0xD7, 0x17, 0x16, 0xD6, 0xD2, 0x12, 0x13, 0xD3,
      0x11, 0xD1, 0xD0, 0x10, 0xF0, 0x30, 0x31, 0xF1, 0x33, 0xF3,
      0xF2, 0x32, 0x36, 0xF6, 0xF7, 0x37, 0xF5, 0x35, 0x34, 0xF4,
      0x3C, 0xFC, 0xFD, 0x3D, 0xFF, 0x3F, 0x3E, 0xFE, 0xFA, 0x3A,
      0x3B, 0xFB, 0x39, 0xF9, 0xF8, 0x38, 0x28, 0xE8, 0xE9, 0x29,
      0xEB, 0x2B, 0x2A, 0xEA, 0xEE, 0x2E, 0x2F, 0xEF, 0x2D, 0xED,
      0xEC, 0x2C, 0xE4, 0x24, 0x25, 0xE5, 0x27, 0xE7, 0xE6, 0x26,
      0x22, 0xE2, 0xE3, 0x23, 0xE1, 0x21, 0x20, 0xE0, 0xA0, 0x60,
      0x61, 0xA1, 0x63, 0xA3, 0xA2, 0x62, 0x66, 0xA6, 0xA7, 0x67,
      0xA5, 0x65, 0x64, 0xA4, 0x6C, 0xAC, 0xAD, 0x6D, 0xAF, 0x6F,
      0x6E, 0xAE, 0xAA, 0x6A, 0x6B, 0xAB, 0x69, 0xA9, 0xA8, 0x68,
      0x78, 0xB8, 0xB9, 0x79, 0xBB, 0x7B, 0x7A, 0xBA, 0xBE, 0x7E,
      0x7F, 0xBF, 0x7D, 0xBD, 0xBC, 0x7C, 0xB4, 0x74, 0x75, 0xB5,
      0x77, 0xB7, 0xB6, 0x76, 0x72, 0xB2, 0xB3, 0x73, 0xB1, 0x71,
      0x70, 0xB0, 0x50, 0x90, 0x91, 0x51, 0x93, 0x53, 0x52, 0x92,
      0x96, 0x56, 0x57, 0x97, 0x55, 0x95, 0x94, 0x54, 0x9C, 0x5C,
      0x5D, 0x9D, 0x5F, 0x9F, 0x9E, 0x5E, 0x5A, 0x9A, 0x9B, 0x5B,
      0x99, 0x59, 0x58, 0x98, 0x88, 0x48, 0x49, 0x89, 0x4B, 0x8B,
      0x8A, 0x4A, 0x4E, 0x8E, 0x8F, 0x4F, 0x8D, 0x4D, 0x4C, 0x8C,
      0x44, 0x84, 0x85, 0x45, 0x87, 0x47, 0x46, 0x86, 0x82, 0x42,
      0x43, 0x83, 0x41, 0x81, 0x80, 0x40
    ]
    var vCRCHi = 0xFF
    var vCRCLo = 0xFF

    var iIndex

    for (let b of byte_array) {
      iIndex = vCRCLo ^ b
      vCRCLo = vCRCHi ^ CRCHi[iIndex]
      vCRCHi = CRCLo[iIndex]
    }

    return [vCRCHi, vCRCLo]
  }


  private initStatus: string = "UNKNOWN"

  init() {
    return new Promise<void>((resolve, reject) => {
      this.scan().then(() => {
        if (this.initStatus == "INITIALIZED") {
          resolve()
        } else if (this.initStatus == "INITIALIZING") {
          reject("INIT ALREADY IN PROGRESS")
        } else {
          //this.initStatus = "INITIALIZING"
          this.connect().then(() => {
            this.authCallback = () => {
              this.initStatus = "INITIALIZED"
              resolve()
              this.authCallback = null
            }
            this.subscribe(this.SERV_DATA_UUID, this.CHAR_DATA_NOTIFY_UUID, (d) => {
              this.receive(d)
            }).then(
              () => {
                this.send(this.forgePayload("AUTH", this.stringToByte(this.key)))
              }
            )
          }).catch((x) => {
            this.initStatus = "UNKNOWN"
            reject(x)
          })
        }
      })
    })
  }

  send(data: any) {
    this.log("!>" + data.length + "> " + JSON.stringify(this.intToHex(data)))
    // Encrypt and checksum
    var rand = data[1]
    data[1] = data[1] + 0x32 // Rand becomes RAND1
    for (var i = 2; i < data.length; i++) {
      data[i] = data[i] ^ rand
    }
    var crc = this.crc16(data)
    data.push(crc[0])
    data.push(crc[1])
    // Sending
    this.log(">" + data.length + "> " + JSON.stringify(this.intToHex(data)))
    return this.write(this.SERV_DATA_UUID, this.CHAR_DATA_WRITE_UUID, data, "noResponse").catch((x) => {
      alert(JSON.stringify(x))
    })
  }

  private authCallback: any = null

  receive(data: any) {
    this.log("!<" + data.length + "< " + JSON.stringify(this.intToHex(data)))
    data.pop() // Pop out crc
    data.pop()
    // @ts-ignore
    this.log("<C< " + this.crc16(data)[0].toString(16) + this.crc16(data)[1].toString(16))
    // Decrypt
    var rand = data[1] - 0x32
    for (var i = 2; i < data.length - 1; i++) {
      data[i] = data[i] ^ rand
    }
    this.log("<" + data.length + "< " + JSON.stringify(this.intToHex(data)))

    let parsed: any = {}

    // Command switch
    switch (data[3]) {
      // Auth
      case 0x11:
        parsed.command = "AUTH"
        this.encKey = data[5]
        if (this.authCallback) {
          this.authCallback()
        }
        break;
      case 0x21:
        parsed.command = "UNLOCK"
        if (data[5] == 0x00) {
          parsed.esit = "SUCCESS"
        } else {
          parsed.esit = "FAILURE " + data[5]
        }
        parsed.timestamp = [data[6], data[7], data[8], data[9]]
        break;
      case 0x22:
        parsed.command = "LOCK"
        if (data[5] == 0x00) {
          parsed.esit = "SUCCESS"
        } else {
          parsed.esit = "FAILURE " + data[5]
        }
        parsed.timestamp = [data[6], data[7], data[8], data[9]]
        parsed.riding_time = [data[10], data[11], data[12], data[13]]
        break;
      case 0x31:
        parsed.command = "STATUS"
        parsed.lock_status = data[5]
        parsed.battery_voltage = data[6]
        parsed.data_status = data[7]
        parsed.timestamp = [data[8], data[9], data[10], data[11]]
        break;
      case 0x51:
        parsed.command = "DATA"
        break;
      case 0x52:
        parsed.command = "CLEAR-DATA"
        break;
      default:
        parsed.command = "NEW:" + this.intToHex([data[3]])
        break;
    }
    if (this.operationCallback != null && (parsed.command == "LOCK" || parsed.command == "UNLOCK")) {
      this.operationCallback(parsed.esit == "SUCCESS")
      this.operationCallback = null
    }
    this.logJson(parsed)
  }

  getCommandCode(command: string): number {
    switch (command) {
      case "AUTH":
        return 0x11
      case "UNLOCK":
        return 0x21
      case "LOCK":
        return 0x22
      case "STATUS":
        return 0x31
      case "DATA":
        return 0x51
      case "CLEAR-DATA":
        return 0x52
    }
    return 0x00
  }

  data() {
    this.init().then(() => {
      this.send(this.forgePayload("STATUS", []))
    })
  }

  private operationCallback: any = null

  unlock() {
    var unix = (Math.round(+new Date() / 1000)).toString(16);
    return this.send(this.forgePayload("UNLOCK", [
      0x00,         // UserID
      0x00,         // UserID
      0x00,         // UserID
      0x00,         // UserID
      parseInt(unix.substring(0, 2), 16), // Timestamp
      parseInt(unix.substring(2, 4), 16), // Timestamp
      parseInt(unix.substring(4, 6), 16), // Timestamp
      parseInt(unix.substring(6, 8), 16), // Timestamp
    ]))
  }

  forgePayload(command: string, data: number[]) {
    let result: number[] = []
    result = result.concat([0xFE])  // STX
    result = result.concat([Math.floor(Math.random() * (0xFF - 0x32))]) // RANDOM
    result = result.concat([this.encKey]) // KEY
    result = result.concat([this.getCommandCode(command)]) // COMMAND
    result = result.concat([data.length]) // LEN
    result = result.concat(data) // DATA
    return result
  }

  isCorrectDevice(name: string, address: string, mode: string, data: any) {
    if (name != "LOCK") {
      return false
    }

    switch (mode) {
      case "manifacturer":
        return data.toUpperCase() == this.mac.replace(/:/g, "").toUpperCase()
      case "advertisement":
        return address.replace(/:/g, "").toUpperCase() == this.mac.replace(/:/g, "").toUpperCase()
    }

    return false
  }
}
